import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';

import { persistConfigPayments } from '@cp/common/config/persist';
import * as authActions from '@cp/common/modules/auth/store/actions';

import type { RequestLoadingStatus } from '@cp/common/modules/shared/utils';
import type { EnrollAutopayFlowType, PaymentSummary } from '../../utils';

import { actions, thunks } from '../actions';
import {
	allPaymentsAdapter,
	paymentHistoryAdapter,
	paymentMethodsAdapter,
	paymentMissedsAdapter,
	upcomingPaymentsAdapter,
} from './payments.adapters';

const methods = createReducer(
	paymentMethodsAdapter.getInitialState({
		loading: undefined as RequestLoadingStatus | undefined,
		currentRequestId: undefined as string | undefined,
	}),
	(builder) => {
		builder.addCase(thunks.getPaymentMethods.pending, (state, action) => {
			if (!state) return;
			if (state.loading === 'idle' || !state.loading) {
				state.loading = 'pending';
				state.currentRequestId = action.meta.requestId;
			}
		});

		builder.addCase(thunks.getPaymentMethods.fulfilled, (state, action) => {
			const { requestId } = action.meta;
			if (action.payload.success && Array.isArray(action.payload.response)) {
				paymentMethodsAdapter.setAll(state, [...action.payload?.response]);
			}
			if (state.loading === 'pending' && state.currentRequestId === requestId) {
				state.loading = 'idle';
				state.currentRequestId = undefined;
			}
		});

		builder.addCase(thunks.getPaymentMethods.rejected, (state, action) => {
			if (!state) return;
			const { requestId } = action.meta;
			if (state.loading === 'pending' && state.currentRequestId === requestId) {
				state.loading = 'idle';
				state.currentRequestId = undefined;
			}
		});

		builder.addMatcher(
			(action) =>
				(authActions.thunks.signIn.fulfilled.match(action) && action.payload.success) ||
				authActions.thunks.signOut.pending.match(action),
			(state) => {
				if (state?.ids?.length) {
					paymentMethodsAdapter.removeAll(state);
				}
			},
		);
	},
);

const upcoming = createReducer(
	upcomingPaymentsAdapter.getInitialState({
		loading: undefined as RequestLoadingStatus | undefined,
		currentRequestId: undefined as string | undefined,
	}),
	(builder) => {
		builder.addCase(thunks.getUpcomingPaymentsById.pending, (state, action) => {
			if (!state) return;
			if (state.loading === 'idle' || !state.loading) {
				state.loading = 'pending';
				state.currentRequestId = action.meta.requestId;
			}
		});

		builder.addCase(thunks.getAllUpcomingPayments.pending, (state, action) => {
			if (!state) return;
			if (state.loading === 'idle' || !state.loading) {
				state.loading = 'pending';
				state.currentRequestId = action.meta.requestId;
			}
		});

		builder.addCase(thunks.getUpcomingPaymentsById.fulfilled, (state, action) => {
			const {
				requestId,
				arg: { loanId },
			} = action.meta;
			if (action.payload.success && action.payload.response) {
				upcomingPaymentsAdapter.upsertOne(state, {
					id: loanId,
					payments: action.payload.response,
				});
			}
			if (state.loading === 'pending' && state.currentRequestId === requestId) {
				state.loading = 'idle';
				state.currentRequestId = undefined;
			}
		});

		builder.addCase(thunks.getAllUpcomingPayments.fulfilled, (state, action) => {
			const { requestId } = action.meta;
			if (action.payload.success && action.payload.response) {
				upcomingPaymentsAdapter.upsertOne(state, {
					id: 'all',
					payments: action.payload.response,
				});
			}
			if (state.loading === 'pending' && state.currentRequestId === requestId) {
				state.loading = 'idle';
				state.currentRequestId = undefined;
			}
		});

		builder.addCase(thunks.getUpcomingPaymentsById.rejected, (state, action) => {
			if (!state) return;
			const { requestId } = action.meta;
			if (state.loading === 'pending' && state.currentRequestId === requestId) {
				state.loading = 'idle';
				state.currentRequestId = undefined;
			}
		});

		builder.addCase(thunks.getAllUpcomingPayments.rejected, (state, action) => {
			if (!state) return;
			const { requestId } = action.meta;
			if (state.loading === 'pending' && state.currentRequestId === requestId) {
				state.loading = 'idle';
				state.currentRequestId = undefined;
			}
		});
	},
);

const history = createReducer(
	paymentHistoryAdapter.getInitialState({
		loading: undefined as RequestLoadingStatus | undefined,
		currentRequestId: undefined as string | undefined,
	}),
	(builder) => {
		builder.addCase(thunks.getPaymentHistoryById.pending, (state, action) => {
			if (!state) return;
			if (state.loading === 'idle' || !state.loading) {
				state.loading = 'pending';
				state.currentRequestId = action.meta.requestId;
			}
		});

		builder.addCase(thunks.getPaymentHistoryById.rejected, (state, action) => {
			if (!state) return;
			const { requestId } = action.meta;
			if (state.loading === 'pending' && state.currentRequestId === requestId) {
				state.loading = 'idle';
				state.currentRequestId = undefined;
			}
		});

		builder.addCase(thunks.getPaymentHistoryById.fulfilled, (state, action) => {
			const {
				requestId,
				arg: { loanId },
			} = action.meta;
			if (action.payload.success && action.payload.response) {
				paymentHistoryAdapter.upsertOne(state, {
					id: loanId,
					payments: action.payload.response,
				});
			}
			if (state.loading === 'pending' && state.currentRequestId === requestId) {
				state.loading = 'idle';
				state.currentRequestId = undefined;
			}
		});
	},
);

const allHistory = createReducer(
	allPaymentsAdapter.getInitialState({
		loading: undefined as RequestLoadingStatus | undefined,
		currentRequestId: undefined as string | undefined,
	}),
	(builder) => {
		builder.addCase(thunks.getAllPaymentsHistory.fulfilled, (state, action) => {
			const { requestId } = action.meta;
			if (action.payload.success && action.payload.response) {
				allPaymentsAdapter.upsertOne(state, {
					id: 'all',
					payments: action.payload.response,
				});
			}
			if (state.loading === 'pending' && state.currentRequestId === requestId) {
				state.loading = 'idle';
				state.currentRequestId = undefined;
			}
		});
		builder.addCase(thunks.getAllPaymentsHistory.pending, (state, action) => {
			if (!state) return;
			if (state.loading === 'idle' || !state.loading) {
				state.loading = 'pending';
				state.currentRequestId = action.meta.requestId;
			}
		});
		builder.addCase(thunks.getAllPaymentsHistory.rejected, (state, action) => {
			if (!state) return;
			const { requestId } = action.meta;
			if (state.loading === 'pending' && state.currentRequestId === requestId) {
				state.loading = 'idle';
				state.currentRequestId = undefined;
			}
		});
	},
);

const summary = createReducer({} as PaymentSummary, (builder) => {
	builder.addCase(actions.updatePaymentSumary, (state, action) => ({ ...state, ...action.payload }));
	builder.addCase(actions.resetPaymentSumary, (_, action) => ({ ...action.payload } as PaymentSummary));
});

const missed = createReducer(
	paymentMissedsAdapter.getInitialState({
		shownModal: false,
		loading: undefined as RequestLoadingStatus | undefined,
		currentRequestId: undefined as string | undefined,
	}),
	(builder) => {
		builder
			.addCase(actions.setPaymentsMissed, (state, action) => {
				paymentMissedsAdapter.setAll(state, action.payload);
			})
			.addCase(actions.setPaymentMissedShownModal, (state, action) => {
				state.shownModal = action.payload;
			});
	},
);

const enrollAutopayflow = createReducer(null as EnrollAutopayFlowType | null, (builder) => {
	builder.addCase(actions.setEnrollAutopay, (_, action) => action.payload);
	builder.addCase(actions.cleanEnrollAutopay, () => null);
});

const paymentsReducer = combineReducers({
	methods,
	upcoming,
	summary,
	history,
	missed,
	allHistory,
	enrollAutopayflow,
});

export type PaymentsState = ReturnType<typeof paymentsReducer>;

const persistPaymentsReducer = persistConfigPayments
	? (persistReducer(persistConfigPayments, paymentsReducer) as unknown as typeof paymentsReducer)
	: paymentsReducer;

export default persistPaymentsReducer;
