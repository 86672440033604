import React from 'react';

// Images
// import imgWelcome from '@images/billboard/welcome.jpg';

// Styles
import '../styles/billboard.scss';

const LoginBillboard = () => {
	return (
		<div className="lcp-form-box lcp-form-right">
			<div className="uk-card lcp-billboard">
				<div
					className="uk-position-relative uk-visible-toggle uk-light uk-height-1-1"
					tabIndex={-1}
					uk-slideshow="autoplay: true; autoplay-interval: 6000; animation: fade"
				>
					<ul className="uk-slideshow-items uk-height-1-1">
						<li>
							{/* <img src={imgWelcome} alt="" uk-cover="true" /> */}
							<div className="uk-position-center uk-padding-large uk-text-left uk-panel">
								<h2 className="uk-heading-large">Everyone deserves to feel the power of possibilities</h2>
								<p className="uk-heading">LendingPoint has the personal loan you need to expand what’s possible.</p>
							</div>
						</li>
					</ul>
					<div className="uk-position-bottom-center uk-position-small">
						<ul className="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default React.memo(LoginBillboard);
