import { createAsyncThunk } from '@reduxjs/toolkit';
import { setAsDeleted } from './actions';

import * as MailServices from '../../services/mail';

import type { MailGetAllResponse, NotificationType } from '../../utils';
import type { ApiResponse } from '@cp/common/services/utils';

export const getNotificationList = createAsyncThunk(
	'notifications/getNotificationList',
	async (): Promise<ApiResponse<MailGetAllResponse>> => {
		const { success, response, error } = await MailServices.getMails();
		if (success && response) {
			return { success: true, response };
		}
		return { success: false, error };
	},
);

export const getNotificationDetails = createAsyncThunk(
	'notifications/getDetails',
	async (notificationId: number): Promise<ApiResponse<NotificationType>> => {
		const { success, response, error } = await MailServices.getMailDetail(notificationId);
		if (success && response) {
			// TODO: Check if notification ID affect by response or fix spread
			//@ts-ignore keep spread
			return { success: true, response: { id: notificationId, ...response } };
		}
		return { success: false, error };
	},
);

export const deleteNotification = createAsyncThunk(
	'notifications/delete',
	async (notification: NotificationType, { dispatch }): Promise<ApiResponse<any>> => {
		const { success, error } = await MailServices.deleteMail(notification.id);

		dispatch(setAsDeleted(notification));
		if (success) {
			return { success };
		}
		return { success, error };
	},
);
