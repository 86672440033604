import { shouldPolyfill } from '@formatjs/intl-pluralrules/should-polyfill';

if (shouldPolyfill()) {
	// Load the polyfill 1st BEFORE loading data
	require('@formatjs/intl-pluralrules/polyfill');
}
//@ts-ignore check if apply polyfill
if (Intl.PluralRules.polyfilled) {
	require('@formatjs/intl-pluralrules/locale-data/en');
}
