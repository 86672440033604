import * as React from 'react';

import type { TagPlaceholderCallback } from '../../shared/utils';

import { defineMessage } from '../../shared/helpers';

const messages = {
	login: {
		letsGetStarted: "Let's get started.",
		subtitle: 'Log in to your account',
		forgotPasswordBtn: 'Forgot password?',
		submitBtn: 'Log In',
		incorrectDataHint: 'Email address or password are incorrect. Please try again or',
		resetPasswordHere: 'reset password here.',
		dontHaveAnAccount: "Don't have an account?",
		signUpHere: 'Sign up here',
		rememberMe: 'Remember me',
		form: {
			emailAddress: 'Email address',
			password: 'Password',
		},
		validation: {
			required: 'This is required',
		},
	},
	forgotPassword: {
		title: 'Reset your password',
		backToSignInBtn: 'Back to log in',
		submitBtn: 'Reset',
		enterYourEmail: 'Enter your email to receive password reset instructions.',
		submit: 'Submit',
		instructionsSent: 'Password reset instructions sent!',
		successHint1:
			'If we have an account associated with that email, you will receive a message with instructions on how to reset your password shortly.',
		successHint2: 'Please allow up to 15 minutes for the message to be delivered.',
		continue: 'Continue',
		forms: {
			emailAddress: 'Email address',
		},
		validation: {
			required: 'This is required',
		},
	},
	forgotPasswordResultModal: {
		body: defineMessage<
			{ email: string; bold: TagPlaceholderCallback<string>; br: React.ReactNode },
			string | React.ReactElement
		>({
			id: 'forgotPassword.result',
			defaultMessage:
				'If we have an account associated with that email, you will receive a message with instructions on how to reset the password.{br}<bold>{email}</bold>',
		}),
	},
	wantLearnMoreTitle: 'Want to learn more?',
	seeYourLoanOptionsTitle: 'See your loan options',
	seeYourLoanOptionsDescription: 'LOANS FROM $2,000 - $36,500',
	contactUsTitle: 'Contact us',
	contactUsDescription: 'How can we help?',
	sessionTimeout: {
		title: 'Session Timeout',
		body: 'Due to inactivity and for your security,\n you’ve been logged out.',
	},
} as const;

export default messages;
