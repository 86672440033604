import { Documents, InitialStateGetOffers, LoadingStatus, PaymentMethods, RefinanceState, SaveAgreement, TilaType } from './type';

export const initialStateGetOffers: InitialStateGetOffers = {
	offers: null,
	loading: LoadingStatus.NOT_LOADED,
	selectedOffer: null,
	offersError: null,
	opportunity: null,
	experimentGroup: null,
};

export const initialPaymentMethod: PaymentMethods = {
	paymentMethods: null,
	loading: 'loading',
	paymentMethodsError: '',
};

export const initialState: RefinanceState = {
	opportunityError: '',
	loading: LoadingStatus.NOT_LOADED,
	opportunity: null,
	yearlySaving: null,
	hasYearlySaving: false,
	creditUnionOption: undefined,
};

export const initialSaveAgreement: SaveAgreement = {
	saveAgreement: null,
	loading: 'loading',
	saveAgreementError: '',
};

export const initialStateDocuments: Documents = {
	documents: null,
};

export const initialTila: TilaType = {
	tila: null,
	tilaError: '',
	loading: 'loading',
};
