// import { persistConfigPlaid } from '@cp/common/config/persist';
import { combineReducers, createReducer } from '@reduxjs/toolkit';
// import persistReducer from 'redux-persist/es/persistReducer';
import { PLAIDState } from '../../utils';
import { fetchLinkToken, savePlaidPublicToken } from '../actions/thunks';
import * as reducers from './plaid.reducers';
export const plaidInitialState: PLAIDState = {
	loadingStatusFetchLinkToken: 'not loaded',
	errorFetchLinkToken: null,
	dataFetchLinkToken: null,
	loadingStatusSavePlaidPublicToken: 'not loaded',
	errorSavePlaidPublicToken: null,
	dataSavePlaidPublicToken: null,
};
const plaid = createReducer(plaidInitialState, (builder) => {
	builder
		.addCase(fetchLinkToken.pending, reducers.fetchLinkTokenPending)
		.addCase(fetchLinkToken.fulfilled, reducers.fetchLinkTokenFulfilled)
		.addCase(fetchLinkToken.rejected, reducers.fetchLinkTokenRejected)
		.addCase(savePlaidPublicToken.pending, reducers.savePlaidPublicTokenPending)
		.addCase(savePlaidPublicToken.fulfilled, reducers.savePlaidPublicTokenFulfilled)
		.addCase(savePlaidPublicToken.rejected, reducers.savePlaidPublicTokenRejected);
});

const plaidReducer = combineReducers({
	plaid,
});

export type PlaidState = ReturnType<typeof plaidReducer>;

// const persistPlaidReducer = persistConfigPlaid
// 	? (persistReducer(persistConfigPlaid, plaidReducer) as unknown as typeof plaidReducer)
// 	: plaidReducer;

export default plaidReducer;
