import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

// Constants
import { routes } from '@cp/common/navigation/constants/routes.constants';

type ProtectedAuthRouteProps = {
	isLogged: boolean;
	children: any;
	isOctp: boolean;
	isInMaintenance: boolean;
} & RouteProps;

const ProtectedAuthRoute = ({ isLogged, children, isInMaintenance, ...rest }: ProtectedAuthRouteProps) => {
	return (
		<>
			{isInMaintenance ? (
				<Redirect to={`/${routes.maintenance}`} />
			) : (
				<Route
					{...rest}
					render={({ location }) =>
						!isLogged ? (
							children
						) : (
							<Redirect
								to={{
									pathname: `/${routes.loans}`,
									state: { from: location },
								}}
							/>
						)
					}
				/>
			)}
		</>
	);
};

export default ProtectedAuthRoute;
