import * as Yup from 'yup';
export { default as messages } from './messages';
export { default as messagesWeb } from './messages.web';

export const formsKeys = {
	editLoanNicknameScreen: {
		nickname: 'nickname',
	},
} as const;

const MAX_NICKNAME_LENGTH = 25;

export const forms = {
	editLoanNicknameScreen: {
		placeholders: {
			[formsKeys.editLoanNicknameScreen.nickname]: 'Nickname',
		},
		schema: Yup.object({
			[formsKeys.editLoanNicknameScreen.nickname]: Yup.string().max(MAX_NICKNAME_LENGTH, 'Maximum 25 characters').trim(),
		}),
	},
} as const;

/**
 * redux key name
 */
export const NAME = 'loans';

/**
 * LoanType for danger loan or blue loan
 */
export const LoanType = {
	danger: {
		border: 'borderDanger',
		back: 'backDanger',
	},
	default: {
		border: 'borderInfo',
		back: 'backInfo',
	},
};

export enum LoanSources {
	ebay = 'eBaySellerCapital',
	invisalign = 'Invisalign',
	default = 'default',
}

export enum LoanSubSources {
	ebay = 'eBayLoan',
}

export enum LoanQuickLinkTypes {
	APPLY = 'apply',
	MAKE_PAYMENT = 'make_a_payment',
	ENROLL_AUTOPAY = 'enroll_in_autopay',
	MANAGE_AUTOPAY = 'manage_autopay',
	REFINANCE = 'refinance',
	COMPLETED_LOAN = 'completed_loans',
	PAYMENT_METHODS = 'payment_methods',
	VIEW_PAYMENT_METHODS = 'view_payment_methods',
	REVIEW_PAYMENT_METHODS = 'review_payment_methods',
	FICO = 'view_fico_score',
	DIGITAL_CARD = 'view_digital_card',
	PERSONAL_INFORMATION = 'personal_information',
	ACCOUNT_SETTINGS = 'settings',
	DOCUMENTS = 'documents',
}

export const testIDs = {
	screens: {
		delinquentNotice: 'delinquent-notice-scrn',
		dashboard: 'dashboard-scrn',
		loanDetails: 'loan-details-scrn',
	},
	iconButtons: {
		quickActions: 'quick-actions-btn',
		editLoanNickName: 'edit-loan-nickname-btn',
		delinquentNotice: 'delinquent-notice-scrn',
		dashboard: 'dashboard-scrn',
	},
	screenElements: {
		delinquentItems: 'delinquent-items-elmt',
	},
	icons: {
		autoPayOn: 'auto-pay-on-icon',
		autoPayOff: 'auto-pay-off-icon',
		warning: 'warning-icon',
		iconInfoPromotional: 'icon-info-promotional',
	},
	buttons: {
		makePayment: 'make-payment-btn',
		viewDetails: 'view-details-btn',
		enrollAutoPay: 'enroll-auto-pay-btn',
		contactUs: 'contact-us-btn',
		continueDashboard: 'continue-dashboard-btn',
		sendEmail: 'send-email-btn',
		badgesEnrollInAutopay: 'badges-enroll-in-autopay-btn',
		navigateToEnrollAutopay: 'navigate-to-enroll-autopay-btn',
		cancelEditNickname: 'cancel-edit-nickname-btn',
		editNicknameSubmit: 'edit-nickname-submit-btn',
		closeModalEditNickname: 'close-modal-edit-nickname-btn',
	},
};
