import * as React from 'react';
import * as ReactDOM from 'react-dom';
import isBoolean from 'lodash/isBoolean';
import UIkit from 'uikit';

type UIModalOptions = {
	/**
	 * @description Close the modal when the Esc key is pressed.
	 * @default true
	 */
	bgClose?: boolean;
	/**
	 * @description Close the modal when the background is clicked.
	 * @default true
	 */
	escClose?: boolean;
	/**
	 * @description Stack modals, when more than one is open. By default, the previous modal will be hidden.
	 * @default false
	 */
	stack?: boolean;
};
/**
 * Oficial modal doc.
 * @see https://getuikit.com/docs/modal
 */
export type UIKitModalProps = {
	id: string;
	className?: string;
	onShown?: (ev: Event) => void;
	onHidden?: (ev: Event) => void;
} & UIModalOptions;

const modalRoot = document.getElementById('modal-root');

const createModalElement = (id: string, className: string | undefined, options: UIModalOptions) => {
	const modalEl = document.createElement('div');
	// set modal id into div
	modalEl.id = id;
	// set classes
	if (className) modalEl.className = className;
	// add modal options, see: https://getuikit.com/docs/modal#component-options
	let modalOptions = 'container: false;'; // Define a target container via a selector to specify where the modal should be appended in the DOM. Setting it to false will prevent this behavior.
	if (isBoolean(options.stack)) modalOptions += `stack: ${options.stack};`;
	if (isBoolean(options.bgClose)) modalOptions += `bg-close: ${options.bgClose};`;
	if (isBoolean(options.escClose)) modalOptions += `esc-close: ${options.escClose};`;
	// set uk-modal for UIkit detect a modal div
	modalEl.setAttribute('uk-modal', modalOptions);
	return modalEl;
};

const UIKitModal: React.FC<UIKitModalProps> = ({ onShown, onHidden, id: modalId, children, className, ...options }) => {
	const isMounted = React.useRef(true);
	const isTransitioning = React.useRef(false);
	const modalElRef = React.useRef(createModalElement(modalId, className, options));

	React.useEffect(() => {
		isMounted.current = true;

		// append to body, UIKit clone directly in body and avoid errors by remove
		modalRoot?.appendChild(modalElRef.current);
		// callback to remove modal after unmount or detect transition
		const removeModal = () => {
			try {
				modalRoot?.removeChild(modalElRef.current);
			} catch (error) {
				if (__DEV__) console.warn(error);
			}
		};
		//@ts-ignore fix UIkit typos
		const unsubscribe = UIkit.util.on(modalElRef.current, 'show shown hide hidden', (ev: UIEvent) => {
			// if (__DEV__) console.log('[MODAL EVENT]', ev);
			if (ev.type === 'show' || ev.type === 'hide') {
				isTransitioning.current = true;
			} else {
				isTransitioning.current = false;
				if (ev.type === 'hidden') {
					onHidden?.(ev);
					if (!isMounted.current) {
						unsubscribe();
						removeModal();
					}
				} else {
					onShown?.(ev);
				}
			}
		});

		return () => {
			isMounted.current = false;
			if (isTransitioning.current) {
				return;
			}
			unsubscribe();
			removeModal();
		};
	}, []);

	return ReactDOM.createPortal(children, modalElRef.current, modalId);
};

export default UIKitModal;
