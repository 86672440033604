import * as CryptoJS from 'crypto-js';

import * as crypto from '@cp/common/utils/crypto';

export const decryptData = (data: string, key: string) => {
	try {
		const bytes = CryptoJS.AES.decrypt(data, key);
		const response = bytes.toString(CryptoJS.enc.Utf8);
		return JSON.parse(response);
	} catch (error) {
		return undefined;
	}
};

export const encryptData = (data: { [key: string]: number | string | boolean }): { key: string; dataEncrypted: string } => {
	const id = crypto.createID();
	const key = `AES-${id}`;

	const dataEncrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();

	return {
		key,
		dataEncrypted,
	};
};
