import shared from '../../../shared';
import { NAME } from '../../constants';

export default shared.helpers.createActionTypes(NAME, [
	'TYPES_ALL.ATTEMPT',
	'REFINANCE_SELECTED_OFFER.SET_SELECTED_OFFER',
	'REFINANCE_CLEAR.CLEAR_OFFERS',
	'REFINANCE_DOCUMENTS.SET_DOCUMENTS',
	'REFINANCE_DOCUMENTS.CLEAR_DOCUMENTS',
	'REFINANCE_OPPORTUNITY.CLEAR_OPPORTUNITY',
	'REFINANCE_MODAL.HIDEN',
]);

export interface FetchOffers {
	reqData: string | undefined;
}
