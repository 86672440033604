import * as constants from '@cp/common/lendingpoint/constants';
import { isNative } from '@cp/common/platform';
import {
	GENERIC_API_ERROR_EVENT,
	INTERNAL_SERVER_ERROR_EVENT,
	logEventToAnalytics,
	UNAUTHORIZED_SERVER_ERROR_EVENT,
} from '@cp/common/utils/analytics';

import type { AxiosError } from 'axios';

import { handleError as logHttpErrorToSentry } from '../../utils/services';

const { errorNames } = constants;

export const httpErrorLogger = (apiName: string, apiErrorCode: string, error: AxiosError) => {
	const status = error.response?.status || 0;
	let paramsAnalytics;
	// Log server errors in sentry
	if (status > 400) {
		paramsAnalytics = logHttpErrorToSentry(error);
	}
	if (isNative) {
		let eventName;
		if (status >= 500) {
			eventName = INTERNAL_SERVER_ERROR_EVENT;
		} else if (apiErrorCode === errorNames.unauthorized) {
			eventName = UNAUTHORIZED_SERVER_ERROR_EVENT;
		} else {
			eventName = GENERIC_API_ERROR_EVENT;
		}
		if (eventName)
			logEventToAnalytics(eventName, {
				apiName,
				url: error?.config?.url,
				baseURL: error?.config?.baseURL,
				status,
				errorMessage: paramsAnalytics?.errorMessage,
			});
	}
	return null;
};
