import { isInteger } from 'lodash';
import * as Yup from 'yup';

import config from '@cp/common/config';

import { formatPhone } from '../../shared/helpers/format';

export { default as messages } from './messages';
export { default as messagesWeb } from './messages.web';

export const NAME = 'auth';

const LEAST_EIGHT_CHARACTERS_REGEX = /.{8,}/;
const LEAST_ONE_NUMBER_REGEX = /\d/;
const LEAST_ONE_UPPERCASE_LETTER_REGEX = /[A-Z]/;
const LEAST_ONE_LOWERCASE_LETTER_REGEX = /[a-z]/;
const LEAST_ONE_SPECIAL_CHARACTER_REGEX = /[~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

export const formKeys = {
	changePassword: {
		oldPassword: 'oldPassword',
		password: 'password',
		confirmPassword: 'confirmPassword',
	},
};

export const customValidationMessages = {
	login: {
		email: {
			email: 'Invalid email address',
			required: 'Enter your email address',
		},
		emailOrPhone: {
			email: 'Invalid email',
			required: 'Email / Phone is required',
		},
		password: {
			required: 'Enter your password',
		},
		otpNumber: {
			required: 'Please enter a passcode.',
			requiredLengthNumber: 'the passcode must contain 6 digits.',
		},
	},
	resetPassword: {
		oldPassword: {
			required: 'Old Password is required',
		},
		password: {
			required: 'Password is required',
			minimumOfEightCharacters: 'A minimum of 8 characters',
			leastOneNumber: 'At least 1 number',
			leastOneUppercaseLetter: 'At least 1 uppercase letter',
			leastOneLowercaseLetter: 'At least 1 lowercase letter',
			leastOneSpecialCharacter: 'At least 1 special character',
		},
		confirmPassword: {
			required: 'Confirm New Password is required',
			noMatch: 'Passwords do not match',
			noRepeated: 'Current password cannot be used as new password',
		},
	},
	forgotPassword: {
		email: {
			email: 'Please use a valid email address',
			required: 'Invalid email address',
		},
		password: {
			required: 'Password is required',
			minimumOfEightCharacters: 'A minimum of 8 characters',
			leastOneNumber: 'At least 1 number',
			leastOneUppercaseLetter: 'At least 1 uppercase letter',
			leastOneLowercaseLetter: 'At least 1 lowercase letter',
			leastOneSpecialCharacter: 'At least 1 special character',
		},
		confirmPassword: {
			required: 'This field is required',
			noMatch: 'Passwords do not match',
		},
	},
};

const forgotPasswordSchema = Yup.object({
	email: Yup.string()
		.required(customValidationMessages.login.email.required)
		.ensure()
		.trim()
		.lowercase()
		.email(customValidationMessages.login.email.email),
});

const highPasswordSchema = Yup.string().test(formKeys.changePassword.password, 'Password error', function () {
	const { path, createError, options } = this;
	const { originalValue }: Yup.ValidateOptions & { originalValue?: string } = options;
	let customError = {};
	if (!originalValue || !LEAST_EIGHT_CHARACTERS_REGEX.test(originalValue as string)) {
		const error = { ...customError, minimumOfEightCharacters: 'A minimum of 8 characters' };
		customError = error;
	}
	if (!LEAST_ONE_NUMBER_REGEX.test(originalValue as string)) {
		const error = { ...customError, leastOneNumber: 'At least 1 number' };
		customError = error;
	}
	if (!LEAST_ONE_UPPERCASE_LETTER_REGEX.test(originalValue as string)) {
		const error = { ...customError, leastOneUppercaseLetter: 'At least 1 uppercase letter' };
		customError = error;
	}
	if (!LEAST_ONE_SPECIAL_CHARACTER_REGEX.test(originalValue as string)) {
		const error = { ...customError, leastOneSpecialCharacter: 'At least 1 special character' };
		customError = error;
	}
	if (!LEAST_ONE_LOWERCASE_LETTER_REGEX.test(originalValue || '')) {
		const error = { ...customError, leastOneLowercaseLetter: 'At least 1 Lowercase letter' };
		customError = error;
	}
	if (Object.values(customError).length) return createError({ path, message: JSON.stringify(customError) });
	return true;
});

const passwordMatchSchema = Yup.string()
	.oneOf([Yup.ref(formKeys.changePassword.password)], customValidationMessages.resetPassword.confirmPassword.noMatch)
	.required(customValidationMessages.resetPassword.confirmPassword.required);

const changePasswordSchema = Yup.object({
	oldPassword: Yup.string().required(customValidationMessages.resetPassword.oldPassword.required),
	password: highPasswordSchema,
	confirmPassword: passwordMatchSchema,
});

const createPasswordSchema = Yup.object({
	password: highPasswordSchema,
	confirmPassword: passwordMatchSchema,
});

export const validateEmail = (email: string) =>
	Yup.string().email(customValidationMessages.login.emailOrPhone.email).ensure().trim().lowercase().isValidSync(email);
export const validatePhone = (phone: string) =>
	Yup.string()
		.trim()
		.test('phone', 'Phone number is invalid', (value: string) => {
			const clearValue = value.replace(/[\s()-]/g, '');
			if (isInteger(Number(clearValue)) && clearValue.length === 10) {
				const formatedPhone = formatPhone(clearValue);
				if (formatedPhone.length === 14) return true;
			}
			if (value.length === 10) {
				const formatedPhone = formatPhone(value);
				if (formatedPhone.length === 14) return true;
			}
			return false;
		})
		.isValidSync(phone);

export const forms = {
	login: {
		initialValues: {
			email: __DEV__ ? config.login.initialValues.username : '',
			password: __DEV__ ? config.login.initialValues.password : '',
		},
		labels: {
			email: 'Email address',
			password: 'Password',
		},
		placeholders: {
			email: 'myemail@email.com',
			password: '••••••••••••••',
		},
		schema: Yup.object({
			email: Yup.string()
				.email(customValidationMessages.login.email.email)
				.required(customValidationMessages.login.email.required)
				.ensure()
				.trim()
				.lowercase(),
			password: Yup.string().required(customValidationMessages.login.password.required),
		}),
	},
	loginWithPasswordless: {
		initialValues: {
			step: 1,
			emailOrPhone: '',
			password: '',
			otpNumber: '',
		},
		initialErrors: {
			emailOrPhone: '',
			password: '',
		},
		labels: {
			emailOrPhone: 'Email / Phone is required',
			password: 'Password',
		},
		placeholders: {
			emailOrPhone: 'Email / Phone is required',
			password: '••••••••••••••',
		},
		schema: Yup.object({
			step: Yup.number().integer().positive().notRequired(),
			emailOrPhone: Yup.string()
				.required(customValidationMessages.login.emailOrPhone.required)
				.test('step', 'Email / Phone is required', (value) => validateEmail(value) || validatePhone(value)),
			password: Yup.string().when('step', {
				is: 2,
				then: Yup.string().required(customValidationMessages.login.password.required),
				otherwise: Yup.string().notRequired(),
			}),
			otpNumber: Yup.string().when('step', {
				is: 3,
				then: Yup.string()
					.min(6, customValidationMessages.login.otpNumber.requiredLengthNumber)
					.max(6, customValidationMessages.login.otpNumber.requiredLengthNumber)
					.required(customValidationMessages.login.otpNumber.required),
				otherwise: Yup.string().notRequired(),
			}),
		}),
	},
	forgotPassword: {
		initialValues: {
			email: '',
		},
		labels: {
			email: 'Enter the email address associated with your account',
		},
		placeholders: {
			email: 'myemail@email.com',
		},
		schema: forgotPasswordSchema,
	},
	resetPassword: {
		initialValues: {
			password: __DEV__ ? 'Test12345' : '',
		},
		labels: {
			password: 'New Password',
		},
		placeholders: {
			password: '',
		},
		schema: Yup.object({
			password: Yup.string().required(),
		}),
	},
	changePassword: {
		initialValues: {
			oldPassword: '',
			password: '',
			confirmPassword: '',
		},
		labels: {
			email: 'To reset your password please enter the email address on file to receive directions via email.',
		},
		placeholders: {
			email: 'myemail@email.com',
		},
		schema: changePasswordSchema,
	},
	createPassword: {
		initialValues: {
			password: '',
			confirmPassword: '',
		},
		schema: createPasswordSchema,
	},
};

export const testIDs = {
	buttons: {
		login: 'auth-login-btn',
		signup: 'auth-sign-up-btn',
		continue: 'auth-continue-app-btn',
		forceUpdate: 'auth-update-now-btn',
		skipForceUpdate: 'auth-skip-update-btn',
		submitResetPassword: 'auth-reset-password-btn',
		setUpBiometricAuth: 'auth-set-up-bio-auth-btn',
		skipBiometricAuth: 'auth-skip-bio-auth-btn',
	},
	inputs: {
		email: 'auth-email-input',
		emailInForgotPassword: 'auth-email-in-forgot-password-input',
		password: 'auth-password-input',
		remember: 'auth-remember-input',
	},
	links: {
		signUpHere: 'auth-sign-up-here-link',
		forgotPassword: 'auth-forgot-password-link',
		forgotPassword1: 'auth-forgot-password-link1',
		terms: 'auth-terms-link',
		privacyPolicy: 'auth-privacy-policy-link',
		biometric: 'auth-biometric-link',
		backToSignIn: 'auth-back-to-sign-in',
	},
	formState: {
		errorMessage: 'auth-error-message',
	},
	iconButtons: {
		clearInput: 'auth-clear-input-btn',
		togglePassword: 'auth-toggle-password-btn',
	},
	screens: {
		forceUpdate: 'auth-force-update-scrn',
		resetPassword: 'auth-reset-password-scrn',
		forgetPasswordResult: 'auth-forget-password-result-scrn',
		sessionTimeout: 'auth-session-timeout-scrn',
		allowBiometricAuth: 'auth-allow-bio-auth-scrn',
		successBiometricAuth: 'auth-success-bio-auth-scrn',
	},
};
