import { createAction } from '@reduxjs/toolkit';

import { NotificationType } from '../../utils';

import types from './types';

export const addNotification = createAction<NotificationType>(types.NOTIFICATION_LIST.ADD);

export const setAsRead = createAction<NotificationType>(types.NOTIFICATION_DETAILS.SET_AS_READ);

export const setAsDeleted = createAction<NotificationType>(types.NOTIFICATION_DETAILS.SET_AS_DELETED);
