import { combineReducers, createReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { actions, thunks } from '../actions';
import { fetchKYCQuestions, saveKYCAnswers } from '../actions/thunks';
import { initialState, initialStateDocuments, initialStateGetOffers, initialTila } from './initialStateReducer';
import { verifyIdentityInitialState } from './tools';
import { LoadingStatus, VerifyIdentityState } from './type';

const offersRefinance = createReducer(initialStateGetOffers, (builder) => {
	builder.addCase(thunks.getOffersNewrefi.fulfilled, (state, action) => {
		if (action.payload.success && action.payload) {
			state.offers = action.payload.offers ?? null;
			state.loading = LoadingStatus.LOADED;
		} else if (!action.payload.success) {
			state.offers = null;
			state.offersError = action.payload.errorMessage;
		}
	});

	builder.addCase(thunks.getOffersNewrefi.pending, (state) => {
		state.loading = LoadingStatus.LOADING;
	});
	builder.addCase(actions.setSelectedOfferNew, (state, action) => {
		state.selectedOffer = action.payload;
	});
	builder.addCase(actions.clearOffers, (state) => {
		state.offers = null;
		state.loading = LoadingStatus.NOT_LOADED;
		state.selectedOffer = null;
		state.offersError = null;
	});
	builder.addCase(thunks.getRefinanceOffersEbay.fulfilled, (state, action) => {
		if (action.payload.success && action.payload.offers) {
			state.offers = action.payload.offers;
			state.experimentGroup = action.payload.experimentGroup;
			if (state.opportunity && action.payload.disbursalAmount) {
				state.opportunity.disbursalAmount = action.payload.disbursalAmount;
			}
		} else if (action.payload.errorMessage) {
			state.offers = null;
		}
	});
});

const opportunity = createReducer(initialState, (builder) => {
	builder.addCase(thunks.getOpportunity.fulfilled, (state, action) => {
		if (action.payload.success && action.payload.opportunity) {
			state.opportunity = action.payload.opportunity;
			state.yearlySaving = action.payload.opportunity.yearlySaving;
			state.hasYearlySaving = action.payload.opportunity.hasyearlySaving;
			state.loading = LoadingStatus.LOADED;
		} else if (action.payload.errorMessage) {
			state.opportunity = null;
			state.loading = LoadingStatus.ERROR;
			state.opportunityError = action.payload.errorMessage;
		}
	});
	builder.addCase(actions.clearOpportunity, (state) => {
		state.opportunity = null;
		state.loading = LoadingStatus.NOT_LOADED;
	});

	builder.addCase(thunks.isCreditUnionOption.fulfilled, (state, action) => {
		state.creditUnionOption = action.payload;
	});
});

const documents = createReducer(initialStateDocuments, (builder) => {
	builder.addCase(thunks.getDocumentsThunks.fulfilled, (state, action) => {
		state.documents = action.payload?.payload?.Attachments ?? null;
	});
	builder.addCase(actions.clearDocuments, (state) => {
		state.documents = null;
	});
});

const tila = createReducer(initialTila, (builder) => {
	builder.addCase(thunks.getTila.fulfilled, (state, action) => {
		if (action.payload.success && action.payload.response) {
			state.tila = action.payload.response;
			state.loading = 'loaded';
		} else if (action.payload.errorMessage) {
			state.tila = null;
			state.loading = 'loaded';
			state.tilaError = action.payload.errorMessage;
		}
	});
});

// KYC __________

export const addTry = (state: VerifyIdentityState) => {
	state.currentTry++;
};

export const fetchKYCQuestionsPending = (state: VerifyIdentityState) => {
	state.loadingStatusFetchKYCQuestions = LoadingStatus.LOADING;
};
export const fetchKYCQuestionsFulfilled = (state: VerifyIdentityState, action: PayloadAction<any>) => {
	// add a number for every question as those doesn't come from backend
	state.dataFetchKYCQuestions = action.payload.response;
	state.loadingStatusFetchKYCQuestions = LoadingStatus.LOADED;
};

export const fetchKYCQuestionsRejected = (state: VerifyIdentityState) => {
	state.loadingStatusFetchKYCQuestions = LoadingStatus.ERROR;
	//  state.errorFetchKYCQuestions = action.payload.error.message;
	state.errorFetchKYCQuestions = 'error'; // TO DO
};
export const saveKYCAnswersPending = (state: VerifyIdentityState) => {
	state.loadingStatusSaveKYCAnswers = LoadingStatus.LOADING;
};

export const saveKYCAnswersFulfilled = (state: VerifyIdentityState, action: PayloadAction<any>) => {
	state.dataSaveKYCAnswers = action.payload.response;
	state.loadingStatusSaveKYCAnswers = LoadingStatus.LOADED;
};

//e[pfkserewoijriowerj

export const saveKYCAnswersRejected = (state: VerifyIdentityState, action: PayloadAction<any>) => {
	state.loadingStatusSaveKYCAnswers = LoadingStatus.ERROR;
	state.errorSaveKYCAnswers = action.payload.message;
};
export const setKYCQuestions = (state: VerifyIdentityState, action: PayloadAction<any>) => {
	state.kYCQuestions = action.payload.map(
		// TODO: declare rawQuestion's type in some place (it is already declared in the service-qr-apply)
		// but could be in a more central place?
		(rawQuestion: { question: string; type: string; options: string[] }, idx: number) => {
			return {
				questionNumber: idx + 1,
				question: rawQuestion.question,
				type: rawQuestion.type,
				options: rawQuestion.options,
				answer: 'null',
				isSkipped: false,
			};
		},
	);
	// KYC's endpoint sends 5 questions. We should show 4 questions
	// and show the last one if the user skips KYC
	const lastQuestion = state.kYCQuestions[state.kYCQuestions.length - 1];
	lastQuestion.isSkipped = true;
};
export const saveKYCAnswer = (state: VerifyIdentityState, action: PayloadAction<any>) => {
	const questionToAnswer = state.kYCQuestions.find((question) => question.type === action.payload.type);
	if (questionToAnswer) questionToAnswer.answer = action.payload.answer;
};
export const skipKYCQuestion = (state: VerifyIdentityState, action: PayloadAction<any>) => {
	if (!state.alreadySkipped) {
		const questionToSkip = state.kYCQuestions.find((question) => question.type === action.payload.type);
		if (questionToSkip) {
			questionToSkip.isSkipped = true;
			// the last question must change its skipped status and should be
			// shown to the user
			const lastQuestion = state.kYCQuestions[state.kYCQuestions.length - 1];
			lastQuestion.isSkipped = false;
			state.alreadySkipped = true;
		}
	}
};

export const verifyIdentitySlice = createSlice({
	name: 'KYC',
	initialState: verifyIdentityInitialState,
	reducers: {
		addTry: addTry,
		setKYCQuestions: setKYCQuestions,
		saveKYCAnswer: saveKYCAnswer,
		skipKYCQuestion: skipKYCQuestion,
		resetKYCState: () => verifyIdentityInitialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchKYCQuestions.pending, fetchKYCQuestionsPending)
			.addCase(fetchKYCQuestions.fulfilled, fetchKYCQuestionsFulfilled)
			.addCase(fetchKYCQuestions.rejected, fetchKYCQuestionsRejected)
			.addCase(saveKYCAnswers.pending, saveKYCAnswersPending)
			.addCase(saveKYCAnswers.fulfilled, saveKYCAnswersFulfilled)
			.addCase(saveKYCAnswers.rejected, saveKYCAnswersRejected);
	},
});

export const verifyIdentity = verifyIdentitySlice.reducer;

const refinance2Reducer = combineReducers({
	offersRefinance,
	opportunity,
	documents,
	tila,
	verifyIdentity,
});

export const addTryAction = verifyIdentitySlice.actions.addTry;
export const resetKYCStateAction = verifyIdentitySlice.actions.resetKYCState;
export const saveKYCAnswerAction = verifyIdentitySlice.actions.saveKYCAnswer;
export const setKYCQuestionsAction = verifyIdentitySlice.actions.setKYCQuestions;
export const skipKYCQuestionAction = verifyIdentitySlice.actions.skipKYCQuestion;

export default refinance2Reducer;
