import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

import type { AxiosRequestConfig } from 'axios';
import type { ApiResponse } from '../../../services/utils';
import type { PaymentMethodType, PaymentMissed } from '../../payments/utils';

import { lendingpoint } from '../../../services';
import {
	AutopaySetupValidationAction,
	AutopaySetupValidationResponse,
	CompletedLoansResponse,
	Loan,
	LoanDetailsType,
	LoanQuickLinkResponse,
	LoansResponse,
	PaymentHistoryResponse,
	PaymentMissedResponse,
	QuickActionsRoutesName,
	ResponseSendCodeVirtualCard,
	TransactionTypeVirtualCard,
	VirtualCardDetails,
} from '../utils';

const {
	api: { consumer },
	constants: { paths },
	helpers: { handleSubModuleError },
} = lendingpoint;

export const getLoans = async (config?: AxiosRequestConfig): Promise<ApiResponse<LoansResponse>> => {
	try {
		const response = await consumer.get<LoansResponse>(paths.loans, {
			'axios-retry': {
				retries: 2,
			},
			...config,
		});
		return { success: true, response: response.data };
	} catch (err: any) {
		return handleSubModuleError(err);
	}
};

export const getLoanDetails = async (loanId: string, config?: AxiosRequestConfig): Promise<ApiResponse<LoanDetailsType>> => {
	try {
		const response = await consumer.get<LoanDetailsType>(`${paths.loans}/${loanId}`, {
			'axios-retry': {
				retries: 2,
			},
			...config,
		});
		return { success: true, response: response.data };
	} catch (err: any) {
		return handleSubModuleError(err);
	}
};

export const getPaymentHistory = async (loanId: string): Promise<ApiResponse<PaymentHistoryResponse>> => {
	try {
		const response = await consumer.get<PaymentHistoryResponse>(`${paths.loans}/${loanId}/payments`);
		return { success: true, response: response.data };
	} catch (err: any) {
		return handleSubModuleError(err);
	}
};

export const setAutoPayMethod = async (
	loanId: string,
	method: PaymentMethodType,
	shouldValidate?: boolean,
): Promise<ApiResponse<undefined | AutopaySetupValidationResponse>> => {
	try {
		const url = `${paths.loans}/${loanId}`;
		if (shouldValidate) {
			const responseValidation = await consumer.get<AutopaySetupValidationResponse>(`${url}/messages`, {
				params: {
					process: 'autopay',
					paymentMethod: method.mode,
				},
				'axios-retry': {
					retries: 1,
				},
			});
			if (responseValidation.data.action !== AutopaySetupValidationAction.CONTINUE) {
				return { success: false, response: responseValidation.data };
			}
		}

		const response = await consumer.patch<undefined>(
			url,
			{
				[method.isACH ? 'bankId' : 'accountId']: method.paymentMethodId,
				paymentMethod: method.mode,
			},
			{
				'axios-retry': {
					retries: 2,
				},
			},
		);
		return { success: true, response: response.data };
	} catch (err: any) {
		return handleSubModuleError(err);
	}
};

export const getPaymentsMissed = async (
	loanId: string,
	requestConfig?: AxiosRequestConfig,
): Promise<ApiResponse<PaymentMissedResponse>> => {
	try {
		const response = await consumer.get<PaymentMissedResponse>(`${paths.loans}/${loanId}/payments?type=Missed`, {
			'axios-retry': {
				retries: 2,
			},
			...requestConfig,
		});
		return { success: true, response: response.data };
	} catch (err: any) {
		return handleSubModuleError(err);
	}
};
export const getMissedPaymentsOK = async (requestConfig?: AxiosRequestConfig): Promise<ApiResponse<PaymentMissedResponse>> => {
	try {
		const response = await consumer.get<PaymentMissedResponse>(`/me/payments?type=missed`, {
			'axios-retry': {
				retries: 2,
			},
			...requestConfig,
		});
		return { success: true, response: response.data };
	} catch (err: any) {
		return handleSubModuleError(err);
	}
};

export const getAllPaymentsMissed = async (loans: Array<Loan> | undefined): Promise<Array<PaymentMissed>> => {
	// filter the loan with missed payments
	const loansId = loans?.filter((loan) => !!loan.totalMissedPayments && isString(loan.loanId)).map((loan) => loan.loanId);

	if (!loansId || !loansId.length) {
		return [];
	}
	const results = await Promise.all(loansId.map((loanId) => getPaymentsMissed(loanId)));
	const paymentsMissed: Array<PaymentMissed> = [];

	for (const resultPaymentsMissed of results) {
		const details = resultPaymentsMissed.response?.details;
		// validate details its right
		if (Array.isArray(details) && details.length)
			for (const missedPayment of details) {
				if (!missedPayment) continue;
				if (isString(missedPayment.paymentDueDate) && isNumber(missedPayment.paymentDueAmount)) {
					paymentsMissed.push(missedPayment);
				}
			}
	}

	return paymentsMissed;
};

export const getVirtualCardTransactions = async (
	applicationId: string,
	config?: AxiosRequestConfig,
): Promise<ApiResponse<{ transactions: [TransactionTypeVirtualCard] }>> => {
	try {
		const response = await consumer.get(`${paths.loanApplications}/${applicationId}/transactions?method=virtualCard`, {
			'axios-retry': {
				retries: 2,
			},
			...config,
		});
		return { success: true, response: response.data };
	} catch (error: any) {
		return handleSubModuleError(error);
	}
};

export const getVirtualCardDetails = async (
	applicationId: string,
	config?: AxiosRequestConfig,
): Promise<ApiResponse<{ virtualCard: VirtualCardDetails }>> => {
	try {
		const response = await consumer.get(`/virtualcards/${applicationId}`, {
			'axios-retry': {
				retries: 2,
			},
			...config,
		});
		return { success: true, response: response.data };
	} catch (error: any) {
		return handleSubModuleError(error);
	}
};

export const sendCodeVirtualCard = async (
	code: string,
	applicationId: string,
	config?: AxiosRequestConfig,
): Promise<ApiResponse<ResponseSendCodeVirtualCard>> => {
	try {
		const response = await consumer.get(`${paths.loanApplications}/${applicationId}/token`, {
			headers: {
				'lp-phone-verification-code': code,
			},
			'axios-retry': {
				retries: 2,
			},
			...config,
		});
		return { success: true, response: response.data };
	} catch (error: any) {
		return handleSubModuleError(error);
	}
};

export const getCompletedLoans = async (requestConfig?: AxiosRequestConfig): Promise<ApiResponse<CompletedLoansResponse>> => {
	try {
		const response = await consumer.get<CompletedLoansResponse>(`${paths.loans}?type=completed`, requestConfig);
		return { success: true, response: response.data };
	} catch (err: any) {
		return handleSubModuleError(err);
	}
};

export const getQuickLinksByLoan = async ({
	loanId,
	lpScreenName,
	requestConfig,
}: {
	loanId: string;
	lpScreenName: QuickActionsRoutesName;
	requestConfig?: AxiosRequestConfig;
}): Promise<ApiResponse<LoanQuickLinkResponse>> => {
	try {
		const response = await consumer.get<LoanQuickLinkResponse>(`${paths.loans}/${loanId}/access-links`, {
			...requestConfig,
			headers: {
				'lp-screen-name': lpScreenName,
			},
		});
		return { success: true, response: response.data };
	} catch (err: any) {
		return handleSubModuleError(err);
	}
};

export const getUserQuickLinks = async ({
	lpScreenName,
	requestConfig,
}: {
	lpScreenName: QuickActionsRoutesName;
	requestConfig?: AxiosRequestConfig;
}): Promise<ApiResponse<LoanQuickLinkResponse>> => {
	try {
		const response = await consumer.get<LoanQuickLinkResponse>(`${paths.user}/access-links`, {
			...requestConfig,
			headers: {
				'lp-screen-name': lpScreenName,
			},
		});
		return { success: true, response: response.data };
	} catch (err: any) {
		return handleSubModuleError(err);
	}
};

export const updateLoanDetails = async (
	{
		loanId,
		loanNickname,
	}: {
		loanId: string;
		loanNickname: string;
	},
	requestConfig?: AxiosRequestConfig,
): Promise<ApiResponse<undefined>> => {
	try {
		const response = await consumer.patch<undefined>(
			`${paths.loans}/${loanId}`,
			{
				loanNickname,
			},
			requestConfig,
		);
		return { success: true, response: response.data };
	} catch (err: any) {
		return handleSubModuleError(err);
	}
};
