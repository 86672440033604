import { createReducer } from '@reduxjs/toolkit';

import { thunks } from '../actions';
import { thunks as loansThunks } from '@cp/common/loans/store/actions';

import type { RequestLoadingStatus } from '@cp/common/modules/shared/utils';
import type { User } from '../../utils';

interface ProfileState {
	userInfo: User | null;
	loading: RequestLoadingStatus;
	currentRequestId: string | undefined;
}

const initialState: ProfileState = {
	userInfo: null,
	loading: 'idle',
	currentRequestId: undefined,
};

const profileReducer = createReducer(initialState, (builder) => {
	builder.addCase(thunks.getUserInfo.pending, (state, action) => {
		if (state.loading === 'idle') {
			state.loading = 'pending';
			state.currentRequestId = action.meta.requestId;
		}
	});
	builder.addCase(thunks.getUserInfo.fulfilled, (state, action) => {
		if (action.payload.success && action.payload.response) state.userInfo = action.payload.response;
		const { requestId } = action.meta;
		if (state.loading === 'pending' && state.currentRequestId === requestId) {
			state.loading = 'idle';
			state.currentRequestId = undefined;
		}
	});
	builder.addCase(thunks.getUserInfo.rejected, (state, action) => {
		const { requestId } = action.meta;
		if (state.loading === 'pending' && state.currentRequestId === requestId) {
			state.loading = 'idle';
			state.currentRequestId = undefined;
		}
	});

	builder.addCase(loansThunks.getAll.fulfilled, (state, action) => {
		if (state && state.userInfo && action.payload && action.payload.response && action.payload.response.sellerId) {
			state.userInfo.sellerId = action.payload.response.sellerId;
		}
	});
});

export default profileReducer;

export type UserState = ReturnType<typeof profileReducer>;
