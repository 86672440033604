import { createAsyncThunk } from '@reduxjs/toolkit';

import {
	allPaymentHistoryParser,
	paymentHistoryParser,
	paymentMethodsParser,
	upcomingPaymentsParser,
} from '@cp/common/modules/payments/helpers/parser';

import type { ApiResponse } from '@cp/common/services/utils';
import type { AxiosRequestConfig } from 'axios';
import type {
	AddPaymentMethodValues,
	AllPaymentHistoryItemType,
	DeletePaymentMethodParams,
	PaymentHistoryParserType,
	UpcomingPaymentType,
	UpdatePaymentMethodNickname,
} from '../../utils';

import * as api from '../../services/payments';

type GetDataByIdOptions = { loanId: string; config?: AxiosRequestConfig };

export const getPaymentMethods = createAsyncThunk(
	'payments/getPaymentsMethods',
	async (config: AxiosRequestConfig | undefined): Promise<ApiResponse<ReturnType<typeof paymentMethodsParser>>> => {
		const { success, response, error } = await api.getPaymentMethods(config);
		if (success && response) {
			const payments = [...response.bankAccounts, ...response.debitCards].filter(Boolean);
			return { success, response: paymentMethodsParser(payments) };
		}
		return { success, error, response: undefined };
	},
);

export const addPaymentMethod = createAsyncThunk(
	'payments/addPaymentMethod',
	async (data: AddPaymentMethodValues, { dispatch }) => {
		const res = await api.addPaymentMethod(data);
		if (res.success) {
			await dispatch(getPaymentMethods());
			return res;
		}
		return res;
	},
);

export const getPaymentHistoryById = createAsyncThunk(
	'payments/getPaymentHistoryById',
	async ({ loanId, config }: GetDataByIdOptions): Promise<ApiResponse<PaymentHistoryParserType>> => {
		const result = await api.getPaymentHistoryById(loanId, config);
		return { ...result, response: result.success && result.response ? paymentHistoryParser(result.response) : undefined };
	},
);

export const getAllPaymentsHistory = createAsyncThunk(
	'payments/getAllPaymentsHistory',
	async (config?: AxiosRequestConfig): Promise<ApiResponse<AllPaymentHistoryItemType[]>> => {
		const result = await api.getAllPaymentsHistory(config);
		return { ...result, response: result.success && result.response ? allPaymentHistoryParser(result.response) : undefined };
	},
	{
		idGenerator: () => 'all',
	},
);

/**
 * refresh methods, force render
 */
export const refreshPaymentMethods = getPaymentMethods;

export const deleteSelectedPayment = createAsyncThunk(
	'payments/deletePaymentMethod',
	async ({ paymentMethodId, mode }: DeletePaymentMethodParams, { dispatch }) => {
		const res = await api.deletePayment(paymentMethodId, mode);
		if (res.success) {
			await dispatch(getPaymentMethods());
			return res;
		}
		return res;
	},
);

export const getUpcomingPaymentsById = createAsyncThunk(
	'payments/getUpcomingPaymentsById',
	async ({ loanId, config }: GetDataByIdOptions): Promise<ApiResponse<UpcomingPaymentType[]>> => {
		const result = await api.getUpcomingPaymentsById(loanId, config);
		return { ...result, response: result.success && result.response ? upcomingPaymentsParser(result.response) : undefined };
	},
);

export const getAllUpcomingPayments = createAsyncThunk(
	'payments/getAllUpcomingPayments',
	async (config?: AxiosRequestConfig): Promise<ApiResponse<UpcomingPaymentType[]>> => {
		const result = await api.getAllUpcomingPayments(config);
		return { ...result, response: result.success && result.response ? upcomingPaymentsParser(result.response) : undefined };
	},
	{
		idGenerator: () => 'all',
	},
);

export const updatePaymentMethodNickname = createAsyncThunk(
	'payments/updateNickname',
	async (options: UpdatePaymentMethodNickname) => {
		return api.updatePaymentMethodNickname(options);
	},
);
