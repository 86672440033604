import React, { ReactNode } from 'react';

import { getRemoteConfig } from '@cp/common/shared/store/actions/thunks';
import { selectIsRemoteConfigLoading, selectRemoteConfigStatus } from '@cp/common/shared/store/selectors';
import { useAppDispatch, useAppSelector } from '@cp/common/store/hooks';
import { Loading } from '@shared/components';

const RemoteConfigContainer: React.FC<ReactNode> = ({ children }) => {
	const dispatch = useAppDispatch();
	const isRemoteConfigLoading = useAppSelector(selectIsRemoteConfigLoading);
	const remoteConfigStatus = useAppSelector(selectRemoteConfigStatus);

	React.useEffect(() => {
		const fetchRemoteConfig = async () => {
			try {
				await dispatch(getRemoteConfig());
			} catch (error: any) {
				if (error.name !== 'ConditionError') {
					console.error(error);
				}
			}
		};
		if (!remoteConfigStatus) {
			fetchRemoteConfig();
		}
	}, [remoteConfigStatus]);

	if (isRemoteConfigLoading) return <Loading />;
	return <React.Fragment>{children}</React.Fragment>;
};

export default RemoteConfigContainer;
