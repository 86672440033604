import axios, { AxiosError } from 'axios';

import config from '@cp/common/config';
import { consumer } from '@cp/common/services/modules/lendingpoint/api';
import { handleSubModuleError } from '@cp/common/services/modules/lendingpoint/helpers';
import { HTTPServicesResponse } from '@cp/common/services/utils';

import { ResponseGetApplicationDetails } from '../utils';

export const apiGetApplicationDetails = async (
	appId: string,
	authToken: string | undefined,
): Promise<HTTPServicesResponse<ResponseGetApplicationDetails | AxiosError | any>> => {
	try {
		const { data } = await consumer({
			method: 'GET',
			baseURL: `${config.api.host}/consumer/v2/applications/${appId}/details`,
			headers: {
				// cancelToken: cancelToken,
				Authorization: `Bearer ${authToken}`,
			},
		});

		return { success: !!Object.keys(data).length, payload: data };
	} catch (error: any) {
		const res = error as AxiosError;

		if (axios.isCancel(error)) {
			return { success: false, errorMessage: res.message };
		}

		return handleSubModuleError(error);
	}
};
