import axios from 'axios';

// constants
import config from '@cp/common/config';
import { isWeb } from '@cp/common/platform';
import { emitCustomEvent } from '@cp/common/utils/events';

// helpers
import { getAppVersionHeader, handleRejectedHttpErrors } from '../helpers';

const {
	api: {
		host,
		paths: { manager: managerPath },
	},
} = config;

const manager = axios.create({
	baseURL: host + managerPath,
	headers: {
		'Content-Type': 'application/json',
		...getAppVersionHeader(),
	},
});

/**
 * API RESPONSE INTERCEPTOR
 */
manager.interceptors.response.use((response) => {
	if (isWeb) emitCustomEvent('httpResponse', response);
	if (response.status === 204) {
		return { ...response, data: 'Successful update' };
	}
	return response;
}, handleRejectedHttpErrors);

export default manager;
