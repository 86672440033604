/// <reference path="../../../../@types/enviroment.d.ts" />
import isFinite from 'lodash/isFinite';

import { isWeb } from '@cp/common/platform';

const autoLogoutDuration = parseInt(process.env.REACT_APP_AUTO_LOGOUT_DURATION);
const autoLogoutThrottle = parseInt(process.env.REACT_APP_AUTO_LOGOUT_THROTTLE);

const environment = process.env.REACT_APP_ENVIRONMENT_NAME;
const isTesting = process.env.NODE_ENV === 'test' || environment === 'test';
const isQA = !__DEV__ && environment === 'qa';
const isProduction = !__DEV__ && environment === 'production';

export const contactPhoneDict = {
	lendingpoint: {
		contactPhone: '+18889690959',
		contactPhone2: '+18774223545',
	},
	ebay: {
		contactPhone: '+18882210286',
		contactPhone2: '+18882210286',
		contactPhone3: '+18447797031',
	},
	multiloan: {
		contactPhone: '+18443790058',
	},
};

export const firebseWebConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_WEB_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_WEB_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_WEB_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_WEB_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_WEB_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_WEB_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_WEB_MEASUREMENT_ID,
};

const config = {
	environment,
	disableForceUpdate: process.env.REACT_APP_DISABLE_ANDROID_BIOMETRICS,
	disableAndroidBiometrics: process.env.REACT_APP_DISABLE_ANDROID_BIOMETRICS,
	disableAnalytics: isQA || __DEV__,
	disableReactotron: isTesting || isProduction || isQA || isWeb || !!process.env.REACT_APP_DISABLE_REACTOTRON,
	isTesting,
	sentry: {
		dsn: process.env.REACT_APP_SENTRY_DSN,
		release: process.env.REACT_APP_SENTRY_RELEASE_NAME,
		environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
	},
	api: {
		host: process.env.REACT_APP_API_HOST,
		apiDocs: {
			BASE_URL: process.env.REACT_APP_API_DOCS_BASE_URL,
		},
		paths: {
			utilities: '/utilities/v1',
			consumer: '/consumer/v1',
			manager: '/api-manager/v2',
			oauth: '/oauth',
			vendors: '/vendors/v1',
		},
		apiManager: {
			managerAuth: {
				clientId: process.env.REACT_APP_CLIENT_ID,
				clientSecret: process.env.REACT_APP_CLIENT_SECRET,
			},
		},
		appVersions: {
			bearerToken: process.env.REACT_APP_APP_VERSION_BEARER_TOKEN,
		},
		middlewareUrl: process.env.REACT_APP_MIDDLEWARE_URL,
	},
	clientId: process.env.REACT_APP_MIDDLEWARE_CLIENT_ID,
	lpApps: {
		host: process.env.REACT_APP_LP_APPS_HOST,
	},
	contineUrl: process.env.REACT_APP_LP_CONTINUE_URL,
	applyUrl: process.env.REACT_APP_LP_APPLY_URL,
	lpEbayUrl: process.env.REACT_APP_LP_EBAY_URL,
	contactUrl: process.env.REACT_APP_LP_CONTACT_URL,
	contactPhone: contactPhoneDict.lendingpoint.contactPhone,
	contactPhone2: contactPhoneDict.lendingpoint.contactPhone2,
	contactPhone3: contactPhoneDict.ebay.contactPhone,
	contactPhone4: contactPhoneDict.multiloan.contactPhone,
	contactPhone5: contactPhoneDict.ebay.contactPhone3,
	tcUrl: process.env.REACT_APP_LP_TERMS_URL,
	lpPrivacyPolicyUrl: process.env.REACT_APP_PRIVACY_POLICY,
	electronicCommunicationsUrl: process.env.REACT_APP_LP_ELECTRO_COMMU_URL,
	redirectUrl: process.env.REACT_APP_LP_REDIRECT_URL,
	ebayPrivateNoticeUrl: process.env.REACT_APP_PRIVATE_NOTICE_URL,
	redirectCSP: process.env.REACT_APP_REDIRECT_SERVICING_PORTAL,
	login: {
		initialValues: {
			username: process.env.REACT_APP_DEBUG_USERNAME,
			password: process.env.REACT_APP_DEBUG_PASSWORD,
		},
	},
	autoLogoutDuration:
		isFinite(autoLogoutDuration) && autoLogoutDuration >= 180
			? autoLogoutDuration * 1000 // convert seconds to ms
			: 600000, //default 10 min
	autoLogoutThrottle:
		isFinite(autoLogoutThrottle) && autoLogoutThrottle >= 7
			? autoLogoutThrottle * 1000 // convert seconds to ms
			: 10000, //default 10 min
	heapAppId: process.env.REACT_APP_HEAP_APP_ID,
	keyAuthMiddleware: process.env.REACT_APP_KEY_AUTH_MIDDLEWAY,
} as const;

export const TOTAL_KYC_TRIES = 2;
export const TOTAL_KYC_MINUTES = 4;

export default config;
