export const paths = {
	financialLife: '/posts?filter[taxonomy]=category&filter[term]=cp-app-financial-life',
	helpCenter: '/posts?filter[taxonomy]=category&filter[term]=cp-app-help-center',
	posts: '/posts',
};

export const NAME = 'WORDPRESS';
export const errorNames = {
	general: 'general err',
};
