import config from '@cp/common/config';
import { manager } from '@cp/common/lendingpoint/api';
import { refreshToken, revokeToken } from '@cp/common/lendingpoint/api/oauth';
import { paths } from '@cp/common/lendingpoint/constants';
import { handleSubModuleError } from '@cp/common/lendingpoint/helpers/errors';
import { isWeb } from '@cp/common/platform';
import middleware from '@cp/common/services/middlewares/middleware';

import type { ApiResponse } from '@cp/common/services/utils';
import type { AxiosError } from 'axios';
import type {
	ChangePasswordRequest,
	ChangePasswordResponse,
	CreateePasswordRequest,
	LoginPayload,
	LoginResponse,
	RequestOTPCodeResponseType,
	UserAuthenticationData,
} from '../utils';

import { decryptData, encryptData } from '../../dtc-apply/utils/middlewareUtils';

export const login = async (payload: LoginPayload): Promise<ApiResponse<LoginResponse>> => {
	try {
		const { key, dataEncrypted } = encryptData({
			username: payload.username,
			password: payload.password,
			grantType: 'password',
		});

		const responseEncrytion = await middleware.post(
			'/api-manager/v2/signin',
			{ data: dataEncrypted },
			{
				headers: {
					aes: key,
				},
			},
		);

		if (responseEncrytion?.data.status === 401) {
			return {
				success: false,
				status: 401,
			};
		}

		const response = decryptData(responseEncrytion.data.data, key);
		const success = !!response?.uuid;

		return { success: success, response: response };
	} catch (err) {
		const response = (err as AxiosError<LoginResponse>).response?.data;
		return Object.assign(handleSubModuleError(err as AxiosError), { response: response });
	}
};

export const redirectAuthentication = async (data: { refreshToken?: string }) => {
	if (isWeb) {
		//@ts-ignore to avoid affect in tsc mobile
		window.sessionStorage.clear();
	}
	return refreshToken(data.refreshToken);
};

export const logout = async (token: string | null | undefined) => {
	try {
		await revokeToken(token);
	} catch (error) {
		console.error('LOGOUT ERROR:', error);
	}
};

export const resetPassword = async (email: string): Promise<ApiResponse<any>> => {
	try {
		const response = await manager.post(paths.resetPassword, {
			email,
			redirectUrl: config.redirectUrl,
		});
		return { success: true, response };
	} catch (err) {
		return handleSubModuleError(err as AxiosError);
	}
};

export const changePassword = async ({
	username,
	oldPassword,
	password,
	confirmPassword,
}: ChangePasswordRequest): Promise<ApiResponse<Pick<ChangePasswordResponse, 'response'>>> => {
	try {
		const response = await manager.post<ChangePasswordResponse>(paths.changePassword, {
			username,
			oldPassword,
			password,
			confirmPassword,
		});
		return { success: true, response: response.data };
	} catch (err) {
		return handleSubModuleError(err as AxiosError);
	}
};

export const createPassword = async ({
	username,
	password,
	confirmPassword,
}: CreateePasswordRequest): Promise<ApiResponse<UserAuthenticationData>> => {
	try {
		const response = await manager.post<UserAuthenticationData>(paths.createPassword, {
			username,
			password,
			confirmPassword,
		});
		return { success: true, response: response.data };
	} catch (err) {
		return handleSubModuleError(err as AxiosError);
	}
};

export const getAuthenticationUser = async (
	emailOrPhone: string,
	token: string,
): Promise<ApiResponse<UserAuthenticationData>> => {
	try {
		const response = await manager.get<UserAuthenticationData>(`/users/${emailOrPhone}`, {
			headers: {
				Authorization: `Bearer ${token}`,
				'lp-correlation-id': undefined,
			},
		});
		return { success: true, response: response.data };
	} catch (err) {
		return handleSubModuleError(err as AxiosError);
	}
};

export const requestOTPCode = async ({
	emailId,
	phoneNumber,
}: {
	emailId: string | undefined;
	phoneNumber: string | undefined;
}): Promise<ApiResponse<RequestOTPCodeResponseType>> => {
	try {
		const response = await manager.post<RequestOTPCodeResponseType>(`/send-otp`, {
			emailId,
			phoneNumber,
		});
		return { success: true, response: response.data };
	} catch (err) {
		return handleSubModuleError(err as AxiosError);
	}
};

export const verifyOTPCode = async ({
	emailId,
	phoneNumber,
	code,
}: {
	emailId: string | undefined;
	phoneNumber: string | undefined;
	code: string;
}): Promise<ApiResponse<LoginResponse>> => {
	try {
		const response = await manager.post<LoginResponse>(`/verify-otp`, {
			emailId,
			phoneNumber,
			code,
		});
		return { success: true, response: response.data };
	} catch (err) {
		return handleSubModuleError(err as AxiosError);
	}
};
