import React from 'react';

import useHttpInterceptor from '@cp/common/shared/hooks/useHttpInterceptor';

const HttpRequestInterceptor = ({ children }: React.PropsWithChildren<{}>) => {
	useHttpInterceptor();
	return children as React.ReactElement;
};

export default HttpRequestInterceptor;
