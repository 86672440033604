import dayjs from 'dayjs';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import set from 'lodash/set';

import config from '@cp/common/config';
import Platform from '@cp/common/platform';

import type { FieldMetaProps, FormikState } from 'formik';
import type { MessageDescriptorOptions } from '../utils';

type ActionType = { [key: string]: { [key: string]: string } };

/**
 * @description Implement ICU message to keep centralize date,number and messages centralize and easy reeimplement
 * @url https://formatjs.io/docs/core-concepts/icu-syntax/
 * @param message string or AST
 */
export const defineMessage = <T = void, P = void>(message: MessageDescriptorOptions<T, P>) => message;

export const isAuthExpired = (expiresIn: Date | string | number | undefined | null) => !!expiresIn && dayjs().isAfter(expiresIn);

export const isSessionTimeout = (timestamp: number) => {
	try {
		const autoLogoutDuration = config.autoLogoutDuration;
		return (
			isNumber(autoLogoutDuration) &&
			isNumber(timestamp) &&
			timestamp > 0 &&
			autoLogoutDuration > 0 &&
			Date.now() - timestamp >= autoLogoutDuration
		);
	} catch (error) {
		return false;
	}
};

export const createActionTypes = (base: string, types: string[]) => {
	const res: ActionType = {};
	types.forEach((type) => {
		set(res, type, `${base}/${type.replace('.', '/')}`);
	});
	return res;
};

export function getInputStatus<V>(inputKey: keyof V | string, formik: Partial<FormikState<V>>) {
	if (!get(formik.touched, inputKey)) {
		return undefined;
	}

	if (get(formik.touched, inputKey) && get(formik.errors, inputKey)) {
		return 'danger';
	}
	return 'success';
}

export function getInputFieldStatus<V>(meta: FieldMetaProps<V>) {
	if (!meta.touched) {
		return undefined;
	}

	if (meta.touched && meta.error) {
		return 'danger';
	}
	return 'success';
}

export const getPhoneNumberUri = (phone: string) => Platform.select({ ios: `telprompt:${phone}`, default: `tel:${phone}` });

//@ts-ignore ignore bad type
export const allSettled: typeof Promise.allSettled = (promises: Array<Promise<unknown>>) =>
	Promise.all(
		(promises || []).map((p: Promise<unknown> | undefined) =>
			Promise.resolve(p)
				.then((value: unknown) => ({
					status: 'fulfilled',
					value,
				}))
				.catch((reason: unknown) => ({
					status: 'rejected',
					reason,
				})),
		),
	);
