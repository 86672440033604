import React, { RefObject } from 'react';
import { ErrorMessage, Field, Form, useFormikContext } from 'formik';

import { formsWeb } from '@cp/common/support/constants';
import { HelpEmailUsFormValues } from '@cp/common/support/utils';
import ButtonGeneric from '@shared/components/ButtonGeneric';

import '../styles/help.css';
import '../styles/custom-email-us.scss';
type Props = {
	formRef: RefObject<HTMLFormElement>;
};

const EmailComposerFormComponent: React.FC<Props> = ({ formRef }) => {
	const { handleSubmit, isSubmitting, handleBlur, handleChange, values, errors, touched } =
		useFormikContext<HelpEmailUsFormValues>();

	return (
		<div className="uk-modal-body lp-help">
			<div className="uk-modal-text-align-fix">
				<legend className="uk-text-lead uk-text-emphasis fix-modal-header-legend">Contact Us</legend>
			</div>
			<Form className="lp-forms" ref={formRef}>
				<div className="lp-form-field">
					<div className="uk-form-controls">
						<Field
							name="subject"
							className={`uk-input ${errors.subject && touched.subject && 'border-error-input'}`}
							placeholder=" "
							type="text"
							onBlur={handleBlur('subject')}
							onChange={handleChange('subject')}
							value={values?.subject}
						/>
						<label className="uk-form-label">{formsWeb.emailComposer.labels.subject}</label>
					</div>
					<ErrorMessage name="subject">
						{(message) => (
							<div className="lp-error">
								<p>{message}</p>
							</div>
						)}
					</ErrorMessage>{' '}
				</div>
				<div className="lp-form-field">
					<div className="uk-form-controls">
						<Field
							as="textarea"
							name="message"
							className={`uk-textarea ${errors.message && touched.message && 'border-error-input'}`}
							rows={5}
							placeholder="Message"
							onBlur={handleBlur('message')}
							onChange={handleChange('message')}
							value={values?.message}
						/>
					</div>
					<ErrorMessage name="message">
						{(message) => (
							<div className="lp-error">
								<p>{message}</p>
							</div>
						)}
					</ErrorMessage>
				</div>
				<div className="lp-form-field help-button uk-flex uk-flex-center">
					<ButtonGeneric
						text="Send"
						className="uk-button uk-button-primary fix-modal-header-buttom"
						isDisabled={isSubmitting}
						isLoading={isSubmitting}
						onClick={() => handleSubmit}
						type="submit"
					/>
					<br />
				</div>
			</Form>
		</div>
	);
};

export default EmailComposerFormComponent;
