/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
/**
 * polyfill Intl
 */
import './intl';
/**
 * @description dayjs plugins - not polyfill exactly - but must be initialized globally
 */
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

/**
 *
 * @description polyfill allSettled
 *  @see http://medium.com/@95yashsharma/polyfill-for-promise-allsettled-965f9f2a003
 */
if (!Promise.allSettled)
	//@ts-ignore polyfill Promise allSettled
	Promise.allSettled = function (promises: Array<Promise<unknown>>) {
		return Promise.all(
			promises.map((p: Promise<unknown> | undefined) => {
				if (!p) {
					console.warn('WARN: invalid promise in Promise.allSettled');
					p = Promise.resolve(p);
				}
				return p
					.then((value: unknown) => {
						return {
							status: 'fulfilled',
							value,
						};
					})
					.catch((reason: unknown) => {
						return {
							status: 'rejected',
							reason,
						};
					});
			}),
		);
	};

/**
 * String.prototype.replaceAll() polyfill
 * https://gomakethings.com/how-to-replace-a-section-of-a-string-with-another-one-with-vanilla-js/
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!String.prototype.replaceAll) {
	//@ts-ignore add replaceAll polyfill
	String.prototype.replaceAll = function (str: string, newStr: string) {
		// If a regex pattern
		if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
			return this.replace(str, newStr);
		}

		// If a string
		return this.replace(new RegExp(str, 'g'), newStr);
	};
}

if (!Array.prototype.every) {
	//@ts-ignore add every polyfill
	Array.prototype.every = function (callbackfn, thisArg) {
		'use strict';
		let T, k;

		if (this == null) {
			throw new TypeError('this is null or not defined');
		}

		// 1. Let O be the result of calling ToObject passing the this
		//    value as the argument.
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const O = Object(this);

		// 2. Let lenValue be the result of calling the Get internal method
		//    of O with the argument "length".
		// 3. Let len be ToUint32(lenValue).
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		const len = O.length >>> 0;

		// 4. If IsCallable(callbackfn) is false, throw a TypeError exception.
		if (typeof callbackfn !== 'function' && Object.prototype.toString.call(callbackfn) !== '[object Function]') {
			throw new TypeError();
		}

		// 5. If thisArg was supplied, let T be thisArg; else let T be undefined.
		if (arguments.length > 1) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			T = thisArg;
		}

		// 6. Let k be 0.
		k = 0;

		// 7. Repeat, while k < len
		while (k < len) {
			// eslint-disable-next-line no-var
			var kValue;

			// a. Let Pk be ToString(k).
			//   This is implicit for LHS operands of the in operator
			// b. Let kPresent be the result of calling the HasProperty internal
			//    method of O with argument Pk.
			//   This step can be combined with c
			// c. If kPresent is true, then
			if (k in O) {
				// eslint-disable-next-line no-var
				var testResult;
				// i. Let kValue be the result of calling the Get internal method
				//    of O with argument Pk.
				kValue = O[k];

				// ii. Let testResult be the result of calling the Call internal method
				// of callbackfn with T as the this value if T is not undefined
				// else is the result of calling callbackfn
				// and argument list containing kValue, k, and O.
				if (T) testResult = callbackfn.call(T, kValue, k, O);
				else testResult = callbackfn(kValue, k, O);

				// iii. If ToBoolean(testResult) is false, return false.
				if (!testResult) {
					return false;
				}
			}
			k++;
		}
		return true;
	};
}
