import { createAction } from '@reduxjs/toolkit';

import types from './types';
import type { EnrollAutopayFlowType, PaymentMethodType, PaymentMissedState, PaymentSummary } from '../../utils';

export const updatePaymentMethods = createAction<PaymentMethodType[]>(types.PAYMENT_METHODS.UPDATE);

export const updatePaymentSumary = createAction<Partial<PaymentSummary>>(types.SUMMARY.UPDATE);

export const resetPaymentSumary = createAction<Partial<PaymentSummary> | undefined>(types.SUMMARY.RESET);

export const setPaymentsMissed = createAction<PaymentMissedState[]>(types.MISSED.SET);

export const setPaymentMissedShownModal = createAction<boolean>(types.MISSED.SHOWN);

export const setEnrollAutopay = createAction<EnrollAutopayFlowType>(types.PAYMENT_ENROLLAUTOPAY.SET);

export const cleanEnrollAutopay = createAction(types.PAYMENT_ENROLLAUTOPAY.CLEAN);
