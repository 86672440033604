import { defineMessage } from '../../shared/helpers';
import { TagPlaceholderCallback } from '../../shared/utils';

const messagesWeb = {
	confirmPersonalInfo: {
		lastFourDigits: 'Debit Card ending with',
		forYou: 'we’re here for you',
		confirm: 'Confirm your information to see your options',
		opportunity: 'With an additional loan opportunity to help unlock all your possibilities!',
		address: 'Address',
		phone: 'Phone',
		email: 'Email',
		income: 'Income',
		incomeType: 'Income Type',
		employer: 'Employer',
		deposit: 'Funds will be deposited to',
		withdrown: 'Payments will be automatically withdrawn from',
		Call: 'Call us at',
		updateInfo: 'To update your information call us at',
	},
	offersNotFound: {
		title: 'We’re unable to offer you an additional loan at this time.',
		weAreAlways: defineMessage<{ link: TagPlaceholderCallback }>({
			id: 'multiloan.offersNotFound',
			defaultMessage:
				'As a valued customer, we want to meet your financial needs. For help or questions you may have regarding this decision, please get in touch with us at <link>contactPhone4</link>.',
		}),
		btnSubmit: 'Return to Dashboard',
	},
} as const;

export default messagesWeb;
