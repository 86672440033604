import * as React from 'react';
import { handleHttpHookError, handleHttpMissingError, handleHttpMissingPayload } from '../helpers';
import type { HTTPServicesResponse, HTTPHooksResponse, ApiResponse } from '../utils';

type useHTTPProps<Payload, Values = unknown> = {
	httpFunction: (...args: any[]) => Promise<HTTPServicesResponse<Payload> | ApiResponse<Payload> | undefined>;
	functionPayload?: Values;
	callOnComponentMount?: boolean;
	onError?: <T = any>(result: HTTPServicesResponse<T>) => any;
};

export default function useHTTP<Payload = unknown, Values = unknown>({
	httpFunction,
	functionPayload = undefined,
	callOnComponentMount = false,
	onError,
}: useHTTPProps<Payload>): HTTPHooksResponse<Payload, Values> {
	const [wasCalled, setWasCalled] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(callOnComponentMount && !wasCalled);
	const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
	const [payload, setPayload] = React.useState<Payload | null>(null);

	const callService = React.useCallback(
		async (externalFunctionPayload = functionPayload) => {
			setWasCalled(true);
			setErrorMessage(null);
			try {
				setIsLoading(true);
				const response = externalFunctionPayload ? await httpFunction(externalFunctionPayload) : await httpFunction();
				setIsLoading(false);
				if (response) {
					const { success, errorMessage: _errorMessage, statusCode } = response;
					const _payload = (response as HTTPServicesResponse<Payload>).payload || (response as ApiResponse<Payload>).response;
					if (success) {
						if (_payload) {
							setPayload(_payload);
							return { success, payload: _payload };
						}
						setErrorMessage(handleHttpMissingPayload({ ...response, httpFunction }));
						return;
					}
					setErrorMessage(_errorMessage ?? handleHttpMissingError(response));
					onError?.(response);
					return { success: false, errorMessage: _errorMessage, statusCode };
				}
			} catch (error) {
				setIsLoading(false);
				return handleHttpHookError(error);
			}
		},
		[httpFunction, functionPayload],
	);

	React.useEffect(() => {
		if (callOnComponentMount && !wasCalled) {
			void callService();
		}
	}, [callOnComponentMount, wasCalled, callService]);

	return { isLoading, errorMessage, payload, handleSubmit: callService };
}
