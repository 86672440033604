import React from 'react';
import { Link } from 'react-router-dom';

import { signOut } from '@cp/common/auth/store/actions/thunks';
import { getQuickLinksById, selectIsEmptyLoans } from '@cp/common/loans/store/selectors';
import { QuickActionsRoutesName } from '@cp/common/loans/utils';
import * as store from '@cp/common/modules/profile/store';
import shared from '@cp/common/modules/shared';
import { messages } from '@cp/common/navbar/constants';
import { Sentry } from '@cp/common/shared/services';
import { useAppDispatch, useAppSelector } from '@cp/common/store/hooks';
import icoCall from '@images/drops/ico-call.svg';
import Knowledge from '@images/drops/ico-knowcenter.svg';
import lpLogo from '@images/lendingpoint.svg';
import icoHelp from '@images/nav/help.svg';
import ListOfItemsComponent from '@navbar/components/ListOfItems.component';
import { navigation } from '@navbar/constants';
import { isMobile } from '@shared/helpers/DetectionDevice';
import EmailComposerModalScreen from '@support/screens/EmailComposer.modal';

import '@navbar/styles/fix.scss';

const NavbarScreen: React.FC = () => {
	const dispatch = useAppDispatch();
	const [isManageAutopay, setIsManageAutopay] = React.useState(false);
	const isEmptyLoans = useAppSelector(selectIsEmptyLoans);

	const userInfo = useAppSelector((state) => store.selectors.getUserInfo(state));
	const isMobileRender = isMobile.any();

	const links = useAppSelector(
		React.useCallback(
			(state) => {
				let rawLinks = getQuickLinksById(state, QuickActionsRoutesName.PAYMENTS);
				if (!Array.isArray(rawLinks)) {
					return;
				}
				return rawLinks;
			},
			[QuickActionsRoutesName.DASHBOARD],
		),
	);

	React.useEffect(() => {
		links?.forEach((link) => {
			if (link.id === 'manage_autopay') {
				setIsManageAutopay(true);
				return;
			}
		});
	}, [links]);

	const singOut = () => {
		dispatch(signOut())
			.catch((error) => {
				console.error('Something happend when you try to log out.', error);
				Sentry.captureException(error);
			})
			.finally(() => {
				Sentry.addSignOutBreadcrumb();
			});
	};

	return (
		<div
			uk-sticky="animation: uk-animation-slide-top; sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; cls-inactive: uk-navbar-transparent; top: 240"
			className="lp-navbar no-print"
		>
			<nav className="uk-navbar-container">
				<div className="uk-container uk-container-small" uk-navbar="true">
					<div className="uk-navbar-left">
						<Link to="/" className="uk-navbar-item uk-logo">
							<img src={lpLogo} alt="LendingPoint" />
						</Link>
						<h1 className="uk-text-white uk-heading-small uk-margin-remove uk-hidden@s">
							{shared.helpers.format.formatMessage(messages.navbar.greeting, {
								name: () => <strong>{`${userInfo?.firstName ? `${userInfo?.firstName}` : ''}`}</strong>,
							})}
						</h1>
					</div>
					<div className="uk-navbar-right">
						<ul className="uk-navbar-nav">
							<li className="uk-hidden@s">
								<a className="uk-navbar-item uk-navbar-icon">
									<img className="uk-icon" src={icoHelp} alt="" />
								</a>
								<div className="uk-navbar-dropdown">
									<ul className="uk-nav uk-navbar-dropdown-nav">
										<li>
											{isMobileRender ? (
												<a href="tel:+18889690959">
													<img src={icoCall} alt="" />
													<span>Call (888) 969-095</span>
												</a>
											) : (
												<a className="disable-click-link">
													<img src={icoCall} alt="" />
													<span>Call (888) 969-095</span>
												</a>
											)}
										</li>
										<li>
											<EmailComposerModalScreen />
										</li>
										<li>
											<a href={`${process.env.REACT_APP_URL_KNOWLEDGE_BROWSER}`} target="_blank">
												<img src={Knowledge} alt="" />
												<span>Knowledge Center</span>
											</a>
										</li>
									</ul>
								</div>
							</li>
							<ListOfItemsComponent
								items={navigation}
								singOut={singOut}
								isManageAutopay={isManageAutopay}
								isEmptyLoans={isEmptyLoans}
							/>
						</ul>
					</div>
				</div>
			</nav>
		</div>
	);
};

export default NavbarScreen;
