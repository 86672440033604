import { InferType } from 'yup';

import type { LoanQuickLinkTypes, LoanSources } from '../constants';

import { forms } from '../constants';

export type EditNicknameFormValues = InferType<typeof forms.editLoanNicknameScreen.schema>;

export type RefinanceOptionType = {
	savings: number;
};

export type PaymentHistoryResponse = {
	pendingTransactions: PaymentHistoryItemTypeResponse[];
	postedTransactions: PaymentHistoryItemTypeResponse[];
};

export type PaymentHistoryItemTypeResponse = {
	transactionId: string;
	transactionNumber: string;
	merchant: string;
	contractName: string;
	loanNickname: string | null;
	date: string;
	status: string;
	interest: number;
	fee: number;
	total: number;
	principal: number;
	type: string;
	postedDate: string;
	method: string;
	paymentMethodNumber: string;
	title: string;
	principalAllocation: number;
};

export type AllPaymentsHistoryResponse = {
	contractName?: string;
	loanNickname?: string;
	details: {
		contractName?: string;
		loanNickname?: string | null;
		transactionId: string;
		transactionNumber: string;
		date: string;
		interest: number;
		total: number;
		principal: number;
		type: string;
		method: string;
		fee: number;
		paymentMethodNumber: string;
		status: string;
		postedDate: string;
	}[];
};

export type PaymentHistoryItemType = {
	pendingTransactions: PaymentHistoryItemTypeResponse[];
	postedTransactions: PaymentHistoryItemTypeResponse[];
};

export type AllPaymentHistoryItemType = Pick<
	AllPaymentsHistoryResponse['details'][0],
	'contractName' | 'loanNickname' | 'principal' | 'interest' | 'fee' | 'method' | 'status'
> & {
	methodNumber: string;
	payment: number;
	paymentDate: string;
	id: string;
	lpt: string;
};

export type OfferType = {
	id: string;
	amount: number;
	fee?: number;
	payment: number;
	totalPayment: number;
	financeCharge: number;
	term: number;
	installments: number;
	apr: number;
};

export type ConsolidateOfferType = OfferType & {
	title: string;
	description: string;
	savings: number;
};

export type VirtualCardType = {
	card: string;
	status: string;
	valid: string;
	csv: string;
} & OfferType;

export type LoansResponse = {
	message: string;
	endOfDayProcessRunningFlag: boolean;
	sellerId: string | null;
	loanAccounts: Array<{
		loanNickname?: string | null;
		contractId: string;
		contractName: string;
		subSource?: string;
		disbursalAmount?: number;
		firstPaymentScheduled: boolean;
		loanPayOffAmountAsOfToday: number; // loan balance
		loanStatus: string;
		loanPaymentAmount: number;
		isACH: boolean;
		isRefinance: boolean;
		isAutopay: boolean;
		isPromoApplicable: boolean;
		payoffBalanceAmount: number;
		paymentPassedDueDate: string;
		nextDueDate: string;
		onlinePaymentFlag: boolean;
		currentLoanPaymentAmount: number;
		overDueDays: number;
		totalMissedPayments: number;
		totalMissedPaymentAmount: number;
		createdDate: string;
		source?: string | LoanSources;
		frequency: string;
		remainingTerm: number;
		hasPaymentDueDate: boolean;
		canChangePaymentDueDate: boolean;
		creditLineConsumedBalance?: number;
		creditLineAvailableBalance: number;
		creditLineRepresentative?: string;
		isCreditLineExhausted?: boolean;
		isOverDueDaysBetween21And30?: boolean;
		isOverDueDaysBetween31And90?: boolean;
		isOverDueDaysBetween1And20?: boolean;
		estimatedPaymentAmount: number;
		remaniningTermInDays: number;
	}>;
};

/**
 * @see https://lendingpoint.readme.io/reference#getloanaccountsdetails
 */
export type Loan = {
	loanId: string;
	lai: string;
	subSource?: string;
	amount: number;
	balance: number;
	payment: number;
	paymentDate: string;
	image?: string;
	isOverDue: boolean;
	hasMissedPayments: boolean;
	paymentDueDate?: string;
	creditLineConsumedBalance?: number;
	creditLineAvailableBalance?: number;
	creditLineRepresentative?: string;
	isCreditLineExhausted?: boolean;
	isOverDueDaysBetween21And30?: boolean;
	isOverDueDaysBetween31And90?: boolean;
	isOverDueDaysBetween1And20?: boolean;
	estimatedPaymentAmount: number;
	remaniningTermInDays: number;
} & Omit<LoansResponse['loanAccounts'][number], 'contractId' | 'contractName'>;

export type LoanVirtualCard = {
	loanPayOffAmountAsOfToday: number;
	loanPaymentAmount: number;
	nextDueDate: string;
	limitAmount: number;
	applicationStage: string;
	applicationStageReason: string;
	cardStatus: string;
	applicationId: string;
	approvedAmount: number;
	lastFourDigits: string;
	displayName: string;
	zipCode: string;
	cardExpiryDate: string;
};

export type TransactionTypeVirtualCard = {
	id: string;
	merchant: string;
	clearedAmount: number;
	clearedDate: string;
	authotizedAmount: number;
	authorizationDate: string;
	type: string;
	status: string;
};

export type VirtualCardDetails = {
	id: string;
	status: string;
	recipient: {
		firstName: string;
		lastName: string;
		email: string;
		locale: string;
	};
	cardImage: {
		id: string;
		contentType: string;
		urls: {
			large: string;
			medium: string;
			small: string;
		};
		textColorRGBA: string;
		hasTextShadow: boolean;
		shadowTextColorRGBA: string;
	};
	expires: number;
	validFrom: number;
	validTo: number;
	timezone: string;
	limitCents: number;
	balanceCents: number;
	spentCents: number;
	currency: string;
	last4: string;
	vcn: string;
	securityCode: string;
	numberFormat: string;
	address: {
		address1: string;
		city: string;
		province: string;
		postal: string;
		country: string;
	};
};

export type ResponseSendCodeVirtualCard = {
	jwtToken: string;
	virtualCardId: string;
};

export type LoanDetailsType = {
	paymentAmount: number;
	totalAmountToCurrent: number;
	lastPaymentDate: string;
	resubmitProcess: boolean;
	resubmitPayment: number;
	nextDueDate: string;
	overDueDays: number;
	totalMissedPaymentAmount: number;
	loanPayOffAmountAsOfToday: number;
	disbursalAmount: number;
	firstPaymentScheduled: boolean;
	subSource: string;
	isDebit: boolean;
	isAch: boolean;
	isAutopay: boolean;
	loanId: string;
	apr: number;
	interestRate: number;
	fee: number | null;
	term: number;
	currentTermInMonths: number;
	frequency: string;
	remainingTerm: number;
	depositAccountNumber?: string;
	depositBankName?: string;
	withdrawalAccountNumber?: string;
	withdrawalBankName?: string;
	yearlyPaymentAmount?: number;
	promoMinimumAmount: number;
	promoBalanceAmount: number;
	promoDurationMonths: number;
	promoExpiryDate: string;
	payoffBalanceAmount: number;
	achPaymentDue: boolean;
	transactionInProcess: boolean;
	transactionProcessAmount?: number;
	autopayBankAccount: string | null;
	autopayDebitAccount: string | null;
	autopayAccountId?: string | null;
	contractName?: string;
	creditLineConsumedBalance: number;
	creditLineAvailableBalance?: number;
	creditLineRepresentative?: string;
	isCreditLineExhausted?: boolean;
	isOverDueDaysBetween21And30?: boolean;
	isOverDueDaysBetween1And20?: boolean;
	isOverDueDaysMoreThan30?: boolean;
	estimatedPaymentAmount?: number;
	remaniningTermInDays: number;
};

export type PaymentFromApi = {
	transactionId: string;
	transactionNumber: string;
	date: string;
	interest: number;
	total: number;
	principal: number;
	type: string;
	method: string;
};

export type PaymentMissedResponse = {
	details: Array<{
		paymentDueDate: string;
		paymentDueAmount: number;
	}>;
};

export enum AutopaySetupValidationAction {
	STOP = 'stop',
	SHOW_MESSAGE = 'show_message',
	CONTINUE = 'continue',
}

export type AutopaySetupValidationResponse = {
	action: AutopaySetupValidationAction;
	message: string;
};

export type UpcomingPaymentType = {
	loanNickname: string | null;
	contractName: string;
	paymentAmount?: number;
	paymentDate?: string;
	paymentMethod?: string;
	paymentMethodId?: string;
	paymentType?: 'autopay' | 'scheduled' | 'processing' | 'pastDue';
	paymentDueDate?: string;
	paymentDueAmount?: number;
};

export type CompletedLoansResponse = {
	message: string;
	loanAccounts: CompletedLoan[];
};

export type CompletedLoan = {
	name: string;
	closedDate: string;
	totalLoanAmount: number;
	interestPaid: number;
	fixedFee: number;
	status: string;
	contractId: string;
};

export enum QuickActionsRoutesName {
	PROFILE = 'profile',
	DASHBOARD = 'dashboard',
	PAYMENTS = 'paymentCenter',
	LOAN_DETAILS = 'loanDetails',
}

export type LoanQuickLinkResponse = {
	total: number;
	links: Array<LoanQuickLink>;
};

export type LoanQuickLink = {
	id: LoanQuickLinkTypes;
	label: string;
	image: LoanQuickLinkTypes;
};
