import { parsePhoneNumber } from 'libphonenumber-js/mobile';
import * as Yup from 'yup';
export { default as messages } from './messages';
export { default as messagesWeb } from './messages.web';
import { stateValues } from '../../shared/constants';

export const NAME = 'profile';

export const formsKeys = {
	editPreferredNameScreen: {
		name: 'name',
	},
	editPaymentMethodNickname: {
		nickname: 'nickname',
	},
} as const;

export const forms = {
	profileInfo: {
		initialValues: {
			firstName: __DEV__ ? 'John' : 'John',
			lastName: __DEV__ ? 'Smith' : 'Smith',
			email: __DEV__ ? 'test@mail.com' : 'test@mail.com',
			phoneNumber: __DEV__ ? '3216549870' : '3216549870',
			street: __DEV__ ? '220 LOCUS AVE' : '220 LOCUS AVE',
			city: __DEV__ ? 'Atlanta' : 'Atlanta',
			state: __DEV__ ? 'GA' : 'GA',
			zipCode: __DEV__ ? '30318' : '30318',
			employmentType: __DEV__ ? 'Employee' : 'Employee',
			employerName: __DEV__ ? '' : '',
			annualIncome: __DEV__ ? 25000.0 : 25000.0,
		},
		labels: {
			firstName: 'First name',
			lastName: 'Last name',
			email: 'Email',
			phoneNumber: 'Phone number',
			street: 'Address',
			city: 'City',
			state: 'State',
			zipCode: 'ZIP code',
			employmentType: 'Employment type',
			employerName: "Employer's name",
			annualIncome: 'Annual income',
		},
		placeholders: {
			firstName: 'John',
			lastName: 'Smith',
			email: 'myemail@email.com',
			phoneNumber: '(999) 999-9999',
			street: '220 LOCUS AVE',
			city: 'Atlanta',
			state: 'Select...',
			zipCode: '30318',
			employmentType: '',
			employerName: '',
			annualIncome: '',
		},
		schema: Yup.object({
			firstName: Yup.string().required().ensure().trim().lowercase(),
			lastName: Yup.string().required().ensure().trim().lowercase(),
			email: Yup.string().required().ensure().trim().lowercase().email(),
			phoneNumber: Yup.string()
				.required()
				.ensure()
				.trim()
				.lowercase()
				.test('phoneNumber', 'Phone number is invalid', function (val) {
					try {
						const number = parsePhoneNumber(val, 'US');
						return number.isValid();
					} catch (e) {
						return false;
					}
				}),
			street: Yup.string().required().ensure().trim().lowercase(),
			city: Yup.string().required().ensure().trim().lowercase(),
			// TODO: validate validation logic
			state: Yup.string().required().ensure().trim().length(2).uppercase().oneOf(stateValues),
			zipCode: Yup.string().required().ensure().trim().lowercase(),
			// TODO: validate validation logic and field type
			employmentType: Yup.string().ensure().trim().lowercase(),
			// TODO: validate validation logic
			employerName: Yup.string().ensure().trim().lowercase(),
			// TODO: validate validation logic
			annualIncome: Yup.number().positive(),
		}),
	},
	editPaymentMethodNickname: {
		placeholders: {
			[formsKeys.editPaymentMethodNickname.nickname]: 'Payment Method Nickname',
		},
		schema: Yup.object({
			[formsKeys.editPaymentMethodNickname.nickname]: Yup.string().trim(),
		}),
	},
};
