import React from 'react';

import { messagesWeb } from '@cp/common/support/constants';
import IcoSuccess from '@images/badge-desk-success.svg';
import ButtonGeneric from '@shared/components/ButtonGeneric';

type Props = {
	hideModal: () => void;
};

const EmailComposerSucces: React.FC<Props> = ({ hideModal }) => {
	return (
		<div className="uk-modal-body lp-help content-modal-succes-header" uk-overflow-auto="true">
			<div className="lp-notice">
				<div className="app-notice uk-text-center content-modal-succes">
					<h1 className="uk-heading-small uk-margin-medium-bottom uk-margin-medium-top">{messagesWeb.successMessages.title}</h1>
					<img className="notice-img fix-icons" src={IcoSuccess} alt="success-ico" />
					<p className="uk-text-gray margin-top-48">{messagesWeb.successMessages.body}</p>
					<div className="notice-buttons margin-top-48">
						<ButtonGeneric text="Continue" className="uk-button uk-button-primary uk-modal-close" onClick={hideModal} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default EmailComposerSucces;
