import { createReducer } from '@reduxjs/toolkit';

import { actions } from '../actions';
import type { NavigationHistoryEntry } from '../../utils';

const initialState: NavigationHistoryEntry = {
	currentRouteName: null,
	previousRouteName: null,
};

const navigationHistoryReducer = createReducer(initialState, (builder) => {
	builder.addCase(actions.updateNavigationHistory, (state, action) => action.payload);
});

export default navigationHistoryReducer;
