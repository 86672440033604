import { defineMessage } from '../../shared/helpers';
import { TagPlaceholderCallback } from '../../shared/utils';

const messagesWeb = {
	emailUsModalTitle: 'Email us',
	callUs: defineMessage<{ link: TagPlaceholderCallback }>({
		id: 'supports.calPhone',
		defaultMessage: '<link>contactPhone</link>',
	}),
	errorMessages: {
		title: 'Your message could not be sent',
		body1: 'We encountered an error while trying to send your message.',
		body2: 'The issue may be resolved if you try again, but give us a call if the problem persists at',
	},
	successMessages: {
		title: 'Your message has been sent!',
		body: "We'll drop you a line here shortly.",
	},
};

export default messagesWeb;
