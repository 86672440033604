import dayjs from 'dayjs';

import { getCorrelationIdHeader } from '@cp/common/services/modules/lendingpoint/helpers/extraHeaders';
import base64 from '@cp/common/utils/base64';

import type { PayloadToken } from '@cp/common/services/modules/lendingpoint/utils';

import { AuthData } from '../utils';

export const getPayloadToken = (token: string) => {
	try {
		return JSON.parse(base64.decode(token.split('.')[1])) as PayloadToken;
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

export const getSafeExpireDate = (expires: number) => dayjs().add(expires, 's').subtract(60, 's').toDate().toISOString();

export const getRequestAuthorizationConfig = ({ token, correlationId }: Partial<Pick<AuthData, 'correlationId' | 'token'>>) =>
	token
		? {
				headers: {
					Authorization: `Bearer ${token}`,
					...(correlationId && getCorrelationIdHeader(correlationId)),
				},
				'axios-retry': {
					retries: 0,
				},
		  }
		: undefined;
