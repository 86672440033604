import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { getAuthData } from '@cp/common/auth/store/selectors';
import config from '@cp/common/config';
import shared from '@cp/common/modules/shared';
import { routes } from '@cp/common/navigation/constants/routes.constants';
import { useAppSelector } from '@cp/common/store/hooks';
import icoError from '@images/icons/badge-desk-error.svg';
import FooterScreen from '@navbar/screens/Footer.screen';
import NavbarScreen from '@navbar/screens/Navbar.screen';
import NavbarMobileScreen from '@navbar/screens/NavbarMobile.screen';
import { messagesWeb } from '@shared/constants';

import ButtonGeneric from './ButtonGeneric';
import NavbarNoAuthComponent from './NavbarNoAuth.component';

import '../styles/app-notice.scss';

type Props = {
	buttonText?: string;
	buttonRoute?: string;
	title?: string;
	isSessionTimeout?: boolean;
	isGoBack?: boolean;
	isNavbarsRender?: boolean;
};

const AppGeneralError: React.FC<Props> = ({
	isGoBack,
	isSessionTimeout = false,
	buttonRoute,
	buttonText = 'Try Again',
	title = 'Oops, something went wrong',
	isNavbarsRender = false,
}) => {
	const authData = useAppSelector(getAuthData);
	const authorizationToken = authData.authorizationToken;
	const history = useHistory();
	const location = useLocation<{
		errorMessage?: string;
	}>();

	const isOctp = authData.isOctp;
	const isInSessionTimeoutView = window.location.pathname === `/${routes.sessionTimeout}` && isSessionTimeout;
	const isLogged = !!authorizationToken && !isOctp;

	return (
		<>
			{!isLogged ? <NavbarNoAuthComponent /> : isNavbarsRender ? <NavbarScreen /> : null}

			<main className="uk-background-light">
				<section className="uk-section uk-section-default lp-section notice_section">
					<div className="uk-container uk-container-small" uk-height-viewport="expand: true">
						<div className="lp-notice">
							<div className="app-notice uk-text-center">
								<h1 className="uk-heading-small uk-margin-large">{title}</h1>
								<img className="notice-img fix-icons" src={icoError} alt="notice-img" />
								<p className="uk-text-gray uk-margin-medium margin-top-48">
									{location.state?.errorMessage ? (
										location.state.errorMessage
									) : (
										<>
											{isInSessionTimeoutView ? messagesWeb.sessionTimeout.body : messagesWeb.generalError.issue}{' '}
											{isInSessionTimeoutView ? null : (
												<a
													className="uk-link-heading uk-text-bold uk-text-nowrap phone_number"
													href={`tel:${shared.helpers.format.formatPhone(config.contactPhone.slice(2) /* remove +1 */)}`}
												>
													{shared.helpers.format.formatPhone(config.contactPhone.slice(2) /* remove +1 */)}
												</a>
											)}
										</>
									)}
								</p>
								<div className="notice-button margin-top-48">
									{isInSessionTimeoutView ? (
										<ButtonGeneric
											text={buttonText}
											className="uk-button uk-button-primary"
											onClick={() => history.replace(`/${buttonRoute}`)}
										/>
									) : (
										<ButtonGeneric text={buttonText} className="uk-button uk-button-primary" onClick={() => history.goBack()} />
									)}
									{isGoBack && (
										<ButtonGeneric
											text={messagesWeb.generalError.Back}
											className="uk-button uk-button-secondary"
											onClick={() => history.goBack()}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			{isNavbarsRender && <FooterScreen />}
			{isLogged && isNavbarsRender ? <NavbarMobileScreen /> : null}
		</>
	);
};

export default AppGeneralError;
