import { createReducer } from '@reduxjs/toolkit';
import { actions } from '../actions';
import type { AppUpdateInfo } from '../../utils/forceUpdate';

const initialState: AppUpdateInfo = {
	shouldAppUpdate: false,
};

const forceUpdate = createReducer(initialState, (builder) => {
	builder.addCase(actions.setForceUpdate, (state, action) => ({ ...state, ...action.payload }));
});

export default forceUpdate;
export type ForceUpdateState = ReturnType<typeof forceUpdate>;
