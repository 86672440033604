import { createSelector } from 'reselect';

import type { NavigationHistoryEntry } from '../../utils';
import type { RootState } from '@cp/common/store';

const navigationHistorySelector = (state: RootState) => state.shared.navigationHistory as NavigationHistoryEntry;

export const getNavigationHistory = navigationHistorySelector;

export const previousRouteNameSelector = createSelector(
	navigationHistorySelector,
	(navigationHistory) => navigationHistory.previousRouteName,
);
