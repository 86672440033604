import { messages } from '@cp/common/navbar/constants';
import { routes } from '@cp/common/navigation/constants';
import icoAutoPay from '@images/drops/ico-autopay.svg';
import icoCall from '@images/drops/ico-call.svg';
import icoCompleted from '@images/drops/ico-completed.svg';
import icoDocs from '@images/drops/ico-docs.svg';
import icoEmail from '@images/drops/ico-email.svg';
import icoKnowcenter from '@images/drops/ico-knowcenter.svg';
import icoLogout from '@images/drops/ico-logout.svg';
import icoPayCenter from '@images/drops/ico-paycenter.svg';
import icoPayment from '@images/drops/ico-payment.svg';
import icoProfile from '@images/drops/ico-profile.svg';
import icoSettings from '@images/drops/ico-settings.svg';

export const navigation = [
	{
		title: messages.navbar.supports.title,
		subItems: [
			{
				icon: icoCall,
				subTitle: messages.navbar.supports.subItems.call,
			},
			{
				icon: icoEmail,
				subTitle: messages.navbar.supports.subItems.email,
			},
			{
				icon: icoKnowcenter,
				subTitle: messages.navbar.supports.subItems.knowledgeCenter,
			},
		],
	},
	{
		title: messages.navbar.payments.title,
		subItems: [
			{
				icon: icoPayCenter,
				subTitle: messages.navbar.payments.subItems.paymentCenter,
				route: `${routes.payments}/${routes.paymentsCenter.initial}`,
			},
			{
				icon: icoPayment,
				subTitle: messages.navbar.payments.subItems.makeAPayment,
				route: `${routes.makePaymentRoute.initial}/${routes.makePaymentRoute.selectLoan}`,
			},
			{ icon: icoAutoPay, subTitle: messages.navbar.payments.subItems.manageAutopay },
		],
	},
	{
		title: messages.navbar.profile.title,
		subItems: [
			{
				icon: icoProfile,
				subTitle: messages.navbar.profile.subItems.personalInformation,
				route: `${routes.profile}/${routes.profileInfo}`,
			},
			{
				icon: icoSettings,
				subTitle: messages.navbar.profile.subItems.settings,
				route: `${routes.profile}/${routes.settings}`,
			},
			{ icon: icoDocs, subTitle: messages.navbar.profile.subItems.documents, route: `${routes.profile}/${routes.documents}` },
			{
				icon: icoPayment,
				subTitle: messages.navbar.profile.subItems.paymentMethods,
				route: `${routes.profile}/${routes.PaymentMethodsDetails}`,
			},
			{
				icon: icoCompleted,
				subTitle: messages.navbar.profile.subItems.completedLoans,
				route: `${routes.profile}/${routes.completedLoans}`,
			},
			{ icon: icoLogout, subTitle: messages.navbar.profile.subItems.signOut },
		],
	},
];
