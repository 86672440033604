import { createSelector } from 'reselect';
import type { SharedState } from '../reducers';

const isNetConnectedSelector = (state: SharedState['device']) => state.isNetConnected;

export const getIsNetConnected = createSelector(isNetConnectedSelector, (isNetConnected: boolean) => isNetConnected);

const biometricAuthPermissionSelector = (state: SharedState['device']) => state.biometricAuthPermission;

export const getBiometricAuthPermission = createSelector(biometricAuthPermissionSelector, (payload: boolean) => payload);
const isBiometricAuthSupportedSelector = (state: SharedState['device']) => state.isBiometricAuthSupported;

export const getIsBiometricAuthSupported = createSelector(isBiometricAuthSupportedSelector, (payload: null | boolean) => payload);
const isBioAuthPromptRejected = (state: SharedState['device']) => state.bioAuthPromptRejected;

export const getIsBioAuthPromptRejected = createSelector(isBioAuthPromptRejected, (payload: boolean) => payload);

const modalShownSelector = (state: SharedState['device']) => state.modalShown;

export const getModalShown = createSelector(modalShownSelector, (payload: boolean) => payload);
