/**
 * This axios instance is created for iodev api-utilities related services
 */

import axios from 'axios';
import axiosRetry from 'axios-retry';

import config from '../../../../config';
import { getCorrelationIdHeader, handleRejectedHttpErrors } from '../helpers';

const {
	api: {
		host,
		appVersions: { bearerToken },
		paths: { utilities: utilitiesPath },
	},
} = config;

const utilities = axios.create({
	baseURL: host + utilitiesPath,
	headers: {
		'Content-Type': 'application/json',
	},
});
/**
 * Retries request if failed, check options: https://www.npmjs.com/package/axios-retry#options
 */
axiosRetry(utilities, {
	retryDelay: axiosRetry.exponentialDelay.bind(null),
	retries: 3,
});

/**
 * API REQUEST INTERCEPTOR
 */
utilities.interceptors.request.use(
	(request) => {
		const headers = request.headers as Record<string, string>;
		if (bearerToken) {
			headers.Authorization = `Bearer ${bearerToken}`;
		}
		const correlationIdHeader = getCorrelationIdHeader();
		if (correlationIdHeader) {
			Object.assign(request.headers, correlationIdHeader);
		}
		return request;
	},
	(error) => Promise.reject(error),
);

/**
 * API RESPONSE INTERCEPTOR
 */
utilities.interceptors.response.use((response) => {
	if (response.status === 204) {
		return { ...response, data: 'Successful update' };
	}
	return response;
}, handleRejectedHttpErrors);

export default utilities;
