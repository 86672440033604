import sharedConfig from './shared.config';

const IS_MOCKS_ACTIVE = __DEV__ && 0;

const config = {
	...sharedConfig,
	api: {
		...sharedConfig.api,
		host: IS_MOCKS_ACTIVE ? process.env.REACT_APP_API_HOST_MOCK : process.env.REACT_APP_API_HOST,
		middlewareUrl: IS_MOCKS_ACTIVE ? process.env.REACT_APP_API_HOST_MOCK : process.env.REACT_APP_MIDDLEWARE_URL,
	},
	redirectUrl: process.env.REACT_APP_CUSTOMER_PORTAL_WEB,
};

export default config;
