import { createAsyncThunk } from '@reduxjs/toolkit';
import { getOffers, getTila as getTilaService } from '../../services';
import type { OffersAndOpportunityId, Tila } from '../../utils';
import type { ApiResponse } from '@cp/common/services/utils';
import { NOF } from '../../constants';

type ObjIds = {
	contactId: string;
	recentLoanId: string;
};

export const getMultiLoanOffers = createAsyncThunk(
	'multiLoan/getMultiLoanOffers',
	async (
		objIds: ObjIds,
	): Promise<{
		success: boolean;
		statusCode?: number | null;
		offerStatus?: string;
		data?: OffersAndOpportunityId;
		errorMessage?: string | null;
	}> => {
		const { payload, errorMessage, statusCode } = await getOffers(objIds.contactId, objIds.recentLoanId);

		if (payload) {
			if (Array.isArray(payload.offers) && payload.offers.length > 0 && payload.details) {
				const offerSortsDesc = payload.offers.sort((a, b) => a.loanAmount - b.loanAmount);
				return { success: true, data: { offers: offerSortsDesc, opportunityId: payload.details.applicationId } };
			} else if (payload.offerStatus === NOF) {
				return { success: false, statusCode: 201, offerStatus: payload.offerStatus, errorMessage: payload.message };
			}
		}
		return { success: false, statusCode: statusCode, errorMessage };
	},
);

export const getTila = createAsyncThunk('multiLoan/getTila', async (applicationId: string): Promise<ApiResponse<Tila>> => {
	const { success, payload, errorMessage, statusCode } = await getTilaService(applicationId);

	if (success && payload) {
		return { success: true, response: payload };
	}

	if (!success && statusCode) {
		return { success: false, errorMessage: errorMessage, statusCode };
	}

	return { success: false, statusCode, errorMessage: errorMessage ?? 'Thunk error' };
});
