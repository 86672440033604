import React from 'react';
import { useHistory } from 'react-router-dom';

import { signOut } from '@cp/common/auth/store/actions/thunks';
import * as loanStore from '@cp/common/loans/store';
import { getQuickLinksById, selectIsEmptyLoans } from '@cp/common/loans/store/selectors';
import { QuickActionsRoutesName } from '@cp/common/loans/utils';
import { routes } from '@cp/common/navigation/constants';
import { setEnrollAutopay } from '@cp/common/payments/store/actions/actions';
import { Sentry } from '@cp/common/shared/services';
import { useAppDispatch, useAppSelector } from '@cp/common/store/hooks';
import icoAutopay from '@images/drops/ico-autopay.svg';
import icoCompleted from '@images/drops/ico-completed.svg';
import icoDocs from '@images/drops/ico-docs.svg';
import icoLogout from '@images/drops/ico-logout.svg';
import icoPaymentCenter from '@images/drops/ico-paycenter.svg';
import icoPayment from '@images/drops/ico-payment.svg';
import icoProfileDrop from '@images/drops/ico-profile.svg';
import icoSettings from '@images/drops/ico-settings.svg';
import icoHome from '@images/nav/home.svg';
import icoPayments from '@images/nav/payments.svg';
import icoProfile from '@images/nav/profile.svg';

const NavbarMobileScreen: React.FC = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const pathname = window.location.pathname;
	const isSingleLoan = useAppSelector(loanStore.selectors.selectIsSingleLoan);
	const singleLoanId = useAppSelector(loanStore.selectors.selectSingleLoanId);
	const loan = useAppSelector((state) => loanStore.selectors.getLoanById(state, singleLoanId));
	const isEmptyLoans = useAppSelector(selectIsEmptyLoans);
	const [isManageAutopay, setIsManageAutopay] = React.useState(false);

	const links = useAppSelector(
		React.useCallback(
			(state) => {
				let rawLinks = getQuickLinksById(state, QuickActionsRoutesName.PAYMENTS);
				if (!Array.isArray(rawLinks)) {
					return;
				}
				return rawLinks;
			},
			[QuickActionsRoutesName.DASHBOARD],
		),
	);

	React.useEffect(() => {
		links?.forEach((link) => {
			if (link.id === 'manage_autopay') {
				setIsManageAutopay(true);
				return;
			}
		});
	}, [links]);

	const handlePressMakePayment = () => {
		isSingleLoan && loan?.loanId
			? history.push(`/${routes.makePaymentRoute.initial}/${routes.makePaymentRoute.selectPaymentAmount}`, {
					loanId: loan.loanId,
			  })
			: history.push(`/${routes.makePaymentRoute.initial}/${routes.makePaymentRoute.selectLoan}`);
	};

	const handlePressMangeAutopay = () => {
		dispatch(
			setEnrollAutopay({
				loanId: loan?.loanId as string,
				isAutopay: loan?.isAutopay as boolean,
			}),
		);
		isSingleLoan && loan?.loanId
			? history.push(
					loan.isAutopay
						? `/${routes.enrollInAutoPay.enrollInAutoPay}/${routes.enrollInAutoPay.autopaySummary}`
						: `/${routes.enrollInAutoPay.enrollInAutoPay}/${routes.enrollInAutoPay.autopaySetup}`,
					{ loanId: loan?.loanId ? loan.loanId : (singleLoanId as string), isAutopay: loan.isAutopay },
			  )
			: history.push(`/${routes.enrollInAutoPay.enrollInAutoPay}/${routes.enrollInAutoPay.autopaySelectLoan}`);
	};

	const singOut = () => {
		dispatch(signOut())
			.catch((error) => {
				console.error('Something happend when you try to log out.', error);
				Sentry.captureException(error);
			})
			.finally(() => {
				Sentry.addSignOutBreadcrumb();
			});
	};

	const closeMenuProfile = () => {
		const navProfile = document.getElementById('popup-profile')!;
		navProfile.click();
	};

	const closeMenuPayments = () => {
		const navPayments = document.getElementById('popup-payments')!;
		navPayments.click();
	};

	return (
		<div
			className="lp-mobile-nav uk-hidden@s uk-position-bottom no-print"
			style={{
				zIndex: 1000,
			}}
		>
			<nav className="uk-navbar-container" uk-navbar="true">
				<div className="uk-navbar-center">
					<ul className="uk-navbar-nav">
						<li className={pathname === '/loans' ? 'uk-active' : ''}>
							<a onClick={() => history.push('/')}>
								<img src={icoHome} alt="" />
								<span>Home</span>
							</a>
						</li>
						<li
							className={
								pathname.includes('/payments/') || pathname.includes('/enroll-in-autopay/') || pathname.includes('make-payment/')
									? 'uk-active'
									: ''
							}
						>
							<a className="uk-navbar-item dropdown-item" id="popup-payments">
								<img src={icoPayments} alt="" />
								<span>Payments</span>
							</a>
							<div
								className="uk-dropbar uk-dropbar-bottom"
								uk-drop="mode: click; stretch: x; target: !.uk-navbar-container; animation: slide-bottom; animate-out: true; offset: 0;"
							>
								<ul className="uk-nav uk-dropdown-nav">
									<li>
										<a
											onClick={() => {
												history.push('/payments/payment-center');
												closeMenuPayments();
											}}
										>
											<img src={icoPaymentCenter} alt="" />
											<span>Payment Center</span>
										</a>
									</li>
									{!isEmptyLoans && (
										<li>
											<a
												onClick={() => {
													handlePressMakePayment();
													closeMenuPayments();
												}}
											>
												<img src={icoPayment} alt="" />
												<span>Make a Payment</span>
											</a>
										</li>
									)}
									{isManageAutopay && (
										<li>
											<a
												onClick={() => {
													handlePressMangeAutopay();
													closeMenuPayments();
												}}
											>
												<img src={icoAutopay} alt="" />
												<span>Manage Autopay</span>
											</a>
										</li>
									)}
								</ul>
							</div>
						</li>
						<li className={pathname.includes('/profile/') ? 'uk-active' : ''}>
							<a className="uk-navbar-item dropdown-item" id="popup-profile">
								<img src={icoProfile} alt="" />
								<span>Profile</span>
							</a>
							<div
								className="uk-dropbar uk-dropbar-bottom"
								uk-drop="mode: click; stretch: x; target: !.uk-navbar-container; animation: slide-bottom; animate-out: true; offset: 0;"
							>
								<ul className="uk-nav uk-dropdown-nav">
									<li>
										<a
											onClick={() => {
												history.push('/profile/profile-info');
												closeMenuProfile();
											}}
										>
											<img src={icoProfileDrop} alt="" />
											<span>Personal Information</span>
										</a>
									</li>
									{/* className="uk-active" */}
									<li>
										<a
											onClick={() => {
												history.push('/profile/settings');
												closeMenuProfile();
											}}
										>
											<img src={icoSettings} alt="" />
											<span>Settings</span>
										</a>
									</li>
									<li>
										<a
											onClick={() => {
												history.push('/profile/documents');
												closeMenuProfile();
											}}
										>
											<img src={icoDocs} alt="" />
											<span>Documents</span>
										</a>
									</li>
									<li>
										<a
											onClick={() => {
												history.push('/profile/payment-method-details');
												closeMenuProfile();
											}}
										>
											<img src={icoPayment} alt="" />
											<span>Payment Methods</span>
										</a>
									</li>
									<li>
										<a
											onClick={() => {
												history.push('/profile/completed-loans');
												closeMenuProfile();
											}}
										>
											<img src={icoCompleted} alt="" />
											<span>Completed Loans</span>
										</a>
									</li>
									<li>
										<a onClick={singOut}>
											<img src={icoLogout} alt="" />
											<span>Sign Out</span>
										</a>
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</div>
			</nav>
		</div>
	);
};

export default NavbarMobileScreen;
