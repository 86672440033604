import { createReducer } from '@reduxjs/toolkit';
import { actions, thunks } from '../actions';
import type { Offer, Tila } from '../../utils';
import * as authActions from '@cp/common/modules/auth/store/actions';

interface MultiLoanState {
	offers: Offer[] | null;
	offerStatus: string | null;
	selectedOffer: Offer | null;
	opportunityId: string | null;
	tila: Tila | null;
}

const initialState: MultiLoanState = {
	offers: null,
	selectedOffer: null,
	tila: null,
	opportunityId: null,
	offerStatus: null,
};

const multiLoanReducer = createReducer(initialState, (builder) => {
	builder.addCase(thunks.getMultiLoanOffers.fulfilled, (state, action) => {
		if (action.payload.success && action.payload.data) {
			state.offers = action.payload.data.offers;
			state.opportunityId = action.payload.data.opportunityId;
		} else if (!action.payload.success && action.payload.offerStatus) {
			state.offers = null;
			state.offerStatus = action.payload.offerStatus;
		}
	});

	builder.addCase(actions.setSelectedOffer, (state, action) => {
		state.selectedOffer = action.payload;
	});

	builder.addCase(thunks.getTila.fulfilled, (state, action) => {
		if (action.payload.success && action.payload.response) {
			state.tila = action.payload.response;
		}
	});

	builder.addMatcher(
		(action) =>
			(authActions.thunks.signIn.fulfilled.match(action) && action.payload.success) ||
			authActions.thunks.signOut.pending.match(action),
		(state) => {
			if (state?.offers || state?.selectedOffer) {
				state.offers = null;
				state.selectedOffer = null;
				state.tila = null;
				state.opportunityId = null;
				state.offerStatus = null;
			}
		},
	);
});

export default multiLoanReducer;
export type MultiLoansState = ReturnType<typeof multiLoanReducer>;
