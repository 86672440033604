import React from 'react';
import { Field } from 'formik';

type Props = {
	icoInput?: string;
	type?: 'text' | 'password' | 'number';
	labelInput?: string;
	placeHolder?: string;
	name?: string;
	value?: string | number;
	isDisabled?: boolean;
	isError?: string | undefined;
	isTouched?: boolean;
	modalName?: string | undefined;
	testID?: string | undefined;
	classNameInput?: string | undefined;
	classNameContent?: string | undefined;
	classNameContentInput?: string | undefined;
};

const InputFiel: React.FC<Props> = ({
	labelInput,
	placeHolder,
	name,
	value,
	isDisabled,
	isError,
	isTouched,
	testID,
	classNameInput,
	classNameContent,
	classNameContentInput,
	type,
}) => {
	const inputClass = isError && isTouched ? 'uk-input border-error-input' : 'uk-input';
	return (
		<div className={classNameContent ? classNameContent : 'lp-form-field'}>
			<div className={classNameContentInput ? classNameContentInput : 'uk-form-controls'}>
				<Field
					className={`${classNameInput ? classNameInput : ''} ${inputClass}`}
					type={type ? type : 'text'}
					placeholder={placeHolder || ' '}
					name={name}
					value={value}
					disabled={isDisabled}
					id={testID}
				/>
				<label className="uk-form-label">{labelInput}</label>
			</div>
			{isError && isTouched && (
				<div className="lp-error">
					<p>{isError}</p>
				</div>
			)}
		</div>
	);
};

export default InputFiel;
