import React from 'react';
import { Link } from 'react-router-dom';

import LendingPoint from '@images/lendingpoint.svg';

const NavbarNoAuthComponent: React.FC = () => (
	<div
		uk-sticky="animation: uk-animation-slide-top; sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; cls-inactive: uk-navbar-transparent; top: 240"
		className="lp-navbar"
	>
		<nav className="uk-navbar-container">
			<div className="uk-container uk-container-small" uk-navbar="true">
				<div className="uk-navbar-left">
					<Link className="uk-navbar-item uk-logo" to="/">
						<img src={LendingPoint} alt="LendingPoint" />
					</Link>
				</div>
			</div>
		</nav>
	</div>
);

export default NavbarNoAuthComponent;
