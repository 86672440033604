import { ResponseAccountInfo } from '@cp/common/plaid/utils';

import type { ApiResponse } from '../../../services/utils';

import { lendingpoint } from '../../../services';
import { AddPaymentMethodResponse } from '../../payments/utils';
import { BankAccountInfo, DataSend, InfoBankAccountQuery } from '../utils';

const {
	api: { consumer, vendors },
	helpers: { handleSubModuleError },
} = lendingpoint;
export const saveBankAccountInfo = async (
	reqData: BankAccountInfo | DataSend,
	applicationId: string,
): Promise<ApiResponse<AddPaymentMethodResponse>> => {
	try {
		const path = `/applications/${applicationId}/payment-methods`;
		const { data, status } = await consumer.post(path, reqData);
		return { response: data, status, success: status === 204 };
	} catch (error: any) {
		return handleSubModuleError(error);
	}
};

export const getBankAccountInfo = async (reqData: InfoBankAccountQuery): Promise<ApiResponse<ResponseAccountInfo>> => {
	try {
		const path = '/services/bankAccountInformation/link/validate';
		const { data, status } = await vendors.post<ResponseAccountInfo>(path, reqData);
		return { response: data, status, success: !!Object.keys(data).length };
	} catch (error: any) {
		return handleSubModuleError(error);
	}
};
