import { createDraftSafeSelector } from '@reduxjs/toolkit';

import type { RootState } from '@cp/common/store';

const authSelector = (state: RootState) => state.auth;

export const getIsAuthLoading = createDraftSafeSelector(authSelector, (auth) => auth.isAuthLoading);

export const getAuthScope = createDraftSafeSelector(authSelector, (auth) => auth?.scope);

export const getUserVariants = createDraftSafeSelector(getAuthScope, (scope) =>
	scope && Array.isArray(scope.variants) ? scope.variants : [],
);

export const getCorrelationId = createDraftSafeSelector(authSelector, (auth) => auth.correlationId);
export const getApplicationId = createDraftSafeSelector(authSelector, (auth) => auth.scope?.applicationId);

export const getAuthData = createDraftSafeSelector(authSelector, (auth) => ({
	authorizationToken: auth.octpToken || auth.userToken,
	isAuthLoading: auth.isAuthLoading,
	userToken: auth.userToken,
	isSignout: auth.isSignout,
	isAutoSignout: auth.isAutoSignout,
	refreshToken: auth.refreshToken,
	correlationId: auth.correlationId,
	scope: auth.scope,
	expiresIn: auth.expiresIn,
	isOctp: !!auth.octpToken,
	isSystemGeneratedPassword: auth.isSystemGeneratedPassword,
}));
