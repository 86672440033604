/* eslint-disable indent */
import { lendingpoint } from '../../../services';
import { ConfigForLinkToken, LinkSuccessPayload, ResponseAccountInfo, ResponseLinkToken } from '@cp/common/plaid/utils';
import type { ApiResponse } from '../../../services/utils';
import type { AxiosRequestConfig } from 'axios';

const {
	api: { vendors },
	constants: { paths },
	helpers: { handleSubModuleError },
} = lendingpoint;

export const fetchLinkToken = async (
	reqData: ConfigForLinkToken,
	config?: AxiosRequestConfig,
): Promise<ApiResponse<ResponseLinkToken>> => {
	try {
		const response = await vendors.post<ResponseLinkToken>(paths.plaid_link_token_create, reqData, {
			'axios-retry': {
				retries: 3,
			},
			...config,
		});
		return { success: true, response: response.data, status: response.status };
	} catch (err: any) {
		return handleSubModuleError(err);
	}
};

export const savePlaidPublicToken = async (reqData: LinkSuccessPayload): Promise<ApiResponse<ResponseAccountInfo>> => {
	try {
		const { data, status } = await vendors.post<ResponseAccountInfo>(paths.plaid_link_sucess, reqData);
		return { response: data, status, success: !!Object.keys(data) };
	} catch (err: any) {
		return handleSubModuleError(err);
	}
};
