import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';

import { persistConfigLoans } from '@cp/common/config/persist';
import * as authActions from '@cp/common/modules/auth/store/actions';

import type { RequestLoadingStatus } from '@cp/common/modules/shared/utils';

import { thunks } from '../actions';
import { loansAdapter, loansCompletedLoansAdapter, loansDetailsAdapter } from './loans.adapters';
import { loanQuickLinkAdapter } from './quickLinks.adapter';

const loansAccountsReducer = createReducer(
	loansAdapter.getInitialState({
		loading: 'idle' as RequestLoadingStatus,
		currentRequestId: undefined as string | undefined,
		sellerId: undefined as null | undefined | string,
	}),
	(builder) => {
		builder.addCase(thunks.getAll.pending, (state, action) => {
			if (!state) return;
			if (state.loading === 'idle') {
				state.loading = 'pending';
				state.currentRequestId = action.meta.requestId;
			}
		});

		builder.addCase(thunks.getAll.fulfilled, (state, action) => {
			if (!state) return;
			const { requestId } = action.meta;

			if (action.payload && action.payload.success && action.payload.response && action.payload.response.loanAccounts) {
				loansAdapter.setAll(state, action.payload.response.loanAccounts);
				state.sellerId = action.payload.response.sellerId;
			}

			if (state.loading === 'pending' && state.currentRequestId === requestId) {
				state.loading = 'idle';
				state.currentRequestId = undefined;
			}
		});

		builder.addCase(thunks.getAll.rejected, (state, action) => {
			if (!state) return;
			const { requestId } = action.meta;
			if (state.loading === 'pending' && state.currentRequestId === requestId) {
				state.loading = 'idle';
				state.currentRequestId = undefined;
			}
		});

		builder.addCase(thunks.updateLoanDetails.fulfilled, (state, action) => {
			if (!action.payload.success && !action.payload.response) return;

			const { loanId, loanNickname } = action.meta.arg;
			if (loanId && state.ids.includes(loanId)) {
				loansAdapter.updateOne(state, {
					id: loanId,
					changes: { loanNickname: loanNickname ? loanNickname.trim() : null },
				});
			}
		});

		builder.addMatcher(
			(action) =>
				(authActions.thunks.signIn.fulfilled.match(action) && action.payload.success) ||
				authActions.thunks.signOut.pending.match(action),
			(state) => {
				if (state?.ids?.length) {
					loansAdapter.removeAll(state);
				}
			},
		);
	},
);

const loanDetailsReducer = createReducer(
	loansDetailsAdapter.getInitialState({
		loading: 'idle' as RequestLoadingStatus,
		currentRequestId: undefined as string | undefined,
		currentLoanId: null as string | null,
	}),
	(builder) => {
		builder.addCase(thunks.getDetails.pending, (state, action) => {
			if (!state) return;
			if (state.loading === 'idle') {
				state.loading = 'pending';
				state.currentRequestId = action.meta.requestId;
			}
		});

		builder.addCase(thunks.getDetails.fulfilled, (state, action) => {
			if (!state) return;
			if (action.payload.success && action.payload.response) {
				loansDetailsAdapter.upsertOne(state, action.payload.response);
				state.currentLoanId = action.meta.arg.loanId;
			}
			if (state.loading === 'pending') {
				state.loading = 'idle';
				state.currentRequestId = undefined;
			}
		});

		builder.addCase(thunks.getDetails.rejected, (state) => {
			if (state.loading === 'pending') {
				state.loading = 'idle';
				state.currentRequestId = undefined;
			}
		});

		builder.addMatcher(
			(action) =>
				(authActions.thunks.signIn.fulfilled.match(action) && action.payload.success) ||
				authActions.thunks.signOut.pending.match(action),
			(state) => {
				if (state?.ids?.length) {
					loansDetailsAdapter.removeAll(state);
				}
			},
		);
	},
);

const loansCompletedReducer = createReducer(
	loansCompletedLoansAdapter.getInitialState({
		loading: undefined as RequestLoadingStatus | undefined,
		currentRequestId: undefined as string | undefined,
	}),
	(builder) => {
		builder.addCase(thunks.getCompletedLoans.pending, (state, action) => {
			if (!state) return;
			if (state.loading === 'idle' || !state.loading) {
				state.loading = 'pending';
				state.currentRequestId = action.meta.requestId;
			}
		});

		builder.addCase(thunks.getCompletedLoans.fulfilled, (state, action) => {
			if (!state) return;
			const { requestId } = action.meta;
			if (action.payload && action.payload.success && action.payload.response) {
				loansCompletedLoansAdapter.setAll(state, action.payload.response.loanAccounts || []);
			}

			if (state.loading === 'pending' && state.currentRequestId === requestId) {
				state.loading = 'idle';
				state.currentRequestId = undefined;
			}
		});

		builder.addCase(thunks.getCompletedLoans.rejected, (state, action) => {
			if (!state) return;
			const { requestId } = action.meta;
			if (state.loading === 'pending' && state.currentRequestId === requestId) {
				state.loading = 'idle';
				state.currentRequestId = undefined;
			}
		});

		builder.addMatcher(
			(action) =>
				(authActions.thunks.signIn.fulfilled.match(action) && action.payload.success) ||
				authActions.thunks.signOut.pending.match(action),
			(state) => {
				if (state?.ids?.length) {
					loansCompletedLoansAdapter.removeAll(state);
				}
			},
		);
	},
);

const loanQuickLinksReducer = createReducer(
	loanQuickLinkAdapter.getInitialState({
		loading: undefined as RequestLoadingStatus | undefined,
		currentRequestId: undefined as string | undefined,
	}),
	(builder) => {
		builder.addCase(thunks.getQuickLinks.pending, (state, action) => {
			if (!state) return;
			if (state.loading === 'idle' || !state.loading) {
				state.loading = 'pending';
				state.currentRequestId = action.meta.requestId;
			}
		});

		builder.addCase(thunks.getQuickLinks.fulfilled, (state, action) => {
			if (!state) return;
			const { requestId } = action.meta;
			if (action.payload && action.payload.success && action.payload.response && action.payload.response.links) {
				const { loanId, lpScreenName } = action.meta.arg;
				loanQuickLinkAdapter.upsertOne(state, { id: loanId || lpScreenName, links: action.payload.response.links });
			}

			if (state.loading === 'pending' && state.currentRequestId === requestId) {
				state.loading = 'idle';
				state.currentRequestId = undefined;
			}
		});

		builder.addCase(thunks.getQuickLinks.rejected, (state, action) => {
			if (!state) return;
			const { requestId } = action.meta;
			if (state.loading === 'pending' && state.currentRequestId === requestId) {
				state.loading = 'idle';
				state.currentRequestId = undefined;
			}
		});

		builder.addMatcher(
			(action) =>
				(authActions.thunks.signIn.fulfilled.match(action) && action.payload.success) ||
				authActions.thunks.signOut.pending.match(action),
			(state) => {
				if (state?.ids?.length) {
					loanQuickLinkAdapter.removeAll(state);
				}
			},
		);
	},
);

const loansReducer = combineReducers({
	list: loansAccountsReducer,
	details: loanDetailsReducer,
	completed: loansCompletedReducer,
	quickLinks: loanQuickLinksReducer,
});

export type LoansState = ReturnType<typeof loansReducer>;

const persistLoansReducer = persistReducer<LoansState>(persistConfigLoans, loansReducer);

export default persistLoansReducer;
