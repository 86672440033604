import { AxiosResponse } from 'axios';

import { isNative } from '@cp/common/platform';

import type { HTTPServicesResponse } from '../../../services/utils';
import type { SingleUserDocumentsResponseType } from '../../profile/services/utils';

import config from '../../../config';
import { lendingpoint } from '../../../services';
import { Offer, OffersResponse, Tila } from '../utils';

const {
	api: { consumer },
	constants: { paths },
	helpers: { handleSubModuleError },
} = lendingpoint;

export const getOffers = async (contactId: string, recentLoanId: string): Promise<HTTPServicesResponse<OffersResponse>> => {
	try {
		const response = await consumer({
			method: 'POST',
			baseURL: `${config.api.host}/consumer/v1`,
			url: paths.loanApplications,
			data: {
				contactId,
				recentLoanId,
				systemSource: isNative ? 'MobileMLSlider' : 'DesktopMLSlider',
			},
		});
		const data = response.data as OffersResponse;

		return { success: true, payload: data };
	} catch (error) {
		const { success, error: responseError, errorMessage } = handleSubModuleError(error);
		const status = responseError?.response?.status;

		return {
			success,
			statusCode: status,
			errorMessage,
		};
	}
};

export const recalculateAndSelectOffer = async ({
	loanAmount,
	opportunityId,
	term,
}: {
	loanAmount: number;
	opportunityId: string;
	term: number;
}): Promise<HTTPServicesResponse<Offer>> => {
	try {
		const {
			data: { offers },
		}: AxiosResponse<{ offers: Offer[] }> = await consumer.post(`${paths.loanApplications}/${opportunityId}/offers`, {
			loanAmount,
		});

		const selectedOffer = offers.find((offer) => offer.term === term && offer.loanAmount === loanAmount);

		if (selectedOffer) {
			await consumer.patch(`${paths.loanApplications}/${opportunityId}/offers/${selectedOffer.id}`, {
				selected: true,
				source: isNative ? 'MultiLoanMobileApp' : 'MultiLoanWeb',
				offerSelectSrc: isNative ? 'MultiLoanMobileApp' : 'MultiLoanWeb',
			});
			return { success: true, payload: selectedOffer };
		}

		return {
			success: false,
		};
	} catch (err) {
		const { success, error, errorMessage } = handleSubModuleError(err);
		const status = error?.response?.status;
		return {
			success,
			errorMessage: errorMessage,
			statusCode: status,
		};
	}
};

export const getTila = async (opportunityId: string): Promise<HTTPServicesResponse<Tila>> => {
	try {
		const { data } = await consumer.get<Tila>(`${paths.applications}/${opportunityId}`);
		return { success: true, payload: data };
	} catch (err) {
		const { success, error, errorMessage } = handleSubModuleError(err);
		const status = error?.response?.status;

		return {
			success,
			errorMessage: errorMessage,
			statusCode: status,
		};
	}
};

export const getContract = async (opportunityId: string): Promise<HTTPServicesResponse<SingleUserDocumentsResponseType>> => {
	try {
		const { data } = await consumer.get<SingleUserDocumentsResponseType>(`${paths.applications}/${opportunityId}/loan-agreement`);
		return { success: true, payload: data };
	} catch (err) {
		const { success, error, errorMessage } = handleSubModuleError(err);
		const status = error?.response?.status;

		return {
			success,
			errorMessage: errorMessage,
			statusCode: status,
		};
	}
};

export const signAgreement = async ({
	opportunityId,
	signatureFileName,
	signatureFileContent,
}: {
	opportunityId: string;
	signatureFileName: string;
	signatureFileContent: string;
}): Promise<HTTPServicesResponse<any>> => {
	try {
		const { data } = await consumer.patch<{}>(`${paths.applications}/${opportunityId}`, {
			signatureFileName,
			signatureFileContent,
		});
		return { success: true, payload: data };
	} catch (err) {
		const { success, error, errorMessage } = handleSubModuleError(err);
		const status = error?.response?.status;

		return {
			success,
			errorMessage: errorMessage,
			statusCode: status,
		};
	}
};
