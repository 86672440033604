import type { DocumentType } from '../utils';
import type { AllUserDocumentsResponseType } from '../services/utils';

export const documentsResponseParser = (response: AllUserDocumentsResponseType): DocumentType[] =>
	response.documents.map(({ id, name, date, contractName, loanNickname }) => ({
		id,
		date,
		name,
		contractName,
		loanNickname,
	}));
