import React from 'react';

export const useOutside = (ref: any, handleOnClick: () => void) => {
	const handleClickOutside = (event: any) => {
		if (ref.current && !ref.current.contains(event.target)) {
			handleOnClick();
		}
	};

	React.useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);

	return { handleClickOutside };
};
