import * as Sentry from '@sentry/react';
import { fetchAndActivate, getAll } from 'firebase/remote-config';

import { AppRemoteConfig, remoteConfig, RemoteConfigKeys } from '../../utils/remoteConfig';

export const fetchRemoteConfig = async () => {
	if (!remoteConfig) return { success: false };
	try {
		await fetchAndActivate(remoteConfig);
		const parameters = getAll(remoteConfig);
		const config = {
			[RemoteConfigKeys.APPTENTIVE_ENABLED]: parameters[RemoteConfigKeys.APPTENTIVE_ENABLED].asBoolean(),
			[RemoteConfigKeys.FICO_ENABLED]: parameters[RemoteConfigKeys.FICO_ENABLED].asBoolean(),
			[RemoteConfigKeys.CHATBOT_ENABLED]: parameters[RemoteConfigKeys.CHATBOT_ENABLED].asBoolean(),
			[RemoteConfigKeys.KNOWLEDGE_CENTER_ENABLED]: parameters[RemoteConfigKeys.KNOWLEDGE_CENTER_ENABLED].asBoolean(),
			[RemoteConfigKeys.MULTI_SWIPE_ENABLED]: parameters[RemoteConfigKeys.MULTI_SWIPE_ENABLED].asBoolean(),
			[RemoteConfigKeys.PAYMENT_DUE_DATE_ENABLED]: parameters[RemoteConfigKeys.PAYMENT_DUE_DATE_ENABLED].asBoolean(),
			[RemoteConfigKeys.CDP_ENABLED]: parameters[RemoteConfigKeys.CDP_ENABLED].asBoolean(),
			[RemoteConfigKeys.NOTIFICATION_CENTER_ENABLED]: parameters[RemoteConfigKeys.NOTIFICATION_CENTER_ENABLED].asBoolean(),
			[RemoteConfigKeys.PFM]: parameters[RemoteConfigKeys.PFM].asString(),
			[RemoteConfigKeys.DTC_APPLY_ENABLED]: parameters[RemoteConfigKeys.DTC_APPLY_ENABLED].asBoolean(),
			[RemoteConfigKeys.DTC_APPLY_ENABLED_FOR_CSP]: parameters[RemoteConfigKeys.DTC_APPLY_ENABLED_FOR_CSP].asBoolean(),
			[RemoteConfigKeys.PASSWORDLESS_ENABLED]: parameters[RemoteConfigKeys.PASSWORDLESS_ENABLED].asBoolean(),
			[RemoteConfigKeys.PASSWORDLESS_ENABLE_FOR_CSP]: parameters[RemoteConfigKeys.PASSWORDLESS_ENABLE_FOR_CSP].asBoolean(),
			[RemoteConfigKeys.SUBSCRIPTIONS_ENABLED]: parameters[RemoteConfigKeys.SUBSCRIPTIONS_ENABLED].asBoolean(),
			[RemoteConfigKeys.IS_IN_MAINTENANCE_FOR_CSP]: parameters[RemoteConfigKeys.IS_IN_MAINTENANCE_FOR_CSP].asString(),
			[RemoteConfigKeys.ALERT_INFO_BANNER_FOR_CSP]: parameters[RemoteConfigKeys.ALERT_INFO_BANNER_FOR_CSP].asString(),
			[RemoteConfigKeys.BYPASS_FOR_MAINTENANCE_FOR_CSP]: parameters[RemoteConfigKeys.BYPASS_FOR_MAINTENANCE_FOR_CSP].asString(),
			[RemoteConfigKeys.SHOW_LOCKED_USER_SCREEN_FOR_CSP]:
				parameters[RemoteConfigKeys.SHOW_LOCKED_USER_SCREEN_FOR_CSP].asBoolean(),
			[RemoteConfigKeys.REFINANCE_BANNER_ENABLED_FOR_CSP]:
				parameters[RemoteConfigKeys.REFINANCE_BANNER_ENABLED_FOR_CSP].asBoolean(),
		} as AppRemoteConfig;
		return {
			success: true,
			response: {
				config,
			},
		};
	} catch (error) {
		console.error(error);
		Sentry.captureException(error);
		return { success: false as const, error };
	}
};
