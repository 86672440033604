import React from 'react';

import { defineMessage } from '../../shared/helpers';
import { TagPlaceholderCallback } from '../../shared/utils';

const messagesWeb = {
	paymentCanceled: {
		payment: 'Your payment has been canceled',
		scheduledPayment: 'Your scheduled payment of ',
		canceled: 'has been canceled.',
		nextPayment: 'Next Payment Due Date',
		paymentCenter: 'Back to Payment Center',
	},
	enrollPaymentAutopay: {
		title: 'Set Autopay Method',
		description: 'Select an account. This will be where your payments are automatically drafted.',
		buttonText: 'Continue',
		addPaymentMethod: 'Add Payment Method',
		selectAccountAlert: 'Select an account to continue.',
	},
	enrollPaymentSummary: {
		title: 'Autopay Summary',
		paymentAmount: 'Payment Amount',
		paymentDate: 'Payment Date',
		paymentMethod: 'Payment Method',
		byClicingBelow: defineMessage<{
			link: TagPlaceholderCallback;
		}>({
			id: 'enrollPaymentSummary.byClicingBelow',
			defaultMessage: `By clicking below to make your scheduled payments, you authorize LendingPoint to electronically debit your account in accordance with the {link} for the amount owing on each payment date scheduled in your loan agreement or as changed thereafter ("Due Date").`,
		}),
		terms: defineMessage<{
			link: TagPlaceholderCallback;
		}>({
			id: 'enrollPaymentSummary.terms',
			defaultMessage: `Yes, I have read the {link} specified and I agree to authorize this recurring transaction.`,
		}),
	},
	enrollPaymentStop: {
		title: `Your Autopay enrollment couldn't be completed`,
		description: defineMessage<{
			br: React.ReactText | React.ReactElement;
		}>({
			id: 'enrollPaymentStop.description',
			defaultMessage: `Autopay cannot be enabled until current payments are up-to-date. {br} Please make a payment before enabling Autopay.`,
		}),
	},
	enrollPaymentSuccess: {
		title: 'Cross that one off your list',
		description: 'You are now enrolled in Autopay.',
		backToPaymentCenter: 'Back to Payment Center',
	},
	paymentCenter: {
		title: 'Payment Center',
		upcomingPayments: {
			title: 'Upcoming Payments',
			makeAPayment: 'Make A Payment',
			makeAnExtraPayment: 'Make Extra Payment',
			noCompletedAutopay: 'You have completed this cycle’s autopay.',
			noScheduledPayments: 'You don’t have any scheduled payments.',
			nextPaymentScheduled: `Your next autopay is scheduled for\n`,
			nextPaymentDue: `Your next payment is due\n`,
			loan: 'Loan',
			paymentMethod: 'Payment Method',
			paymentDate: 'Payment Date',
			paymentAmount: 'Payment Amount',
		},
		upcomingPaymentCancel: {
			titileFailed: "Your payment couldn't be canceled",
			weEncounteredFailed: 'We encountered an error while trying to cancel your scheduled payment.',
			hintFailed: 'The issue may be resolved if you try again, but give us a call if the problem persists at',
			tryAgain: 'Try Again',
			backToPaymentCenter: 'Back to Payment Center',
		},
	},
	paymentHistory: {
		title: 'Transaction History',
		dontSeeAPayment: "Don't see a payment?",
		dontSeeAPaymentHint: `Payments made with a bank account are applied the same day but drafted the next business day. It may take up to 24
		hours to be viewable in your payment history.`,
	},
	paymentDetails: {
		title: 'Payment Details',
	},
	makePayment: {
		selectAmountScreen: {
			title: 'Select Payment Amount',
			typeAmount: {
				minimumAmount: 'Minimum Amount',
				pastDuePayments: 'Past Due Payments',
				otherAmount: 'Other Amount',
			},
			textContinue: 'Enter a payment amount to continue.',
			buttonContinue: 'Continue',
		},
		selecDateScreen: {
			title: 'Select Payment Date',
		},
		selectMethodScreen: {
			title: 'Select Payment Method',
			alertDescription: 'Select a payment method to continue.',
		},
		paymentSummaryScreen: {
			title: 'Payment Summary',
			paymentAmount: 'Payment Amount',
			paymentDate: 'Payment Date',
			paymentMethod: 'Payment Method',
			titleExtraPayment: 'How would you like this payment applied?',
			titleHeaderExtraPayment: 'Extra Loan Payment',
			alertExtraPayment: 'Select a payment option to continue.',
		},
		paymentMethosEmpty: {
			title: "You don't have any saved payment methods",
			textAddPaymentMethod: 'Add a new payment method to continue.',
		},
		errorScreen: {
			callUs: defineMessage<{ link: TagPlaceholderCallback }>({
				id: 'supports.calPhone',
				defaultMessage: '<link>contactPhone</link>',
			}),
		},
	},
} as const;

export default messagesWeb;
