import { initializeApp } from 'firebase/app';
import { getRemoteConfig, RemoteConfig } from 'firebase/remote-config';

import { firebseWebConfig } from '@cp/common/config/shared.config';

export enum RemoteConfigKeys {
	FICO_ENABLED = 'lp_fico_enabled',
	APPTENTIVE_ENABLED = 'lp_apptentive_enabled',
	CHATBOT_ENABLED = 'lp_chatbot_enabled',
	KNOWLEDGE_CENTER_ENABLED = 'lp_knowledge_center_enabled',
	MULTI_SWIPE_ENABLED = 'lp_multi_swipe_enabled',
	PAYMENT_DUE_DATE_ENABLED = 'lp_payment_due_date_enabled',
	CDP_ENABLED = 'lp_cdp_enabled',
	NOTIFICATION_CENTER_ENABLED = 'lp_notification_center_enabled',
	PFM = 'lp_pfm',
	DTC_APPLY_ENABLED = 'is_dtc_apply_enabled',
	DTC_APPLY_ENABLED_FOR_CSP = 'is_dtc_apply_enabled_for_csp',
	PASSWORDLESS_ENABLED = 'lp_passwordless_enabled',
	PASSWORDLESS_ENABLE_FOR_CSP = 'lp_passwordless_enabled_for_csp',
	SUBSCRIPTIONS_ENABLED = 'is_subscriptions_enabled',
	IS_IN_MAINTENANCE_FOR_CSP = 'is_in_maintenance_for_csp',
	ALERT_INFO_BANNER_FOR_CSP = 'alert_info_banner_for_csp',
	BYPASS_FOR_MAINTENANCE_FOR_CSP = 'bypass_for_maintenance_credential_for_csp',
	SHOW_LOCKED_USER_SCREEN_FOR_CSP = 'show_locked_user_screen_for_csp',
	REFINANCE_BANNER_ENABLED_FOR_CSP = 'is_refinance_banner_enabled_for_csp',
}

export const remoteConfigPFMDefaultState = {
	linked_accounts_enabled: false,
	transactions_enabled: false,
	spending_enabled: false,
	income_enabled: false,
	debts_enabled: false,
	market_enabled: false,
	insights_cards_enabled: false,
};

export const remoteConfigDefault = {
	[RemoteConfigKeys.APPTENTIVE_ENABLED]: true,
	[RemoteConfigKeys.FICO_ENABLED]: true,
	[RemoteConfigKeys.CHATBOT_ENABLED]: true,
	[RemoteConfigKeys.KNOWLEDGE_CENTER_ENABLED]: true,
	[RemoteConfigKeys.MULTI_SWIPE_ENABLED]: false,
	[RemoteConfigKeys.PAYMENT_DUE_DATE_ENABLED]: false,
	[RemoteConfigKeys.CDP_ENABLED]: false,
	[RemoteConfigKeys.NOTIFICATION_CENTER_ENABLED]: false,
	[RemoteConfigKeys.PFM]: JSON.stringify(remoteConfigPFMDefaultState),
	[RemoteConfigKeys.DTC_APPLY_ENABLED]: false,
	[RemoteConfigKeys.DTC_APPLY_ENABLED_FOR_CSP]: false,
	[RemoteConfigKeys.PASSWORDLESS_ENABLED]: false,
	[RemoteConfigKeys.PASSWORDLESS_ENABLE_FOR_CSP]: false,
	[RemoteConfigKeys.SUBSCRIPTIONS_ENABLED]: false,
	[RemoteConfigKeys.IS_IN_MAINTENANCE_FOR_CSP]: JSON.stringify({
		isActive: false,
		contentHTML: '',
		title: '',
		imageURL: '',
	}),
	[RemoteConfigKeys.BYPASS_FOR_MAINTENANCE_FOR_CSP]: '',
	[RemoteConfigKeys.ALERT_INFO_BANNER_FOR_CSP]: '',
	[RemoteConfigKeys.SHOW_LOCKED_USER_SCREEN_FOR_CSP]: false,
	[RemoteConfigKeys.REFINANCE_BANNER_ENABLED_FOR_CSP]: false,
};

export type AppRemoteConfig = typeof remoteConfigDefault;

const firebaseConfig: any = firebseWebConfig;
let missingFirebaseConfig = false;

Object.keys(firebaseConfig).forEach((key: any) => {
	if (!firebaseConfig[key]) missingFirebaseConfig = true;
});

if (missingFirebaseConfig) console.warn('Missing firebseWebConfig config');

let remoteConfig: RemoteConfig | undefined;

if (!missingFirebaseConfig) {
	// Initialize Firebase
	initializeApp(firebaseConfig);
	remoteConfig = getRemoteConfig();
	remoteConfig.settings.minimumFetchIntervalMillis = 15000;
	remoteConfig.defaultConfig = remoteConfigDefault;
}

export { remoteConfig };
