import axios, { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';
import isString from 'lodash/isString';

import config from '@cp/common/config';

import type { ApiResponse } from '@cp/common/services/utils';
import type { AuthUserTokenResponse } from '../utils';

import { httpErrorLogger } from '../../../middlewares';
import * as constants from '../constants';
import { handleRejectedHttpErrors, handleSubModuleError } from '../helpers/errors';
import { getAppVersionHeader } from '../helpers/extraHeaders';

const { NAME, errorNames, paths } = constants;

const {
	api: {
		host,
		paths: { manager: managerPath, oauth: oauthPath },
	},
} = config;

const oauth = axios.create({
	baseURL: `${host}${managerPath}${oauthPath}`,
	headers: {
		'Content-Type': 'application/json',
		...getAppVersionHeader(),
	},
});

axiosRetry(oauth, { retries: 1 });

export const refreshToken = async (token: string | undefined): Promise<ApiResponse<AuthUserTokenResponse>> => {
	try {
		const response = await oauth.post<AuthUserTokenResponse>(paths.token, {
			grantType: 'refresh_token',
			refreshToken: token,
		});
		return { success: true, response: response.data };
	} catch (error) {
		httpErrorLogger(NAME, errorNames.refreshToken, error as AxiosError);
		return handleSubModuleError(error as AxiosError);
	}
};

export const revokeToken = async (token: string | null | undefined): Promise<ApiResponse<any> | undefined> => {
	if (!isString(token) || !token) return Promise.resolve(undefined);
	try {
		const response = await oauth.post(
			paths.revokeToken,
			{
				token: token,
			},
			{
				validateStatus: (status) => status < 500,
			},
		);
		return { success: true, response };
	} catch (err) {
		return handleSubModuleError(err as AxiosError);
	}
};

oauth.interceptors.response.use(undefined, handleRejectedHttpErrors);

export default oauth;
