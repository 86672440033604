export const NAME = 'LENDINGPOINT';

export const paths = {
	user: '/me',
	documents: '/me/documents',
	login: '/signin',
	loans: '/me/loans',
	mails: '/me/mails',
	payments: '/me/payments',
	resetPassword: '/accesses/send_reset_password_email',
	changePassword: '/users/change_password',
	createPassword: '/users/create_password',
	paymentMethods: '/me/payment-methods',
	loanApplications: '/me/loan-applications',
	offersCatalog: '/offers/catalog',
	applications: '/applications',
	token: '/token',
	revokeToken: '/revoke',
	verifyToken: '/access/verifyToken',
	octp: '/octp',
	plaid_link_token_create: '/services/bankAccountInformation/link/token/create',
	plaid_link_sucess: '/services/bankAccountInformation/link/success',
} as const;

// errors handled by api
export const errorNames = {
	unauthorized: 'unauthorized',
	general: 'general',
	refreshToken: 'refreshToken',
	// for errors handled by module services
	unexpected: 'subModule',
};
