const messagesWeb = {
	personalInfo: {
		title: 'Personal Information',
		profile: 'Profile',
		InfoCall: 'If this information is inaccurate, please give us a call at:',
		information: 'Information & Settings',
		street: 'Street Address',
		apartment: 'Apartment, Suite, Building, etc.',
		city: 'City',
		Street: 'Street Address',
		state: 'State',
		Code: 'ZIP Code',
	},
	completedLoans: {
		title: 'Completed Loans',
		loan: 'Loan',
		amount: 'Total Loan Amount',
		closeDate: 'Account Close Date',
		Interest: 'Interest Paid',
		status: 'Account Status',
	},
	settings: {
		title: 'Settings',
		Security: 'Security Settings',
		changePassword: 'Change your account password',
		createPassword: 'Create a new password',
		securityReason: 'For security reasons, change your password periodically.',
		createPasswordSecurityReason: 'You don’t need a password to sign in, but you can set up one here, if you’d like.',
		include: 'Please include:',
		minimum: 'A minimum of 8 characters',
		number: 'At least 1 number',
		uppercase: 'At least 1 uppercase letter',
		lowercase: 'At least 1 lowercase letter',
		character: 'At least 1 special character',
		appSettings: 'App Settings',
		Score: 'Credit Score',
		lendingPointMobile: 'View, track, and monitor in the LendingPoint mobile app',
		old: 'Old Password',
		new: 'New Password',
		confirm: 'Confirm New Password',
	},
} as const;

export default messagesWeb;
