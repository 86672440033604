import { PaymentHistoryResponse } from '../../loans/utils';
import { PaymentMethodNameKeys, PaymentMethodTypeKeys } from '../constants';
import {
	AllPaymentHistoryItemType,
	AllPaymentsHistoryResponse,
	PaymentHistoryItemTypeResponse,
	PaymentHistoryParserType,
	PaymentMethodType,
	UpcomingPaymentsResponse,
} from '../utils';

export const paymentMethodsParser = (methods: PaymentMethodType[]) =>
	methods.map((entry) => ({
		...entry,
		isACH: !!entry.bankId,
		paymentMethodId: entry.bankId ? entry.bankId : entry.accountId,
		mode: entry.bankId ? PaymentMethodTypeKeys.BANK_ACCOUNT : PaymentMethodTypeKeys.DEBIT_CARD,
		methodName: entry.bankId ? PaymentMethodNameKeys.BANK_ACCOUNT : PaymentMethodNameKeys.DEBIT_CARD,
	}));

export const paymentHistoryParser = (response: PaymentHistoryResponse): PaymentHistoryParserType => ({
	pendingTransactions: Array.isArray(response.pendingTransactions)
		? response?.pendingTransactions.map((payment: PaymentHistoryItemTypeResponse) => ({
				contractName: payment.contractName,
				loanNickname: payment.loanNickname,
				principal: payment.principal,
				interest: payment.interest,
				fee: payment.fee,
				payment: payment.total,
				methodNumber: payment.paymentMethodNumber,
				method: payment.method,
				paymentDate: payment.date,
				lpt: payment.transactionNumber,
				id: payment.transactionId,
				status: payment.status,
				type: payment.type,
				postedDate: payment.postedDate,
				title: payment.title,
		  }))
		: [],
	postedTransactions: Array.isArray(response.postedTransactions)
		? response?.postedTransactions.map((payment: PaymentHistoryItemTypeResponse) => ({
				contractName: payment.contractName,
				loanNickname: payment.loanNickname,
				principal: payment.principal,
				interest: payment.interest,
				fee: payment.fee,
				payment: payment.total,
				methodNumber: payment.paymentMethodNumber,
				method: payment.method,
				paymentDate: payment.date,
				lpt: payment.transactionNumber,
				id: payment.transactionId,
				status: payment.status,
				type: payment.type,
				postedDate: payment.postedDate,
				title: payment.title,
		  }))
		: [],
});

export const allPaymentHistoryParser = (response: AllPaymentsHistoryResponse): AllPaymentHistoryItemType[] =>
	response.details.map((payment) => ({
		contractName: response.contractName || payment.contractName,
		loanNickname: response.loanNickname || payment.loanNickname,
		principal: payment.principal,
		interest: payment.interest,
		fee: payment.fee,
		payment: payment.total,
		methodNumber: payment.paymentMethodNumber,
		method: payment.method,
		paymentDate: payment.date,
		lpt: payment.transactionNumber,
		id: payment.transactionId,
		status: payment.status,
		type: payment.type,
		postedDate: payment.postedDate,
	}));

export const upcomingPaymentsParser = (response: UpcomingPaymentsResponse) =>
	(response.details || response.payments || []).sort((a, b) =>
		b.paymentDate && a.paymentDate ? new Date(a.paymentDate).getTime() - new Date(b.paymentDate).getTime() : 0,
	);
