/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import hasIn from 'lodash/hasIn';

import { HTTPServicesResponse } from '../utils';
import { httpErrorLogger } from '../middlewares';
import * as Sentry from '@cp/common/shared/services/Sentry';

import type { AxiosError } from 'axios';

export const getErrorMessage = (error: AxiosError): string => {
	const defaultErrMsg = "Something didn't go right but let's try again.";
	try {
		let errors = error.response?.data?.errors;
		//TODO: Handle more 1 error messages
		if (Array.isArray(errors)) errors = errors[0];

		if (errors?.code === 'INTERNAL_SERVER_ERROR') {
			return defaultErrMsg;
		}

		// Check if status code is 409
		if ((!errors || (isPlainObject(errors) && !errors.detail)) && error.response?.data?.message) {
			errors = { detail: error.response.data.message };
		}

		let errorMessage = errors?.detail;

		// safe error message
		if (!isString(errorMessage)) {
			if (Array.isArray(errorMessage)) {
				errorMessage = errorMessage[0];
			}
			if (isPlainObject(errorMessage) && hasIn(errorMessage, 'message')) {
				errorMessage = errorMessage.message;
			} else {
				errorMessage = defaultErrMsg;
			}
		}

		// safe error string mesasge
		if (!isString(errorMessage)) {
			errorMessage = defaultErrMsg;
			Sentry.captureException(new Error('Unhandle error format'), (scope) => {
				scope.setTag('errorType', 'unhandle-error-format');
				if (error) scope.setExtras(error?.toJSON?.() as Record<string, unknown>);
				scope.setExtra('errors', errors);
				return scope;
			});
		}

		return errorMessage as string;
	} catch (error) {
		Sentry.captureException(error, (scope) => {
			scope.setTag('errorType', 'unhandle-error-format');
			return scope;
		});
		return defaultErrMsg;
	}
};

export const handleHttpMissingPayload = (response: any): string => {
	httpErrorLogger('HTTP', 'MissingPayload', response);
	return 'Missing Payload';
};

export const handleHttpMissingError = (response: any): string => {
	httpErrorLogger('HTTP', 'MissingError', response);
	return 'Missing Error Message';
};

export const handleHttpHookError = (error: AxiosError): HTTPServicesResponse<null> => {
	httpErrorLogger('HTTP', 'hook', error);
	return { success: false, errorMessage: getErrorMessage(error) };
};
