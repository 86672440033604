import { createDraftSafeSelector, createSelector } from '@reduxjs/toolkit';

import { isWeb } from '@cp/common/platform';

import type { RootState } from '@cp/common/store';

import { RemoteConfigKeys, remoteConfigPFMDefaultState } from '../../utils/remoteConfig';

const remoteConfigSelector = (state: RootState) => state.shared.remoteConfig;

export const selectRemoteConfig = createDraftSafeSelector(remoteConfigSelector, (state) => state.config);
export const selectRemoteConfigStatus = createDraftSafeSelector(remoteConfigSelector, (state) => state.status);

export const selectIsFicoEnabled = createSelector(
	selectRemoteConfig,
	(remoteConfig) => remoteConfig[RemoteConfigKeys.FICO_ENABLED],
);

export const selectIsApptentiveEnabled = createSelector(
	selectRemoteConfig,
	(remoteConfig) => remoteConfig[RemoteConfigKeys.APPTENTIVE_ENABLED],
);

export const selectIsRemoteConfigLoading = createDraftSafeSelector(
	remoteConfigSelector,
	(state) => !state.status || state.status === 'pending',
);

export const selectIsChatbotEnabled = createSelector(
	selectRemoteConfig,
	(remoteConfig) => remoteConfig[RemoteConfigKeys.CHATBOT_ENABLED],
);

export const selectIsKnowledgeCenterEnabled = createSelector(
	selectRemoteConfig,
	(remoteConfig) => remoteConfig[RemoteConfigKeys.KNOWLEDGE_CENTER_ENABLED],
);

export const selectIsMultiSwipeEnabled = createSelector(
	selectRemoteConfig,
	(remoteConfig) => remoteConfig[RemoteConfigKeys.MULTI_SWIPE_ENABLED],
);

export const selectIsPaymentDueDateEnabled = createSelector(
	selectRemoteConfig,
	(remoteConfig) => remoteConfig[RemoteConfigKeys.PAYMENT_DUE_DATE_ENABLED],
);

export const selectIsCDPEnabled = createSelector(
	selectRemoteConfig,
	(remoteConfig) => remoteConfig[RemoteConfigKeys.CDP_ENABLED],
);

export const selectIsNotificationCenterEnabled = createSelector(
	selectRemoteConfig,
	(remoteConfig) => remoteConfig[RemoteConfigKeys.NOTIFICATION_CENTER_ENABLED],
);

export const selectPfmRemoteConfig = createSelector(selectRemoteConfig, (remoteConfig): typeof remoteConfigPFMDefaultState =>
	JSON.parse(remoteConfig[RemoteConfigKeys.PFM]),
);

export const selectIsDtcApplyEnabled = createSelector(
	selectRemoteConfig,
	(remoteConfig) => remoteConfig[isWeb ? RemoteConfigKeys.DTC_APPLY_ENABLED_FOR_CSP : RemoteConfigKeys.DTC_APPLY_ENABLED],
);

export const selectIsMaintenanceEnabled = createSelector(selectRemoteConfig, (remoteConfig) => {
	const isMaintenanceSTR = remoteConfig[RemoteConfigKeys.IS_IN_MAINTENANCE_FOR_CSP];

	const maintenance: {
		isActive: boolean;
		title: string;
		contentHTML: string;
	} = JSON.parse(isMaintenanceSTR);

	return maintenance;
});

export const selectAlertInfoBanner = createSelector(
	selectRemoteConfig,
	(remoteConfig) => remoteConfig[RemoteConfigKeys.ALERT_INFO_BANNER_FOR_CSP],
);

export const selectIsSubscriptionsEnabled = createSelector(
	selectRemoteConfig,
	(remoteConfig) => remoteConfig[RemoteConfigKeys.SUBSCRIPTIONS_ENABLED],
);

export const selectBypassForMaintenance = createSelector(
	selectRemoteConfig,
	(remoteConfig) => remoteConfig[RemoteConfigKeys.BYPASS_FOR_MAINTENANCE_FOR_CSP],
);

export const selectIsLockedUserScreenEnabled = createSelector(
	selectRemoteConfig,
	(remoteConfig) => remoteConfig[RemoteConfigKeys.SHOW_LOCKED_USER_SCREEN_FOR_CSP],
);

export const selectIsRefinanceBannerEnabled = createSelector(
	selectRemoteConfig,
	(remoteConfig) => remoteConfig[RemoteConfigKeys.REFINANCE_BANNER_ENABLED_FOR_CSP],
);
