/**
 * @description Event used to log navigation changes.
 * @param currentRouteName
 * @param previousRouteName
 */
export const NAVIGATION_STATE_CHANGE_EVENT = 'navigation_state_change';
/**
 * @description Event used to log when user logging is successful
 */
export const LOGIN_SUCCESSFUL_EVENT = 'login_successful_event';
/**
 * @description Triggered when failed login
 */
export const LOGIN_FAILED_EVENT = 'login_failed_event';
/**
 * @description Event used to log when the user presses the virtual card
 */
export const USER_PRESSED_VIRTUAL_CARD_EVENT = 'user_pressed_virtual_card';
/**
 * @description Event used to log when the user presses the refinance card in the dashboard
 */
export const USER_PRESSED_REFINANCE_CARD_EVENT = 'user_pressed_refinance_card_event';
/**
 * @description Event used to log when the user selects an offer.
 * @param loanAmount the amount of the loan of the selected offer
 */
export const USER_SELECTED_OFFER_EVENT = 'user_selected_offer_event';
/**
 * @description Event used to log when the user confirmed personal information after it selected an offer
 */
export const USER_CONFIRMED_PERSONAL_INFO_EVENT = 'user_confirmed_personal_info';
/**
 * @description Event used to log when the user accepted the loan refinance
 */
export const USER_ACCEPTED_LOAN_AGREEMENT_EVENT = 'user_accepted_loan_agreement';
/**
 * @description Event used to log when the user opens a notification detail from the notification list
 */
export const USER_OPENED_NOTIFICATION_EVENT = 'user_opened_notification';
/**
 * @description Event used to log when the user sign out
 */
export const USER_SIGNED_OUT_EVENT = 'user_signed_out_event';
/**
 * @description Event used to log when the user selects an option from profile button
 */
export const USER_PRESSED_PROFILE_OPTION_EVENT = (profileOption = '') => `user_pressed_${profileOption}`;
/**
 * @description Event used to log when the user opens a document
 */
export const USER_OPENED_DOCUMENT_SUCCESS_EVENT = 'user_open_document_success';

export const USER_OPENED_DOCUMENT_FAILED_EVENT = 'user_open_document_failed';

export const DOCUMENT_DOWNLOAD_FAILED = 'document_download_failed';
/**
 * @description Event used to log when the user adds a new bank account
 */
export const USER_ADDED_NEW_BANK_ACCOUNT_EVENT = 'user_added_new_bank_account';
/**
 * @description Event used to log when the user adds a new debit card
 */
export const USER_ADDED_NEW_DEBIT_CARD_EVENT = 'user_added_debit_card';
/**
 * @description Event used to log when the user presses call us button in help center
 */
export const USER_PRESSED_CALL_US_EVENT = 'user_pressed_help_center_call_us_click';
/**
 * @description Event used to log when the user presses call us button in help center
 */
export const USER_MISSED_PAYMENTS_PRESSED_CALL_US_EVENT = 'missed_payments_modal_call_us_click';
/**
 * @description Event used to log when the user presses sends help email
 */
export const USER_SENT_EMAIL_EVENT = 'user_sent_email';
/**
 * @description Event used to log when the user opens financial life new
 */
export const USER_OPENED_FINANTIAL_LIFE_NEW_EVENT = 'user_opened_finantial_life_new';
/**
 * @description Event used to log when the user presses the button to continue with an extrapayment
 */
export const USER_WOULD_LIKE_TO_MAKE_AN_EXTRAPAYMENT_EVENT = 'user_would_like_to_make_an_extrapayment';
/**
 * @description Event used to log when the user presses the button to go and make an extrapayment
 */
export const USER_PRESSED_CONTINUE_TO_MAKE_AN_EXTRAPAYMENT_EVENT = 'user_pressed_continue_to_make_an_extrapayment';
/**
 * @description Event used to log when the user presses the button to make an extrapayment
 */
export const USER_MADE_AN_EXTRAPAYMENT_EVENT = 'user_made_an_extrapayment';
/**
 * @description Event used to log when the user presses the button to make a payment
 */
export const USER_MADE_A_PAYMENT_EVENT = 'user_made_a_payment';
/**
 * @description Event trigger when payment or extrapayment failed
 */
export const PAYMENT_FAILED_EVENT = 'payment_failed_event';
/**
 * @description Event used to log when the user enrolls in autopayment
 */
export const USER_ENROLLED_IN_AUTOPAYMENT_EVENT = 'autopayment_enrolled_success';
/**
 * @description Event used to log when the user replies a notification
 */
export const USER_REPLIED_NOTIFICATION_EVENT = 'user_replied_notification';
/**
 * @description Event used to log when the user deletes a notification
 */
export const USER_DELETED_NOTIFICATION_EVENT = 'user_deleted_notification';
/**
 * @description Event used to log when user logged using face id
 */
export const USER_LOGGED_WITH_FACE_ID_EVENT = 'user_logged_with_faceid';
/**
 * @description Event used to log when user logged using touch id
 */
export const USER_LOGGED_WITH_TOUCH_ID_EVENT = 'user_logged_with_touchid';
/**
 * @description Event used to log when user press Get Pre-qualified in login page
 */
export const USER_PRESSED_GET_PRE_QUALIFIED_EVENT = 'user_pressed_get_pre_qualified';
/**
 * @description Event used to log when user press contact us in login page
 */
export const USER_PRESSED_CONTACT_US_EVENT = 'user_pressed_contact_us';
/**
 * @description Event used to log when user sent successful forgot password event
 */
export const FORGOT_PASSWORD_EMAIL_SENT_EVENT = 'forgot_password_email_sent';
/**
 * @description Event used to log when user press call in refinance page
 */
export const USER_PRESSED_REFINANCE_CALL_US_EVENT = 'user_pressed_refinance_call_us';
/**
 * @description Event used to log when user press hyperlink LOAN AGREEMENT
 */
export const USER_PRESSED_VIEW_REFINANCE_SIGN_DOCUMENT = 'user_pressed_view_refinance_sign_document';
/**
 * @description Event used to log  when user press terms and conditions in make paymebt
 */
export const USER_PRESSED_MAKE_PAYMENT_TERMS_AND_CONDITIONS = 'user_pressed_make_payment_terms_and_cond';
/**
 * @description Fired when user press open terms and cond
 */
export const USER_PRESSED_EXTRA_PAYMENT_TERMS_AND_CONDITIONS = 'user_pressed_extra_payment_terms_and_cond';
/**
 * @description Fired when user delete a payment method successful
 */
export const USER_DELETE_PAYMENT_METHOD_SUCCESS = 'user_delete_payment_method_success';
/**
 * @description Fired when failed delete payment method
 */
export const USER_DELETE_PAYMENT_METHOD_FAILED = 'user_delete_payment_method_failed';
/**
 * @description Fired when user press sent receipt and it success
 */
export const USER_SEND_RECEIPT_EVENT = 'user_send_receipt_event';
/**
 * @description Fired when user press sent receipt and it success
 */
export const USER_PRINT_RECEIPT_EVENT = 'user_print_receipt_event';
/**
 * @description Event when loan list failed
 */
export const LOAN_LIST_FAILED_EVENT = 'loan_list_failed';
/**
 * @description Fired when offers catalog load failed
 */
export const OFFER_CATALOG_FAILED_EVENT = 'offer_catalog_failed_event';
/**
 * @description Fired when profile info data failed
 */
export const PROFILE_FAILED_EVENT = 'profile_failed_event';
/**
 * @description Fired when user add new debit card and it failed
 */
export const ADD_DEBIT_CARD_FAILED_EVENT = 'add_debit_card_failed_event';
/**
 * @description Fired when user add new bank account and it failed
 */
export const ADD_BANK_ACCOUNT_FAILED_EVENT = 'add_bank_account_failed_event';
/**
 * @description Fired when user setup autopay and it failed
 */
export const SETUP_AUTOPAY_FAILED_EVENT = 'setup_autopay_failed_event';
/**
 * @description Fired when user sign fail and this failed
 */
export const SDOCS_FAILED_EVENT = 'sdocs_failed_event';
/**
 * @description Fired when autologout occurred
 */
export const AUTLOGOUT_EVENT = 'autologout_event';
/**
 * @description Fired when occured an internal server error >= 500
 */
export const INTERNAL_SERVER_ERROR_EVENT = 'internal_server_error';
/**
 * @description Fired when ocurred an uanthorized server error 401 || 403
 */
export const UNAUTHORIZED_SERVER_ERROR_EVENT = 'unauthorized_server_error';
/**
 * @description Fired when app detect user has not internet connection
 */
export const OFFLINE_STATUS_EVENT = 'offline_status_event';
/**
 * @description Fired when user sent email us but it failed
 */
export const EMAIL_US_FAILED_EVENT = 'email_us_failed_event';
/**
 * @description Fired whe user reply a notification but it failed
 */
export const NOTIFICATION_REPLY_FAILED_EVENT = 'notification_reply_failed_event';
/**
 * @description Fired when user press sent receipt and it failed
 */
export const SEND_RECEIPT_FAILED_EVENT = 'send_receipt_failed_event';
/**
 * @description Fired when user press print receipt and it failed
 */
export const PRINT_RECEIPT_FAILED_EVENT = 'print_receipt_failed_event';
/**
 * @description Fired when loan details failed
 */
export const LOAN_DETAILS_FAILED_EVENT = 'loan_details_failed_event';
/**
 * API error - >=400
 */
export const GENERIC_API_ERROR_EVENT = 'generic_api_error_event';
/**
 * @description Event used to log when user press payment methods selector
 */
export const MODAL_PAYMENT_METHOD_DELETE_CONFIRMATION = 'PaymentMethodDeleteConfirmModal';

export const MODAL_HELP_CENTER_EMAIL_US = 'HelpCenterEmailUsModal';
/**
 * @description Saw the payoff online - Test A - How many saw the payoff online via app
 */
export const USER_SAW_PAYOFF_ONLINE_EVENT = 'user_saw_payoff_online';
/**
 * @description User paidoff online - Test A - How many paidoff online
 */
export const USER_PAIDOFF_ONLINE_EVENT = 'user_paidoff_online';
/**
 * @description Saw additional payment -  Test A - Radio Buttons
 */
export const USER_SAW_ADDITIONAL_PAYMENT_EVENT = 'user_saw_additional_payment';
/**
 * @description Saw other amount How many saw the other amount via app/desktop?
 */
export const USER_SAW_OTHER_AMOUNT_EVENT = 'user_saw_other_amount';
/**
 * @description Event when upcoming payments failed
 */
export const ALL_UPCOMING_PAYMENTS_FAILED_EVENT = 'all_upcoming_payments_failed';
/**
 * @description failed to get quick links
 */
export const FAILED_TO_GET_QUICK_LINKS = 'failed_to_get_quick_links';

/**
 * @description Error set autopay
 */
export const BAD_REQUEST_GET_PAYMENT_METHODS = 'bad_request_get_payment_methods';
/**
 * @description Bad request set autopay
 */
export const BAD_REQUEST_SET_AUTOPAY = 'bad_request_set_autopay';
/**
 * @description
 */
export const ERROR_SET_AUTOPAY = 'error_set_autopay';
