import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';

import { isNative } from '@cp/common/platform';
import middleware from '@cp/common/services/middlewares/middleware';
import { vendors } from '@cp/common/services/modules/lendingpoint/api';

import { AnsweredQuestion, IPollingResponse, ResponseKYCQuestions, ResponseSaveKYCAnswers } from './types';
import type { ApiResponse, HTTPServicesResponse } from '../../../services/utils';

import config from '../../../config';
import { lendingpoint, wordpress } from '../../../services';
import { PaymentMethodModeType } from '../../payments/utils';
import { UserResponse } from '../../profile/utils';
import { decryptData } from '../../shared/utils';
import {
	ConsentTermsWordPressResponse,
	GetDocumentByIdResponse,
	GetDocumentsResponse,
	ILoanDetailsResponse,
	joinCreditUnionSaveMethod,
	joinCreditUnionSaveMethodResponse,
	LoanApplicationsResponse,
	OffersCatalogResponse,
	PreferredTypeOffersResponseType,
	RecalculatePayload,
	RefinanceOffer,
	ResponsePostWpRefi,
	ServiceHours,
	SingleUserDocumentsResponseType,
	Tila,
	UploadDocumentType,
} from '../utils';

const {
	api: { consumer },
	constants: { paths },
	helpers: { handleSubModuleError },
} = lendingpoint;

export const sameInformationService = async ({
	applicationId,
	screenIdentifier,
}: {
	applicationId: string;
	screenIdentifier?: 'refiBanner' | 'PersonalInfo';
}) => {
	try {
		const data =
			screenIdentifier === 'refiBanner'
				? { screenIdentifier, isSameBankInfo: null, isSameEmployment: null, isSameIncome: null, isFunded: null }
				: screenIdentifier === 'PersonalInfo'
				? { screenIdentifier, isSameBankInfo: true, isSameEmployment: true, isSameIncome: true }
				: { isSameBankInfo: true, isSameEmployment: true, isSameIncome: true };

		const response = await consumer.patch(`${paths.loanApplications}/${applicationId}`, data);
		return { success: true, payload: response.data };
	} catch (error: any) {
		return handleSubModuleError(error);
	}
};

export const getLoanOpportunities = async (): Promise<HTTPServicesResponse<LoanApplicationsResponse>> => {
	try {
		const response = await consumer.get<LoanApplicationsResponse>(`${paths.loanApplications}`);
		return { success: true, payload: response.data };
	} catch (err: any) {
		return handleSubModuleError(err);
	}
};

export const getOffersCatalog = async ({
	opportunityId,
	isEbay,
	maxRefinanceDisbursal,
	RefinanceVarianceMinPayment,
	LowerRate,
}: {
	opportunityId: string;
	isEbay?: boolean;
	maxRefinanceDisbursal?: boolean;
	RefinanceVarianceMinPayment?: boolean;
	LowerRate?: boolean;
}): Promise<HTTPServicesResponse<OffersCatalogResponse>> => {
	try {
		const response = await consumer({
			method: 'POST',
			baseURL: `${config.api.host}/consumer/v2`,
			url: paths.applications + paths.offersCatalog,
			data: {
				opportunityId,
				systemSource: isNative
					? isEbay
						? 'Mobile.RefinanceSMBSlider'
						: 'Mobile.Refinance'
					: isEbay
					? 'RefinanceWebSMBSlider'
					: 'RefinanceWebSlider',
				maxRefinanceDisbursal,
				RefinanceVarianceMinPayment,
				LowerRate,
			},
		});
		const data = response.data as OffersCatalogResponse;

		return { success: true, payload: data };
	} catch (err) {
		return handleSubModuleError(err as AxiosError);
	}
};

export const getOffersRefinanceNew = async (appId: string): Promise<HTTPServicesResponse<RefinanceOffer[]>> => {
	try {
		const { data } = await consumer({
			baseURL: `${config.api.host}/consumer/v2${paths.loanApplications}/${appId}/offers`,
			method: 'GET',
		});
		return { success: true, payload: data.offers };
	} catch (err: any) {
		return handleSubModuleError(err);
	}
};

export const selectOfferRefinanceNew = async (opportunityId: string, offerId: string): Promise<HTTPServicesResponse<any[]>> => {
	try {
		const { data } = await consumer.patch<any[]>(`${paths.loanApplications}/${opportunityId}/offers/${offerId}`, {
			selected: true,
			source: isNative ? 'RefinanceMobileApp' : 'RefinanceWeb',
			offerSelectSrc: isNative ? 'RefinanceMobileApp' : 'RefinanceWeb',
		});
		return { success: true, payload: data };
	} catch (err: any) {
		return handleSubModuleError(err);
	}
};

export const recalculateAndSelectOffer = async ({
	loanAmount,
	opportunityId,
}: RecalculatePayload): Promise<HTTPServicesResponse<RefinanceOffer>> => {
	try {
		const {
			data: { offers },
		}: AxiosResponse<{ offers: RefinanceOffer[] }> = await consumer.post(`${paths.loanApplications}/${opportunityId}/offers`, {
			loanAmount,
		});

		const selectedOffer = offers.sort((a, b) => {
			const m1 = a.loanAmount;
			const m2 = b.loanAmount;
			const n1 = a.term;
			const n2 = b.term;
			return m1 > m2 ? -1 : m1 < m2 ? 1 : n1 > n2 ? -1 : n1 > n2 ? 1 : 0;
		})[0];

		await consumer.patch(`${paths.loanApplications}/${opportunityId}/offers/${selectedOffer.id}`, {
			selected: true,
			source: isNative ? 'RefinanceMobileApp' : 'RefinanceWeb',
			offerSelectSrc: isNative ? 'RefinanceMobileApp' : 'RefinanceWeb',
		});
		return { success: true, payload: selectedOffer };
	} catch (err) {
		return handleSubModuleError(err as AxiosError);
	}
};

export const updateUserInfo = async (dataForm?: any): Promise<ApiResponse<UserResponse>> => {
	try {
		const response = await consumer.patch<UserResponse>(paths.user, dataForm);
		return { success: true, response: response.data };
	} catch (err: any) {
		return handleSubModuleError(err);
	}
};

export const getContract = async ({
	applicationId,
	isEbay,
}: {
	applicationId: string;
	isEbay: boolean;
}): Promise<ApiResponse<SingleUserDocumentsResponseType>> => {
	try {
		if (!isEbay) {
			const { data, status } = await vendors.get<SingleUserDocumentsResponseType>(`/services/documents/agreement`, {
				headers: {
					'lp-external-id': applicationId,
				},
			});
			return {
				response: data,
				status,
				success: !!Object.keys(data).length,
			};
		} else {
			const { data } = await consumer.get<SingleUserDocumentsResponseType>(
				`${paths.applications}/${applicationId}/loan-agreement`,
			);
			return { success: true, response: data };
		}
	} catch (error) {
		return handleSubModuleError(error as AxiosError);
	}
};

export const signAgreement = async ({
	signatureFileName,
	signatureFileContent,
	opportunityId,
	isEbay,
}: {
	signatureFileName: string;
	signatureFileContent: string;
	opportunityId: string;
	isEbay: boolean;
}): Promise<HTTPServicesResponse<any>> => {
	try {
		if (!isEbay) {
			const { data } = await vendors.post(
				`/services/documents/agreement`,
				{
					signatureFileName,
					signatureFileContent,
				},
				{
					headers: {
						'lp-external-id': opportunityId,
					},
				},
			);
			return { success: true, payload: data };
		} else {
			const { data } = await consumer.patch<{}>(`${paths.applications}/${opportunityId}`, {
				signatureFileName,
				signatureFileContent,
			});
			return { success: true, payload: data };
		}
	} catch (err: any) {
		return handleSubModuleError(err);
	}
};

export const enrollAutopayRefinanceNew = async (
	externalId: string,
	paymentId: string,
	mode: PaymentMethodModeType,
): Promise<HTTPServicesResponse<LoanApplicationsResponse>> => {
	try {
		const response = await consumer.patch<LoanApplicationsResponse>(
			`${paths.loanApplications}/${externalId}/payment-methods/${paymentId}`,
			{
				paymentMethod: mode,
			},
		);
		return { success: true, payload: response.data };
	} catch (err: any) {
		return handleSubModuleError(err);
	}
};

export const uploadDocument = async (
	data: UploadDocumentType,
): Promise<
	HTTPServicesResponse<{
		docId: string;
		message: string;
	}>
> => {
	try {
		const { applicationId, formData } = data;

		const response = await consumer.post(`${paths.loanApplications}/${applicationId}/documents`, formData, {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		});
		return {
			success: true,
			payload: response.data,
		};
	} catch (error: any) {
		const statusCode = error?.response?.status;
		return {
			...handleSubModuleError(error),
			statusCode,
		};
	}
};

export const getDocuments = async (applicationId: string): Promise<HTTPServicesResponse<GetDocumentsResponse>> => {
	try {
		const response = await consumer.get<GetDocumentsResponse>(`${paths.loanApplications}/${applicationId}/documents`);
		return {
			success: true,
			payload: response.data,
		};
	} catch (error: any) {
		return handleSubModuleError(error);
	}
};

export const getDocumentById = async ({
	documentId,
	applicationId,
}: {
	documentId: string;
	applicationId: string;
}): Promise<HTTPServicesResponse<GetDocumentByIdResponse>> => {
	try {
		const res = await consumer.get<GetDocumentByIdResponse>(`${paths.loanApplications}/${applicationId}/documents/${documentId}`);
		return {
			success: true,
			payload: res.data,
		};
	} catch (error: any) {
		return handleSubModuleError(error);
	}
};

export const removeDocuments = async ({
	applicationId,
	documentId,
}: {
	applicationId: string;
	documentId: string;
}): Promise<HTTPServicesResponse<{ status: number; message: string }>> => {
	try {
		const response = await consumer.delete<{ status: number; message: string }>(
			`${paths.loanApplications}/${applicationId}/documents/${documentId}`,
		);
		return {
			success: true,
			payload: response.data,
		};
	} catch (error: any) {
		return handleSubModuleError(error);
	}
};

export const getTila = async (opportunityId: string): Promise<HTTPServicesResponse<Tila>> => {
	try {
		const { data } = await consumer.get<Tila>(`${paths.loanApplications}/${opportunityId}`);
		return { success: true, payload: data };
	} catch (error: any) {
		return handleSubModuleError(error);
	}
};

export const getWordPressConsentRefi = async ({ idPost }: { idPost: string }): Promise<ApiResponse<ResponsePostWpRefi>> => {
	try {
		const { data, status } = await wordpress.api.get<ResponsePostWpRefi>(`posts/${idPost}`);
		return { response: data, status, success: true };
	} catch (error: any) {
		return handleSubModuleError(error);
	}
};

// KYC
export const fetchKYCQuestions = async ({
	appId,
	correlationId = '',
	config,
}: {
	appId: string;
	correlationId?: string;
	config?: AxiosRequestConfig;
}): Promise<ApiResponse<ResponseKYCQuestions>> => {
	try {
		const path = 'me/verification/kyc-questions';
		const { data, status } = await consumer.get<ResponseKYCQuestions>(path, {
			...config,

			headers: { 'lp-external-id': appId, 'lp-correlation-id': correlationId },
		});
		return { response: data, status, success: !!Object.keys(data) };
	} catch (error: any) {
		return handleSubModuleError(error);
	}
};

export const saveKYCAnswers = async (
	reqData: AnsweredQuestion[],
	appId: string,
	correlationId: string,
): Promise<ApiResponse<ResponseSaveKYCAnswers>> => {
	try {
		const path = 'me/verification/kyc-questions';
		const { data, status } = await consumer.post<ResponseSaveKYCAnswers>(path, reqData, {
			headers: {
				'lp-external-id': appId,
				'lp-correlation-id': correlationId,
			},
		});
		return { response: data, status, success: !!Object.keys(data) };
	} catch (error: any) {
		return handleSubModuleError(error);
	}
};

export const getWordPressConsent = async (slug: string): Promise<HTTPServicesResponse<ConsentTermsWordPressResponse>> => {
	try {
		const response = await wordpress.api.get<ConsentTermsWordPressResponse[]>(wordpress.constants.paths.posts, {
			params: {
				category: 'core-app',
				slug,
			},
		});
		return {
			success: true,
			payload: response.data?.[0],
		};
	} catch (err: any) {
		return handleSubModuleError(err);
	}
};

export const getPreferredTypeOffers = async (
	sellerId: string,
): Promise<HTTPServicesResponse<PreferredTypeOffersResponseType>> => {
	try {
		const { data } = await consumer.get<PreferredTypeOffersResponseType>(`${paths.user}/sellers/${sellerId}`);
		return { success: true, payload: data };
	} catch (err: any) {
		return handleSubModuleError(err);
	}
};

export const consent = async (opportunityId: string): Promise<HTTPServicesResponse<any[]>> => {
	try {
		const { data } = await consumer.patch<any[]>(`${paths.loanApplications}/${opportunityId}`, {
			isSameIncome: true,
			isSameEmployment: true,
			isSameBankInfo: true,
		});
		return { success: true, payload: data };
	} catch (err: any) {
		return handleSubModuleError(err);
	}
};

export const apiGetLoanDetails = async (applicationId: string): Promise<HTTPServicesResponse<ILoanDetailsResponse>> => {
	try {
		const res = await consumer({
			baseURL: `${config.api.host}/consumer/v2/applications/${applicationId}/details/`,
		});

		return {
			success: true,
			payload: res.data,
		};
	} catch (error: any) {
		return handleSubModuleError(error);
	}
};

export const saveCreditUnionMethod = async (
	req: joinCreditUnionSaveMethod,
): Promise<HTTPServicesResponse<joinCreditUnionSaveMethodResponse>> => {
	try {
		const { data } = await consumer.put<joinCreditUnionSaveMethodResponse>(`/applications/save-user-membership-details`, [
			{ ...req },
		]);

		return { success: true, payload: data };
	} catch (error: any) {
		return handleSubModuleError(error);
	}
};

/*
 * this function get polling status
 * this is used for next step from contract Signed
 */
export const apiGetPollingStatus = async (
	appId: string,
	authToken: string | undefined,
	cancelToken?: CancelToken | undefined,
	correlationId = '',
): Promise<HTTPServicesResponse<IPollingResponse>> => {
	try {
		const { data: resData } = await middleware.get(`/v1/applications/${appId}/status`, {
			cancelToken: cancelToken,
			headers: {
				Authorization: `Bearer ${authToken}`,
				'lp-correlation-id': correlationId,
			},
		});

		const result = decryptData(resData.data, authToken as string);

		const payload = typeof result === 'string' ? JSON.parse(result) : result;

		return { success: !!Object.keys(resData).length, payload };
	} catch (error: any) {
		const res = error as AxiosError;

		if (axios.isCancel(error)) {
			return { success: false, errorMessage: res.message };
		}

		return handleSubModuleError(error);
	}
};

/*
 * this function return hours operation
 * from the middleware
 */
export const getHoursOperation = async (): Promise<HTTPServicesResponse<{ data: Array<ServiceHours> }>> => {
	try {
		const { data } = await middleware.post<{ data: Array<ServiceHours> }>('/middleware/hours-operation');

		return { success: true, payload: data };
	} catch (err: any) {
		return handleSubModuleError(err);
	}
};
