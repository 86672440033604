export const mapScreen: Record<string, string> = {};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMappedScreenName = (screenName: string | null | undefined): string | null | undefined => {
	return undefined;
};
export const routes = {
	// OCTP
	octp: 'octp',
	// ONBOARDING AUTOPAY
	octap: 'octap',
	// ONBOARDING DEEPLINK
	tokenized: 'tokenized',
	// Refresh
	redirect: 'redirect',
	// Auth
	auth: 'auth',
	forgotPassword: 'forgot-password',
	authenticationFailed: 'authentication-failed',
	emailSend: 'email-send',
	// Loans
	loans: 'loans',
	loanDetails: 'loan-details',
	delinquentNotice: 'delinquent-notice',
	paymentsHistory: 'payments-history',
	paymentDetails: 'payment-details',
	// Refinance, Multiloan
	multiloan: 'multi-loan',
	refinance: 'refinance-offers',
	refinanceEbayOffersOptions: 'ebay-options',
	personalInfoConsent: 'personal-info-consent',
	confirmPersonalInfo: 'confirm-personal-info',
	offers: 'offers',
	sDocs: 's-docs',
	whatsNext: 'whats-next',

	maintenance: 'in-maintenance',

	blockedAccount: 'blocked-account',

	//Refinance 2.0
	refinanceV2: {
		refinance: 'refinance',
		personalInfo: 'personal-information',
		editPersonalInfo: 'edit-personal-information',
		incomeInformation: 'income-information',
		editIncomeInformation: 'edit-income-information',
		bankInformation: 'bank-information',
		bankConnect: 'bank-connect',
		bankInfoNeeded: 'bank-information-needed',
		bankMultipleBankAccounts: 'bank-multiple-bank-accounts',
		bankError: 'bank-error',
		paymentMethodError: 'paymentMethod-error',
		paymentSetup: 'payment-setup',
		plaid: 'plaid',
		offers: 'offers',
		autopay: 'autopay',
		creditUnionNasa: 'credit-union-nasa',
		creditUnionPenfed: 'credit-union-penfed',
		agreement: 'agreement',
		whatsNext: 'whats-next',
		refinanceEbayOffersOptions: 'ebay-options',
		tryAgain: 'try-again',
		offerDecline: 'offer-decline',
		applicationInReview: 'application-in-review',
		checklist: {
			uploadDocuments: 'upload-documents',
			automaticDocumentsUpload: 'automatic-documents-upload',
			automaticDocumentsUploadSuccess: 'automatic-documents-upload-success',
			automaticDocumentsUploadError: 'automatic-documents-upload-error',
			manualDocumentsUpload: 'manual-documents-upload',
			manualDocumentsUploadSuccess: 'manual-documents-upload-success',
		},
		kyc: {
			default: 'verify-identity',
			verifyIdentityTimeout1: 'verify-identity-timeout-1',
			verifyIdentityTimeout2: 'verify-identity-timeout-2',
			verifyIdentityDecline: 'verify-identity-decline',
		},
	},

	//opps something went wrong
	oppsSomethingWentWrong: 'opps-something-went-wrong',
	// offers not found
	decline: 'decline',
	// Payments
	payments: 'payments',
	paymentsCenter: {
		initial: 'payment-center',
		autopaySetup: 'autopay-setup',
		paymentMethods: 'payment-methods',
		history: 'history',
		details: 'details',
		paymentCancel: 'payment-canceled',
		paymentCancelFailed: 'payment-canceled-failed',
	},

	// Make-Payment
	makePaymentRoute: {
		initial: 'make-payment',
		selectLoan: 'select-loan',
		selectPaymentAmount: 'select-payment-amount',
		selectPaymentDate: 'select-payment-date',
		selectPaymentMethod: 'select-payment-method',
		paymentSummary: 'payment-summary',
		paymentSummaryError: 'payment-error',
		paymentSummarySuccess: 'payment-success',
		paymentSummaryProccessing: 'payment-proccessing',
	},

	autopaySetup: 'autopay-setup',
	makePayment: 'make-payment', //TODO borrar
	extraPayment: 'extra-payment',
	paymentReceipt: 'payment-receipt',
	paymentMethods: 'payment-methods',
	newBankAccount: 'new-bank-account',
	newDebitCard: 'new-debit-card',
	addBillingInformation: 'add-billing-information',

	// Enroll in AutoPay and Manage autopay
	enrollInAutoPay: {
		enrollInAutoPay: 'enroll-in-autopay',
		autopaySummary: 'autopay-summary',
		autopaySetup: 'autopay-setup',
		autopaySuccess: 'autopay-success',
		autopayFail: 'autopay-fail',
		autopayConfirm: 'autopay-confirm',
		autopayStop: 'autopay-stop',
		autopaySelectLoan: 'autopay-select-loan',
	},
	// Profile
	profile: 'profile',
	profileInfo: 'profile-info',
	security: 'security',
	settings: 'settings',
	documents: 'documents',
	helpCenter: 'help-center',
	notifications: 'notifications',
	completedLoans: 'completed-loans',
	PaymentMethodsDetails: 'payment-method-details',
	PaymentMethodsSuccess: 'payment-method-success',
	PaymentMethodFailed: 'payment-method-failed',
	// Financial Life
	financialLife: 'financial-life',
	newsDetails: 'news-details',
	// TryAgain
	tryAgain: 'try-again',
	// Payment failed
	paymentFailed: 'payment-failed',
	// Sesssion timeout
	sessionTimeout: 'session-timeout',
} as const;
