import isBoolean from 'lodash/isBoolean';

import type { AxiosError, AxiosRequestConfig } from 'axios';
import type { ApiResponse } from '../../../services/utils';
import type {
	AddPaymentMethodResponse,
	AddPaymentMethodValues,
	AllPaymentsHistoryResponse,
	CancelUpcomingPaymentType,
	MakePaymentResponse,
	PaymentHistoryResponse,
	PaymentMethodModeType,
	PaymentReceiptType,
	PaymentType,
	ResponsePayments,
	UpcomingPaymentsResponse,
	UpdatePaymentMethodNickname,
} from '../utils';

import { lendingpoint } from '../../../services';
import { PaymentMethodTypeKeys } from '../constants';

const {
	api: { consumer },
	constants: { paths },
	helpers: { handleSubModuleError },
} = lendingpoint;

export const getPaymentMethods = async (config?: AxiosRequestConfig): Promise<ApiResponse<ResponsePayments>> => {
	try {
		const response = await consumer.get<ResponsePayments>(paths.paymentMethods, {
			'axios-retry': {
				retries: 3,
			},
			...config,
		});
		return { success: true, response: response.data, status: response.status };
	} catch (err) {
		return handleSubModuleError(err);
	}
};

export const addPaymentMethod = async (data: AddPaymentMethodValues): Promise<ApiResponse<AddPaymentMethodResponse>> => {
	try {
		const { payload, mode } = data;
		const response = await consumer.post<AddPaymentMethodResponse>(paths.paymentMethods, {
			...payload,
			paymentMethod: mode,
		});
		return { success: true, response: response.data };
	} catch (err: any) {
		return { ...handleSubModuleError(err), details: err.response?.data?.detail || '' };
	}
};

export const savePaymentsDefault = async (accountId: string, mode: string): Promise<ApiResponse<ResponsePayments>> => {
	try {
		const response = await consumer.patch<ResponsePayments>(`${paths.paymentMethods}/${accountId}`, {
			paymentMethod: mode,
		});
		return { success: true, response: response.data };
	} catch (err) {
		return handleSubModuleError(err);
	}
};

export const deletePayment = async (paymentMethodId: string, mode: string): Promise<ApiResponse<ResponsePayments>> => {
	try {
		const response = await consumer.delete<ResponsePayments>(
			`${paths.paymentMethods}/${paymentMethodId}?paymentMethod=${mode}`,
			{},
		);
		return { success: true, response: response.data, status: response.status };
	} catch (err) {
		return handleSubModuleError(err);
	}
};

export const makePayment = async (
	paymentMethodId: string,
	payment: PaymentType,
	mode: PaymentMethodModeType,
): Promise<ApiResponse<MakePaymentResponse>> => {
	const { contractId, amount, isAdditionalPayment, email, paymentType, paymentDate } = payment;
	try {
		const response = await consumer.post<MakePaymentResponse>(
			`${paths.loans}/${contractId}/payments`,
			Object.assign(
				mode === PaymentMethodTypeKeys.BANK_ACCOUNT
					? {
							bankId: paymentMethodId,
							email: email ?? '',
							paymentMethod: mode,
							amount,
							paymentType,
							paymentDate,
					  }
					: {
							accountId: paymentMethodId,
							paymentMethod: mode,
							amount,
							paymentType,
							paymentDate,
					  },
				isBoolean(isAdditionalPayment) && { isAdditionalPayment },
			),
		);
		return { success: true, response: response.data, status: response.status };
	} catch (err) {
		const subModuleError = handleSubModuleError(err);
		let errorMessage = subModuleError.errorMessage;
		const errors = ((err as AxiosError).response?.data as Record<string, Record<string, string>>)?.errors;

		// Check errorCode if is ACH error `Payment can not be processed as ACH Transaction is already in process` instead that `Your payment is not enabled`
		if (errors?.errorCode === 'E003') {
			errorMessage = errors?.message || errors?.detail;
		}

		return { ...subModuleError, errorMessage: errorMessage };
	}
};

export const sendReceipt = async (payment: PaymentReceiptType): Promise<ApiResponse<undefined>> => {
	const { contractId, transactionId, paymentMethod } = payment;
	try {
		const response = await consumer.post<undefined>(`${paths.loans}/${contractId}/payment-receipt`, {
			paymentMethod: paymentMethod?.mode,
			transactionId,
		});
		return { success: true, response: response.data, status: response.status };
	} catch (err) {
		return handleSubModuleError(err);
	}
};

export const cancelUpcomingPayment = async ({
	loanId,
	action = 'cancelPayment',
	paymentMethod,
	paymentDate,
	paymentAmount,
}: CancelUpcomingPaymentType): Promise<ApiResponse<ResponsePayments>> => {
	try {
		const response = await consumer.patch<ResponsePayments>(`${paths.loans}/${loanId}`, {
			action,
			paymentMethod,
			paymentDate,
			paymentAmount,
		});
		return { success: true, response: response.data };
	} catch (err) {
		return handleSubModuleError(err);
	}
};

export const getPaymentHistoryById = async (
	loanId: string,
	config?: AxiosRequestConfig,
): Promise<ApiResponse<PaymentHistoryResponse>> => {
	try {
		const response = await consumer.get<PaymentHistoryResponse>(`${paths.loans}/${loanId}/payments?type=all`, config);
		return { success: true, response: response.data };
	} catch (err) {
		return handleSubModuleError(err);
	}
};

export const getAllPaymentsHistory = async (config?: AxiosRequestConfig): Promise<ApiResponse<AllPaymentsHistoryResponse>> => {
	try {
		const response = await consumer.get<AllPaymentsHistoryResponse>(paths.payments, config);
		return { success: true, response: response.data };
	} catch (err) {
		return handleSubModuleError(err);
	}
};

export const getUpcomingPaymentsById = async (
	loanId: string,
	config?: AxiosRequestConfig,
): Promise<ApiResponse<UpcomingPaymentsResponse>> => {
	try {
		const response = await consumer.get<UpcomingPaymentsResponse>(`${paths.loans}/${loanId}/payments?type=upcoming`, {
			'axios-retry': {
				retries: 2,
			},
			...config,
		});
		return { success: true, response: response.data };
	} catch (err) {
		return handleSubModuleError(err);
	}
};

export const getAllUpcomingPayments = async (config?: AxiosRequestConfig): Promise<ApiResponse<UpcomingPaymentsResponse>> => {
	try {
		const response = await consumer.get<UpcomingPaymentsResponse>(`${paths.payments}?type=upcoming`, {
			'axios-retry': {
				retries: 2,
			},
			...config,
		});
		return { success: true, response: response.data };
	} catch (err) {
		return handleSubModuleError(err);
	}
};

export const updatePaymentMethodNickname = async ({
	paymentMethodId,
	paymentMethod,
	nickname,
	config,
}: UpdatePaymentMethodNickname): Promise<ApiResponse<UpdatePaymentMethodNickname>> => {
	try {
		const response = await consumer.patch(`${paths.paymentMethods}/${paymentMethodId}`, { paymentMethod, nickname }, config);
		return { success: true, response: response.data };
	} catch (error) {
		return handleSubModuleError(error as AxiosError);
	}
};
