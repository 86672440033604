import { createEntityAdapter } from '@reduxjs/toolkit';

import type {
	AllPaymentHistoryState,
	PaymentHistoryState,
	PaymentMethodType,
	PaymentMissedState,
	UpcomingPaymentsState,
} from '../../utils';

export const paymentMethodsAdapter = createEntityAdapter<PaymentMethodType>({
	selectId: (method) => method.paymentMethodId,
});

export const upcomingPaymentsAdapter = createEntityAdapter<UpcomingPaymentsState>();

export const paymentHistoryAdapter = createEntityAdapter<PaymentHistoryState>({
	selectId: (payment) => payment.id,
});

export const paymentMissedsAdapter = createEntityAdapter<PaymentMissedState>({
	selectId: (payment) => payment.loanId,
});

export const allPaymentsAdapter = createEntityAdapter<AllPaymentHistoryState>({
	selectId: (payment) => payment.id,
});
