import { createAsyncThunk } from '@reduxjs/toolkit';

import { ConfigForLinkToken, LinkSuccessPayload } from '@cp/common/plaid/utils';

import * as api from '../../services';

export const fetchLinkToken = createAsyncThunk(
	'plaid/fetchLinkToken',
	async (reqData: ConfigForLinkToken, { rejectWithValue }) => {
		const response = await api.fetchLinkToken(reqData);
		if (!response.success) throw rejectWithValue(response);
		return response;
	},
);

export const savePlaidPublicToken = createAsyncThunk(
	'plaid/savePlaidPublicToken',
	async (reqData: LinkSuccessPayload, { rejectWithValue }) => {
		const response = await api.savePlaidPublicToken(reqData);
		if (!response.success) throw rejectWithValue(response);
		return response;
	},
);
