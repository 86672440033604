import React from 'react';
import { Formik } from 'formik';
import UIkit from 'uikit';

import { getLoanById, selectSingleLoanId } from '@cp/common/loans/store/selectors';
import * as store from '@cp/common/modules/loans/store';
import * as mailService from '@cp/common/notifications/services/mail';
import { useAppSelector } from '@cp/common/store/hooks';
import { formsWeb } from '@cp/common/support/constants';
import { EmailComposerFormValues, HelpEmailUsFormValues } from '@cp/common/support/utils';
import icoEmail from '@images/drops/ico-email.svg';
import UIKitModal from '@shared/components/UIKitModal.component';
import { useOutside } from '@shared/hooks/useClickOutside';
import EmailComposerError from '@support/components/EmailComposerError';
import EmailComposerFormComponent from '@support/components/EmailComposerForm';
import EmailComposerSucces from '@support/components/EmailComposerSucces';

import type { RootState } from '@cp/common/store';

type Props = {
	isButton?: boolean;
	islink?: boolean;
	title?: string;
	className?: string | undefined;
	showIcon?: boolean;
};

const EmailComposerFormContainer: React.FC<Props> = ({ isButton, islink, title, className, showIcon }) => {
	const [isError, setIsError] = React.useState<boolean>(false);
	const [isClosedModal, setIsClosedModal] = React.useState<boolean>(false);
	const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
	const loanId = useAppSelector(selectSingleLoanId);
	const loanById = useAppSelector((state: RootState) => getLoanById(state, loanId));
	const isEmptyLoans = useAppSelector(store.selectors.selectIsEmptyLoans);
	const completedLoans = useAppSelector(store.selectors.getCompletedLoans);
	const bodyElement = document.getElementsByTagName('body')[0];

	const isSendEmail = isSuccess === false && isError === false;
	const formRef = React.useRef<HTMLFormElement>(null);
	const wrapperRef = React.useRef(null);

	useOutside(wrapperRef, () => formRef.current?.reset());

	const onSubmit = async (values: EmailComposerFormValues) => {
		const response = await mailService.composeMail(
			loanById?.overDueDays ?? 0,
			values,
			isEmptyLoans && completedLoans.length > 0 ? completedLoans[0].contractId : loanId ?? '',
		);

		if (response.success) {
			formRef.current?.reset();
			setIsSuccess(true);
		} else {
			formRef.current?.reset();
			setIsError(true);
		}
	};

	const hideModal = () => {
		formRef.current?.reset();
		setIsSuccess(false);
		setIsError(false);
		UIkit.modal(`#lp-help`).hide();
	};

	const handleTryAgain = () => {
		formRef.current?.reset();
		setIsSuccess(false);
		setIsError(false);
	};

	React.useEffect(() => {
		if (isClosedModal) {
			formRef.current?.reset();
			setIsClosedModal(false);
			setIsSuccess(false);
			setIsError(false);
			handleOnHideModal();
		}
	}, [isClosedModal]);

	const handleOnHideModal = () => {
		bodyElement.classList.remove('force-hide-navbar-dropdown');
	};

	return (
		<>
			<a
				className={
					isButton ? 'uk-button uk-button-secondary app-button-delinquent' : islink ? 'uk-button uk-button-link' : className
				}
				href="/"
				target="_blank"
				rel="noreferrer"
				onClick={(e) => {
					e.preventDefault();
					setIsSuccess(false);
					setIsError(false);
					bodyElement.classList.add('force-hide-navbar-dropdown');
					UIkit.modal(`#lp-help`).show();
				}}
			>
				{isButton || islink || !showIcon ? null : <img src={icoEmail} alt="email-us" />}
				{title}
			</a>

			<UIKitModal
				id={isSuccess ? 'lp-help-success' : isError ? 'lp-help-error' : 'lp-help'}
				className="lp-modal"
				uk-modal="true"
				onHidden={handleOnHideModal}
			>
				<div className="uk-modal-dialog uk-width-large uk-margin-auto-vertical fix-modal-header" ref={wrapperRef}>
					<button className="uk-modal-close-default" type="button" uk-close="true" onClick={() => setIsClosedModal(true)} />
					{isSendEmail ? (
						<Formik
							initialValues={
								{
									...formsWeb.emailComposer.initialValues,
									loan: loanId,
								} as HelpEmailUsFormValues
							}
							validationSchema={formsWeb.emailComposer.schema}
							onSubmit={onSubmit}
						>
							<EmailComposerFormComponent formRef={formRef} />
						</Formik>
					) : isSuccess ? (
						<EmailComposerSucces hideModal={hideModal} />
					) : (
						<EmailComposerError handleTryAgain={handleTryAgain} />
					)}
				</div>
			</UIKitModal>
		</>
	);
};

export default EmailComposerFormContainer;
