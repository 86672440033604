import dayjs from 'dayjs';
import * as Yup from 'yup';

// TODO: state is required in schema validation billing information?
// import { SelectItemType, stateValues } from '../../shared/constants';
import { SelectItemType } from '../../shared/constants';
import { format } from '../../shared/helpers';

export { default as messages } from './messages';
export { default as messagesWeb } from './messages.web';
/**
 * redux key name
 */
export const NAME = 'payments';

export enum MakePaymentFlowType {
	SINGLE_LOAN = 'single',
	MULTI_LOAN = 'multi',
}

export const accountTypeValues: SelectItemType[] = [
	{ label: 'Checking', value: 'Checking' },
	{ label: 'Savings', value: 'Savings' },
];

export const PaymentMethodTypeKeys = {
	DEBIT_CARD: 'debitCard',
	BANK_ACCOUNT: 'bankAccount',
	EMPTY: '',
} as const;

export const PaymentMethodNameKeys = {
	DEBIT_CARD: 'Debit Card',
	BANK_ACCOUNT: 'Bank Account',
} as const;

export enum PaymentAmountType {
	MINIMUM = 'minimum',
	OTHER_AMOUNT = 'other',
	PAYOFF_BALANCE = 'payoff',
	PAST_DUE_AMOUNT = 'past_due_amount',
	PAST_DUE_PAYMENT = 'past_due_payment',
	SAC_PAYOFF_BALANCE = 'sac_payoff',
	SAC_PAYMENT = 'sac_payment',
}

/**
 * @description useful when you need set a diferent style such as text color
 */
export const paymentAmountTypesOverDue = [PaymentAmountType.PAST_DUE_AMOUNT, PaymentAmountType.PAST_DUE_PAYMENT];

export const formKeys = {
	debitCard: {
		cardNumber: 'cardNumber',
		expiryDate: 'expiryDate',
		securityCode: 'securityCode',
		zipCode: 'zipCode',
		nickname: 'nickname',
		setDefault: 'default',
	},
	billingInformation: {
		firstName: 'firstName',
		lastName: 'lastName',
		phoneNumber: 'phoneNumber',
		line1Address: 'line1Address',
		line2Address: 'line2Address',
		city: 'city',
		state: 'state',
		zipCode: 'zipCode',
	},
	bankAccount: {
		bankName: 'bankName',
		routingNumber: 'routingNumber',
		accountNumber: 'accountNumber',
		confirmAccountNumber: 'confirmAccountNumber',
		accountType: 'accountType',
		nickname: 'nickname',
		setDefault: 'default',
	},
	makePayment: {
		amountType: 'amountType',
		amount: 'amount',
		otherAmount: 'otherAmount',
		isAdditionalPayment: 'isAdditionalPayment',
		maxAmount: '$maxAmount', // context field
		minAmount: '$minAmount', // context field
		enableAdditionalPaymentValidation: '$enableAdditionalPaymentValidation', // context field
	},
	selectBankAccount: {
		bankName: 'bankName',
		accountNumber: 'accountNumber',
	},
	editPaymentMethodNickname: {
		nickname: 'nickname',
	},
} as const;

// TODO more custom errors // is there a best approach? DRY
export const customValidationMessages = {
	debitCard: {
		[formKeys.debitCard.cardNumber]: { required: 'Card number is required' },
		[formKeys.debitCard.expiryDate]: {
			required: 'Expiration date is required',
			format: 'Please use valid format MM/YY',
		},
		[formKeys.debitCard.securityCode]: {
			required: 'Security code is required',
			min: 'Security code should be 3 character length at least',
			max: 'Security code should be 4 character length maximum',
		},
		[formKeys.debitCard.zipCode]: { required: 'Zip code is required' },
	},
	billingInformation: {
		[formKeys.billingInformation.firstName]: { required: 'First name on card is required' },
		[formKeys.billingInformation.lastName]: { required: 'Last name on card is required' },
		[formKeys.billingInformation.phoneNumber]: { required: 'Phone number is required' },
		[formKeys.billingInformation.line1Address]: { required: 'Address is required' },
		[formKeys.billingInformation.city]: { required: 'City is required' },
		[formKeys.billingInformation.state]: { required: 'State is required' },
		[formKeys.debitCard.zipCode]: { required: 'Zip code is required' },
	},
	bankAccount: {
		[formKeys.bankAccount.bankName]: { required: 'Bank name is required' },
		[formKeys.bankAccount.routingNumber]: {
			required: 'Routing number is required',
			onlyNumbers: 'Please use only numbers',
		},
		[formKeys.bankAccount.accountNumber]: {
			required: 'Account number is required',
			onlyNumbers: 'Please use only numbers',
		},
		[formKeys.bankAccount.confirmAccountNumber]: {
			required: 'Confirm account number is required',
		},
		[formKeys.bankAccount.accountType]: {
			required: 'Account type is required',
		},
	},
	makePayment: {
		[formKeys.makePayment.amount]: {
			required: 'Please provide an amount',
		},
		[formKeys.makePayment.otherAmount]: {
			onlyNumbers: 'Please use only numbers',
			max: "The amount can't be more than",
			min: 'Please enter an amount greater than',
		},
		[formKeys.makePayment.isAdditionalPayment]: {
			required: 'Please select one of the choices below',
		},
		paymentMethod: {
			required: 'Please select one payment method',
		},
	},
	selectBankAccount: {
		[formKeys.selectBankAccount.bankName]: {
			required: 'Bank name is required',
		},
		[formKeys.selectBankAccount.accountNumber]: {
			required: 'Bank account is required',
		},
	},
} as const;

export const forms = {
	bankAccount: {
		initialValues: {
			[formKeys.bankAccount.bankName]: __DEV__ ? 'Bank of US test ' : '',
			[formKeys.bankAccount.routingNumber]: __DEV__ ? '122105278' : '',
			[formKeys.bankAccount.accountNumber]: __DEV__ ? '0000000019' : '',
			[formKeys.bankAccount.confirmAccountNumber]: __DEV__ ? '0000000019' : '',
			[formKeys.bankAccount.accountType]: 'Select',
			[formKeys.bankAccount.setDefault]: false,
		},
		labels: {
			[formKeys.bankAccount.bankName]: 'BANK NAME',
			[formKeys.bankAccount.routingNumber]: 'ROUTING NUMBER',
			[formKeys.bankAccount.accountNumber]: 'ACCOUNT NUMBER',
			[formKeys.bankAccount.confirmAccountNumber]: 'CONFIRM ACCOUNT NUMBER',
			[formKeys.bankAccount.accountType]: 'ACCOUNT TYPE',
			[formKeys.bankAccount.nickname]: 'Account Nickname',
			[formKeys.bankAccount.setDefault]: 'Use this bank account to make my payments.',
		},
		placeholders: {
			[formKeys.bankAccount.bankName]: 'Bank Name',
			[formKeys.bankAccount.routingNumber]: 'Routing Number',
			[formKeys.bankAccount.accountNumber]: 'Account Number',
			[formKeys.bankAccount.confirmAccountNumber]: 'Account Number',
			[formKeys.bankAccount.accountType]: 'Select',
			[formKeys.bankAccount.nickname]: 'Account Nickname',
			[formKeys.bankAccount.setDefault]: '',
		},
		schema: Yup.object({
			[formKeys.bankAccount.bankName]: Yup.string()
				.required(customValidationMessages.bankAccount.bankName.required)
				.ensure()
				.trim()
				.lowercase(),
			[formKeys.bankAccount.routingNumber]: Yup.string()
				.required(customValidationMessages.bankAccount.routingNumber.required)
				.ensure()
				.trim()
				.lowercase()
				// TODO: Numeric only validation not working
				.matches(/[0-9]/, customValidationMessages.bankAccount[formKeys.bankAccount.routingNumber].onlyNumbers),
			[formKeys.bankAccount.accountNumber]: Yup.string()
				.required(customValidationMessages.bankAccount.accountNumber.required)
				.ensure()
				.trim()
				.lowercase()
				// TODO: Numeric only validation not working
				.matches(/[0-9]+/, customValidationMessages.bankAccount[formKeys.bankAccount.accountNumber].onlyNumbers),
			[formKeys.bankAccount.confirmAccountNumber]: Yup.string()
				.required(customValidationMessages.bankAccount.confirmAccountNumber.required)
				.ensure()
				.trim()
				.lowercase()
				// TODO: Numeric only validation not working
				.matches(/[0-9]+/, customValidationMessages.bankAccount[formKeys.bankAccount.accountNumber].onlyNumbers)
				.test('account-numbers-match', "Account numbers doesn't match", function (this: Yup.TestContext, value) {
					return (this.parent as Record<string, any>).accountNumber === value;
				}),
			[formKeys.bankAccount.accountType]: Yup.string()
				.oneOf(['Savings', 'Checking'], 'Account Type is required.')
				.required(customValidationMessages.bankAccount.accountType.required),
			[formKeys.bankAccount.nickname]: Yup.string().trim().notRequired(),
			[formKeys.bankAccount.setDefault]: Yup.boolean(),
		}),
	},
	debitCard: {
		initialValues: {
			[formKeys.debitCard.cardNumber]: '',
			[formKeys.debitCard.expiryDate]: '',
			[formKeys.debitCard.securityCode]: '',
			[formKeys.debitCard.zipCode]: '',
		},
		labels: {
			[formKeys.debitCard.cardNumber]: 'CARD NUMBER',
			[formKeys.debitCard.expiryDate]: 'MM/YY',
			[formKeys.debitCard.securityCode]: 'CVV',
			[formKeys.debitCard.zipCode]: 'ZIP CODE',
			[formKeys.debitCard.nickname]: 'Account Nickname',
		},
		placeholders: {
			[formKeys.debitCard.cardNumber]: 'Card Number',
			[formKeys.debitCard.expiryDate]: 'MM/YY',
			[formKeys.debitCard.securityCode]: 'CVV',
			[formKeys.debitCard.zipCode]: 'ZIP',
			[formKeys.debitCard.nickname]: 'Account Nickname',
		},
		schema: Yup.object({
			[formKeys.debitCard.cardNumber]: Yup.string()
				.required(customValidationMessages.debitCard[formKeys.debitCard.cardNumber].required)
				.ensure()
				.trim()
				.lowercase(),
			[formKeys.debitCard.expiryDate]: Yup.string()
				.required(customValidationMessages.debitCard[formKeys.debitCard.expiryDate].required)
				.ensure()
				.trim()
				.lowercase()
				.matches(/^(0[1-9]|1[012])[-/][0-9]{2}$/, customValidationMessages.debitCard[formKeys.debitCard.expiryDate].format)
				.test(
					formKeys.debitCard.expiryDate,
					customValidationMessages.debitCard[formKeys.debitCard.expiryDate].format,
					function (this: Yup.TestContext, expiryDate: string) {
						if (!expiryDate) return false;
						return dayjs(expiryDate, 'MM-YY').isAfter(dayjs());
					},
				),
			[formKeys.debitCard.securityCode]: Yup.string()
				.required(customValidationMessages.debitCard[formKeys.debitCard.securityCode].required)
				.ensure()
				.trim()
				.lowercase()
				.min(3, customValidationMessages.debitCard[formKeys.debitCard.securityCode].min)
				.max(4, customValidationMessages.debitCard[formKeys.debitCard.securityCode].max),
			//TODO: validation deleted information debit card
			// [formKeys.debitCard.zipCode]: Yup.string()
			// 	.required(customValidationMessages.debitCard[formKeys.debitCard.zipCode].required)
			// 	.ensure()
			// 	.trim()
			// 	.lowercase(),
			[formKeys.debitCard.nickname]: Yup.string().trim().notRequired(),
		}),
	},
	billingInformation: {
		initialValues: {
			[formKeys.billingInformation.firstName]: __DEV__ ? 'John' : '',
			[formKeys.billingInformation.lastName]: __DEV__ ? 'Smith' : '',
			[formKeys.billingInformation.phoneNumber]: __DEV__ ? '2901709325' : '',
			[formKeys.billingInformation.line1Address]: __DEV__ ? '220 LOCUS AVE' : '',
			[formKeys.billingInformation.line2Address]: __DEV__ ? '' : '',
			[formKeys.billingInformation.city]: __DEV__ ? 'Atlanta' : '',
			[formKeys.billingInformation.state]: __DEV__ ? 'GA' : '',
			[formKeys.billingInformation.zipCode]: __DEV__ ? '30318' : '',
		},
		labels: {
			[formKeys.billingInformation.firstName]: 'FIRST NAME',
			[formKeys.billingInformation.lastName]: 'LAST NAME',
			[formKeys.billingInformation.phoneNumber]: 'PHONE NUMBER',
			[formKeys.billingInformation.line1Address]: 'ADDRESS LINE 1',
			[formKeys.billingInformation.line2Address]: 'ADDRESS LINE 2',
			[formKeys.billingInformation.city]: 'CITY',
			[formKeys.billingInformation.state]: 'STATE',
			[formKeys.billingInformation.zipCode]: 'ZIP CODE',
		},
		placeholders: {
			[formKeys.billingInformation.firstName]: 'Name',
			[formKeys.billingInformation.lastName]: 'Last Name',
			[formKeys.billingInformation.phoneNumber]: 'Phone Number',
			[formKeys.billingInformation.line1Address]: 'Address Line 1',
			[formKeys.billingInformation.line2Address]: 'Address Line 2',
			[formKeys.billingInformation.city]: 'City',
			[formKeys.billingInformation.state]: 'Select',
			[formKeys.billingInformation.zipCode]: 'ZIP',
		},
		schema: Yup.object({
			[formKeys.billingInformation.firstName]: Yup.string()
				.required(customValidationMessages.billingInformation[formKeys.billingInformation.firstName].required)
				.ensure()
				.trim()
				.lowercase(),
			[formKeys.billingInformation.lastName]: Yup.string()
				.required(customValidationMessages.billingInformation[formKeys.billingInformation.lastName].required)
				.ensure()
				.trim()
				.lowercase(),
			[formKeys.billingInformation.phoneNumber]: Yup.string()
				.required(customValidationMessages.billingInformation[formKeys.billingInformation.phoneNumber].required)
				.ensure()
				.trim()
				.lowercase(),
			[formKeys.billingInformation.line1Address]: Yup.string()
				.required(customValidationMessages.billingInformation[formKeys.billingInformation.line1Address].required)
				.ensure()
				.trim()
				.lowercase(),
			[formKeys.billingInformation.line2Address]: Yup.string().notRequired().ensure().trim().lowercase(),
			[formKeys.billingInformation.city]: Yup.string()
				.required(customValidationMessages.billingInformation[formKeys.billingInformation.city].required)
				.ensure()
				.trim()
				.lowercase(),
			// TODO: validate validation logic
			[formKeys.billingInformation.state]: Yup.string()
				.required(customValidationMessages.billingInformation[formKeys.billingInformation.state].required)
				.ensure()
				.trim()
				.length(2)
				.uppercase(),
			// TODO: state is required in schema validation billing information?
			// .oneOf(stateValues),
			[formKeys.billingInformation.zipCode]: Yup.string()
				.required(customValidationMessages.billingInformation[formKeys.billingInformation.zipCode].required)
				.ensure()
				.trim()
				.lowercase(),
		}),
	},
	makePayment: {
		initialValues: {
			[formKeys.makePayment.amount]: 0,
			[formKeys.makePayment.otherAmount]: 0,
		},
		schema: Yup.object({
			[formKeys.makePayment.amountType]: Yup.mixed<PaymentAmountType>().oneOf(Object.values(PaymentAmountType as {})),
			[formKeys.makePayment.amount]: Yup.number().required(
				customValidationMessages.makePayment[formKeys.makePayment.amount].required,
			),
			[formKeys.makePayment.otherAmount]: Yup.mixed<string | number>().when(formKeys.makePayment.amountType, {
				is: PaymentAmountType.OTHER_AMOUNT,
				then: Yup.number()
					.required(customValidationMessages.makePayment[formKeys.makePayment.amount].required)
					.typeError(customValidationMessages.makePayment[formKeys.makePayment.otherAmount].onlyNumbers)
					.min(
						Yup.ref(formKeys.makePayment.minAmount),
						({ min: minAmount }) =>
							`${customValidationMessages.makePayment[formKeys.makePayment.otherAmount].min} ${format.formatMoney(minAmount, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}`,
					)
					.max(
						Yup.ref(formKeys.makePayment.maxAmount),
						({ max: maxAmount }) =>
							`${customValidationMessages.makePayment[formKeys.makePayment.otherAmount].max} ${format.formatMoney(maxAmount, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}`,
					),
				otherwise: (schema: Yup.MixedSchema) => schema.notRequired(),
			}),
			[formKeys.makePayment.isAdditionalPayment]: Yup.boolean().when(formKeys.makePayment.enableAdditionalPaymentValidation, {
				is: true,
				then: (schema: Yup.BooleanSchema) =>
					schema.required(customValidationMessages.makePayment[formKeys.makePayment.isAdditionalPayment].required),
				otherwise: (schema: Yup.BooleanSchema) => schema.notRequired(),
			}),
		}),
	},
	bankInfoNeeded: {
		initialValues: {
			[formKeys.bankAccount.bankName]: __DEV__ || process.env.NODE_ENV === 'development' ? 'Bank of US test' : '',
			[formKeys.bankAccount.routingNumber]: __DEV__ || process.env.NODE_ENV === 'development' ? '122105278' : '',
			[formKeys.bankAccount.accountNumber]: __DEV__ || process.env.NODE_ENV === 'development' ? '0000000019' : '',
			[formKeys.bankAccount.confirmAccountNumber]: __DEV__ || process.env.NODE_ENV === 'development' ? '0000000019' : '',
		},
		labels: {
			[formKeys.bankAccount.bankName]: 'Bank name',
			[formKeys.bankAccount.routingNumber]: 'Routing number',
			[formKeys.bankAccount.accountNumber]: 'Account number',
			[formKeys.bankAccount.confirmAccountNumber]: 'Repeat account number',
		},
		placeholders: {
			[formKeys.bankAccount.bankName]: '',
			[formKeys.bankAccount.routingNumber]: '',
			[formKeys.bankAccount.accountNumber]: '',
			[formKeys.bankAccount.confirmAccountNumber]: '',
			[formKeys.bankAccount.accountType]: '',
			[formKeys.bankAccount.setDefault]: '',
		},
		schema: Yup.object({
			[formKeys.bankAccount.bankName]: Yup.string()
				.required(customValidationMessages.bankAccount.bankName.required)
				.ensure()
				.trim()
				.lowercase(),
			[formKeys.bankAccount.routingNumber]: Yup.string()
				.required(customValidationMessages.bankAccount.routingNumber.required)
				.ensure()
				.trim()
				.lowercase()
				// TODO: Numeric only validation not working
				.matches(/[0-9]/, customValidationMessages.bankAccount[formKeys.bankAccount.routingNumber].onlyNumbers),
			[formKeys.bankAccount.accountNumber]: Yup.string()
				.required(customValidationMessages.bankAccount.accountNumber.required)
				.ensure()
				.trim()
				.lowercase()
				// TODO: Numeric only validation not working
				.matches(/[0-9]+/, customValidationMessages.bankAccount[formKeys.bankAccount.accountNumber].onlyNumbers),
			[formKeys.bankAccount.confirmAccountNumber]: Yup.string()
				.required(customValidationMessages.bankAccount.confirmAccountNumber.required)
				.ensure()
				.trim()
				.lowercase()
				// TODO: Numeric only validation not working
				.matches(/[0-9]+/, customValidationMessages.bankAccount[formKeys.bankAccount.accountNumber].onlyNumbers)
				.test('account-numbers-match', "Account numbers doesn't match", function (this: Yup.TestContext, value) {
					return (this.parent as Record<string, any>).accountNumber === value;
				}),
		}),
	},
	selectAccount: {
		initialValues: {
			[formKeys.bankAccount.bankName]: __DEV__ || process.env.NODE_ENV === 'development' ? 'Bank of US test ' : '',
			[formKeys.bankAccount.accountNumber]: '',
		},
		labels: {
			[formKeys.bankAccount.bankName]: 'Bank name',
			[formKeys.bankAccount.accountNumber]: 'Bank Account',
		},
		schema: Yup.object({
			[formKeys.bankAccount.bankName]: Yup.string()
				.required(customValidationMessages.selectBankAccount.bankName.required)
				.ensure()
				.trim()
				.lowercase(),
			[formKeys.bankAccount.accountNumber]: Yup.string()
				.required(customValidationMessages.selectBankAccount.accountNumber.required)
				.ensure()
				.trim()
				.lowercase(),
		}),
	},
	editPaymentMethodNickname: {
		placeholders: {
			[formKeys.editPaymentMethodNickname.nickname]: 'Account Nickname',
		},
		labels: {
			[formKeys.editPaymentMethodNickname.nickname]: 'Account Nickname',
		},
		schema: Yup.object({
			[formKeys.editPaymentMethodNickname.nickname]: Yup.string().trim(),
		}),
	},
};

export const autopayConsentTerms = {
	[PaymentMethodTypeKeys.DEBIT_CARD]: `By selecting the Debit Card option, you authorize us, our agents, and our successors and assigns to initiate debits to the Debit Card you provided to us (“Card”) to process your scheduled payments under the terms of this Agreement.  If necessary, you also authorize initiating debits and credits to correct any erroneous payment.  The debit will appear on your deposit account statement.
We will initiate each debit on or after each applicable Due Date set forth in the Payment Schedule (“Due Date”).  If the Due Date occurs on a weekend or holiday, the debit will occur on the next business day.  The amount of any scheduled debit may be increased by any additional fees and/or charges you have incurred under this Agreement, including but not limited to any Late Charge and/or Returned Payment Fee.  If you make any partial prepayments, then you authorize us to vary the amount of the scheduled debit as needed to account for those partial prepayments   We will make no more than two (2) attempts to debit any single scheduled payment to your Card.  This Debit Card authorization will remain in full force and effect until we have received written notification from you of its termination in such time and in such manner as to afford us and the depository institution a reasonable opportunity to act on it.  A termination request will not apply to a scheduled debit unless we receive the request at least three (3) business days before the scheduled debit. You may send written notification by e-mail to customerservice@lendingpoint.com or by mail to LendingPoint, Attn: Customer Service, 1201 Roberts Blvd, Suite 200, Kennesaw, GA 30144.
`,
	[PaymentMethodTypeKeys.BANK_ACCOUNT]: `You represent and warrant that you are named as an account holder on Your Bank Account and that you have the right to withdraw funds from Your Bank Account.
You authorize us to re-initiate such ACH debit a total of two additional times (if necessary) for the same amount if the ACH debit is dishonored. You acknowledge that the origination of an electronic debit to Your Bank Account must comply with the provisions of U.S. law.  You agree that you are responsible for maintaining funds in Your Bank Account sufficient to satisfy each scheduled payment when it becomes due, and you waive any claim you may have against us arising from your failure to do so.
Your Bank Account Information. If there is any missing or erroneous information regarding Your Bank Account then you authorize us to verify and correct the information. You acknowledge, warrant, and represent that Your Bank Account is a legitimate, open, and active account into which your regular employment, benefit, and/or other income is currently deposited and that you have the right to initiate (and to authorize us to initiate) electronic debits from Your Bank Account.
Dates. You acknowledge that this authorization is an authorization to initiate an ACH debit to debit Your Bank Account on or after each Due Date set forth in the Loan Agreement or as changed thereafter.  If the above noted Due Date falls on a weekend or holiday, you authorize us to execute the payment on the next business day.
Range of Debits and Notice of Variation. Our debits to Your Bank Account will be in an amount between the amount of the scheduled payment and the total amount of any scheduled payments due and past due at the time we initiate the debit, plus any additional charges and/or fees incurred under the Loan Agreement, or a lesser amount owing based on your transaction history. We will not debit your account for more than the total amount of scheduled payments due and past due at the time we initiate a debit (plus additional charges and/or fees, as applicable). If a debit amount will fall outside of the specified range, then we will email you notification of the amount of the debit and the date on or after which we will debit such amount, at least ten calendar days before the scheduled debit.
Partial Prepayments. If you make any partial prepayments, then you authorize us to vary the amount of the electronic debit as needed to reflect those partial prepayments.
Additional Amounts. You further authorize us to initiate separate electronic debits to Your Bank Account for any applicable Late Charge(s), Returned Payment Fee(s), and/or other fees or costs provided in this Loan Agreement.
Depository Institution Charges. You agree that you are solely responsible for any and all charges imposed on you by your depository institution in connection with payments processed and/or attempted under this authorization, including but not limited to charges related to insufficient funds in Your Bank Account.
Termination. You understand and acknowledge that you may terminate this authorization by notifying us in writing.  This authorization will remain in full force and effect until we have received written notification from you of its termination at least fifteen (15) business days prior to the next billing date. You may send written notification by e-mail to customerservice@lendingpoint.com or by mail to LendingPoint, Attn: Collections, 1201 Roberts Blvd, Suite 200, Kennesaw, GA 30144.
Error Correction. In the event we make an error in processing any payment, you authorize us to initiate a payment to or from Your Bank Account to correct the error.
PLEASE NOTE THAT YOU ARE NOT REQUIRED TO SELECT ACH DEBIT AUTHORIZATION. ACH DEBIT AUTHORIZATION IS FOR YOUR CONVENIENCE IN PAYING. BY SELECTING THE ACH DEBIT OPTION, YOU ACKNOWLEDGE THAT YOU HAVE BEEN PROVIDED AN ALTERNATIVE PAYMENT METHOD AND THAT YOU HAVE VOLUNTARILY CHOSEN TO PAY ELECTRONICALLY.
`,
};
