import { BankAccountState, DebitCardState } from '@cp/common/modules/bank-account/utils';
import { PayloadAction } from '@reduxjs/toolkit';

export const saveBankAccountInfoPending = (state: BankAccountState) => {
	state.saveBankAccount.loadingStatus = 'loading';
	state.saveBankAccount.data = '';
	state.saveBankAccount.error = undefined;
};

export const saveBankAccountInfoFulfilled = (state: BankAccountState, action: PayloadAction<any>) => {
	state.saveBankAccount.data = action.payload.response;
	state.saveBankAccount.loadingStatus = 'loaded';
};

export const saveBankAccountInfoRejected = (state: BankAccountState, action: any) => {
	state.saveBankAccount.loadingStatus = 'error';
	state.saveBankAccount.error = action.payload || action.error;
};

export const getBankAccountInfoPending = (state: BankAccountState) => {
	state.bankAccount.loadingStatus = 'loading';
	state.bankAccount.data = undefined;
	state.bankAccount.error = undefined;
};

export const getBankAccountInfoFulfilled = (state: BankAccountState, action: PayloadAction<any>) => {
	state.bankAccount.data = action.payload.response;
	state.bankAccount.loadingStatus = 'loaded';
};

export const getBankAccountInfoRejected = (state: BankAccountState, action: any) => {
	state.bankAccount.loadingStatus = 'error';
	state.bankAccount.error = action.payload || action.error;
};

//debit card
export const saveDebitCardInfoPending = (state: DebitCardState) => {
	state.saveDebitCards.loadingStatus = 'loading';
	state.saveDebitCards.data = '';
	state.saveDebitCards.error = undefined;
};

export const saveDebitCardInfoFulfilled = (state: DebitCardState, action: PayloadAction<any>) => {
	state.saveDebitCards.data = action.payload.response;
	state.saveDebitCards.loadingStatus = 'loaded';
};

export const saveDebitCardInfoRejected = (state: DebitCardState, action: any) => {
	state.saveDebitCards.loadingStatus = 'error';
	state.saveDebitCards.error = action.payload || action.error;
};
