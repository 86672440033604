import shared from '../../../shared';
import { NAME } from '../../constants';

export default shared.helpers.createActionTypes(NAME, [
	'PAYMENT_METHODS.UPDATE',
	'PAYMENT_METHODS.ATTEMPT',
	'PAYMENT_METHODS.SUCCESS',
	'PAYMENT_METHODS.FAILURE',
	'PAYMENT_METHODS.DETAIL',
	'SCHEDULED_PAYMENTS.UPDATE',
	'SCHEDULED_PAYMENTS.ATTEMPT',
	'SCHEDULED_PAYMENTS.SUCCESS',
	'SCHEDULED_PAYMENTS.FAILURE',
	'SUMMARY.UPDATE',
	'SUMMARY.RESET',
	'PAYMENTS_HISTORY.UPDATE',
	'MISSED.SET',
	'MISSED.SHOWN',
	'PAYMENT_ENROLLAUTOPAY.SET',
	'PAYMENT_ENROLLAUTOPAY.CLEAN',
]);
