import * as Yup from 'yup';

export const NAME = 'support';
export { default as messages } from './messages';
export { default as messagesWeb } from './messagesWeb';

export const forms = {
	emailComposer: {
		initialValues: {
			subject: '',
			loan: '',
			message: '',
		},
		labels: {
			subject: '',
			loan: '',
			message: '',
		},
		placeholders: {
			subject: 'Subject',
			loan: 'Select loan',
			message: 'Write your message',
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		schema: (ctx?: { loansIds: string[] }) =>
			Yup.object({
				subject: Yup.string().required('Subject is a required field').ensure().trim().lowercase(),
				loan: Yup.string()
					// .oneOf(ctx.loansIds)
					.required(),
				message: Yup.string().required('Message is a required field').ensure().trim(),
			}),
	},
};

export const formsWeb = {
	emailComposer: {
		initialValues: {
			subject: '',
			loan: '',
			message: '',
		},
		labels: {
			subject: 'How can we help?',
			loan: '',
			message: '',
		},
		placeholders: {
			subject: 'Subject',
			loan: 'Select loan',
			message: 'Write your message',
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		schema: (ctx?: { loansIds: string[] }) =>
			Yup.object({
				subject: Yup.string().required('Subject is a required field').ensure().trim().lowercase(),
				message: Yup.string().required('Message is a required field').ensure().trim(),
			}),
	},
};
