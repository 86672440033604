import * as Sentry from '@sentry/react';
import config from '@cp/common/config';
import { forms } from '@cp/common/auth/constants';
import { getMappedScreenName } from '@cp/common/navigation/constants';
import AsyncStorage from '@react-native-community/async-storage';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import { Integrations } from '@sentry/tracing';

import type { ApiResponse } from '@cp/common/services/utils';
import type { LoginResponse } from '@cp/common/auth/utils';
import type { BIOMETRY_TYPE } from 'react-native-keychain';

Sentry.init({
	dsn: config.sentry.dsn,
	integrations: [new Integrations.BrowserTracing()],
	release: config.sentry.release,
	environment: config.sentry.environment,
	tracesSampleRate: 1.0,
});

let currentUser: Sentry.User;

export const IDENTITY_USER_KEY = 'identity.user';

/**
 * @description custom breadcrumb to log auth in sentry
 * @param email
 * @param authType
 * @param result
 */
export const addSignInBreadcrumb = (
	email: string,
	authType?: BIOMETRY_TYPE | null | 'normal' | 'octp',
	result?: ApiResponse<LoginResponse>,
) => {
	try {
		if (email) {
			Sentry.addBreadcrumb({
				category: 'auth',
				message: 'Authenticated user ' + email,
				data: {
					authType,
					uuid: result?.response?.uuid,
					...(result?.response?.scope || {}),
				},
				level: Sentry.Severity.Info,
			});
		}
	} catch (error) {
		console.error(error);
	}
};
/**
 * @description add tracking navigation
 * @param currentRouteName
 * @param previousRouteName
 */
export const addNavigationBreadcrumb = (
	currentRouteName: string | undefined | null,
	previousRouteName: string | null,
	params?: object,
) => {
	try {
		const _currentRouteName = getMappedScreenName(currentRouteName);
		const _previousRouteName = getMappedScreenName(previousRouteName);
		if (_currentRouteName) {
			Sentry.addBreadcrumb({
				category: 'navigation',
				data: {
					to: _currentRouteName,
					from: _previousRouteName,
					params,
				},
				level: Sentry.Severity.Info,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const addSignOutBreadcrumb = (isAutoLogout?: boolean) => {
	try {
		Sentry.addBreadcrumb({
			category: 'auth',
			message: !isAutoLogout ? 'User sign out' : 'Trigger autologout',
			level: Sentry.Severity.Info,
		});
	} catch (error) {
		console.error(error);
	}
};

export const saveUserInfo = (user: Sentry.User) =>
	AsyncStorage.setItem(IDENTITY_USER_KEY, JSON.stringify(user))
		.then(() => {
			currentUser = user;
		})
		.catch((error) => {
			console.error(error);
			Sentry.captureException(error);
		});

export const clearUserInfo = () =>
	AsyncStorage.removeItem(IDENTITY_USER_KEY)
		.then(() => {
			Sentry.setUser(null);
		})
		.catch((error) => {
			console.error(error);
			Sentry.captureException(error);
		});

export const getCurrentUser = (): Promise<Sentry.User | undefined> =>
	AsyncStorage.getItem(IDENTITY_USER_KEY)
		.then((data) => {
			if (isString(data)) {
				currentUser = JSON.parse(data) as Sentry.User;
				if (isPlainObject(currentUser) && currentUser.email) {
					Sentry.setUser(currentUser);
					return currentUser;
				}
			}
		})
		.catch((error) => {
			console.error(error);
			Sentry.captureException(error);
			return undefined;
		});

export const setIdentityUser = async (email: string, result?: ApiResponse<LoginResponse>) => {
	try {
		const success = result?.success;
		const isValidEmail = await ((!!email && success) ||
			forms.login.schema
				.validateAt('email', { email, password: '' })
				.then(() => true)
				.catch(() => false));
		if (isValidEmail) {
			const user = {
				id: result?.response?.uuid,
				username: result?.response?.scope
					? `${result.response.scope.firstName as string} ${result.response.scope.lastName as string}`
					: undefined,
				email,
			};
			Sentry.setUser(user);
			if (JSON.stringify(user) !== JSON.stringify(currentUser)) void saveUserInfo(user);
		} else {
			void clearUserInfo();
		}
		return isValidEmail;
	} catch (error) {
		console.error(error);
	}
};

export * from '@sentry/react';
