import * as React from 'react';
import { MaskService, TextInputMaskOptionProp, TextInputMaskTypeProp } from 'react-native-masked-text';
import memoize from '@formatjs/fast-memoize';
import dayjs, { OpUnitType } from 'dayjs';
import { IntlMessageFormat } from 'intl-messageformat';
import { NumberFormat, parsePhoneNumberFromString } from 'libphonenumber-js';
import { isInteger } from 'lodash';
import isString from 'lodash/isString';

import type { LoanDetailsType } from '../../loans/utils';
import type { PaymentMethodType } from '../../payments/utils';
import type { MessageDescriptorOptions } from '../utils';

import { DEFAULT_LOCALE } from '../constants';

/**
 * @description Intl formarters to speed up
 */
export const formatters = {
	getNumberFormat: memoize((locale = DEFAULT_LOCALE, opts: Intl.NumberFormatOptions) => new Intl.NumberFormat(locale, opts)),
	getDateTimeFormat: memoize(
		(locale = DEFAULT_LOCALE, opts: Intl.DateTimeFormatOptions) => new Intl.DateTimeFormat(locale, { timeZone: 'UTC', ...opts }),
	),
	getPluralRules: memoize((locale = DEFAULT_LOCALE, opts: Intl.PluralRulesOptions) => new Intl.PluralRules(locale, opts)),
};
/**
 *
 * @param message AST or string with ICU message sintax
 * @param value Object with values required by formatter
 */
export const formatMessage = <T, P>(message: MessageDescriptorOptions<T, P>, value: T) => {
	const nodes = new IntlMessageFormat(message.defaultMessage as string, DEFAULT_LOCALE, undefined, {
		formatters,
	}).format<T>(value as Record<string, any>);
	return (
		Array.isArray(nodes)
			? React.Children.toArray(
					nodes.map((child) => (typeof child === 'function' ? (child() as string | React.ReactElement<any, any>) : child)),
			  )
			: nodes
	) as P extends void ? string | string[] | React.ReactElement[] : P;
};

export const formatCardNumber = (cardNumber: string) => cardNumber?.slice(-8)?.replace('XXXX', '•••');

export const formatBankAccountNumber = (accountingNumber: string) => `•••${accountingNumber ? accountingNumber?.slice(-4) : ''}`; //

export const getLoanAutopayPaymentMethod = (loan: LoanDetailsType) =>
	loan.isAch
		? {
				number: formatBankAccountNumber(loan.autopayBankAccount ?? 'xxxxxxxxx'),
				name: loan.withdrawalBankName,
		  }
		: {
				number: formatCardNumber(loan.autopayDebitAccount ?? 'xxxxxxxxx'),
				name: 'Debit card',
		  };

export const formatPaymentMethodNumber = (method: PaymentMethodType | undefined) => {
	if (!method) {
		return '•••';
	}
	return method.isACH ? formatBankAccountNumber(method.accountingNumber) : formatCardNumber(method.cardNumber);
};

/**
 * Format phone number
 * @param phoneNumber (888) 969-0959 or +18889690959
 * @param format NATIONAL (default)
 */
export const formatPhone = memoize((phoneNumber: string | undefined, format: NumberFormat = 'NATIONAL'): string => {
	if (!isString(phoneNumber)) return '';
	const phone = parsePhoneNumberFromString(phoneNumber, 'US');
	return phone?.format(format) || '';
});

/**
 * format string as number ex: 2000 = 2,000
 * @param value string the value for format
 * @param fractions 0 to 20
 * @param style string decimal | currency | percent
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
 */
export const formatNumber = (value: string, fractions = 0, style = 'currency'): string => {
	if (value === '' || value === undefined) {
		return value;
	}
	if (!(style in { decimal: true, currency: true, percent: true })) {
		return value.toString();
	}
	const safeValue = value.replace ? Number(value.replace(/[^0-9-.]+/, '')) : value;
	return formatters
		.getNumberFormat(DEFAULT_LOCALE, {
			style,
			currency: 'USD',
			minimumFractionDigits: fractions,
		})
		.format(safeValue as number);
};

/**
 *
 * @param value string any valid date representation
 * @param format string any valid moment format
 * @see https://momentjs.com/docs/#/displaying/
 */
export const formatDate = (value: dayjs.ConfigType | undefined, format: string = 'MM/DD/YYYY'): string =>
	dayjs(value).format(format);

/**
 *
 * @param date date to handle
 * @param timeToSubtract number of time to subtract
 * @param unit time unit
 * @param format string any valid moment format
 * @see https://day.js.org/docs/en/manipulate/subtract
 */
export const subtractTime = (date: dayjs.ConfigType, timeToSubtract: number, unit: OpUnitType, format: string = 'MM/DD/YYYY') =>
	dayjs(date).subtract(timeToSubtract, unit).format(format);

/**
 *
 * @param date date to handle
 * @param timeToSubtract number of time to add
 * @param unit time unit
 * @param format string any valid moment format
 * @see https://day.js.org/docs/en/manipulate/add
 */
export const addTime = (date: dayjs.ConfigType, timeToAdd: number, unit: OpUnitType, format: string = 'MM/DD/YYYY'): string =>
	dayjs(date).add(timeToAdd, unit).format(format);

export const formatMoney = (value: number, config?: Intl.NumberFormatOptions) => {
	const options: Intl.NumberFormatOptions = {
		style: 'currency',
		currency: 'USD',
		...config,
	};
	// ! if its a whole, dollar amount, leave off the .00
	if (value % 100 === 0) options.minimumFractionDigits = 2;
	//const formatter = new Intl.NumberFormat('en-US', options);
	const formatter = formatters.getNumberFormat(DEFAULT_LOCALE, options);
	return formatter.format(value);
};

export const formatPercent = (value: number, options?: Omit<Intl.NumberFormatOptions, 'style'>) =>
	formatters
		.getNumberFormat(DEFAULT_LOCALE, {
			style: 'percent',
			minimumFractionDigits: 2,
			...options,
		})
		.format(value / 100);

export const formatCustom = (mask: TextInputMaskTypeProp, value: number | string, options: TextInputMaskOptionProp) =>
	MaskService.toMask(mask, `${value}`, options);

export const formatCapitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export { DEFAULT_LOCALE };

export const unFormatPhoneNumber = (phoneNumber: string) => {
	const clearValue = phoneNumber.replace(/[\s()-]/g, '');
	if (isInteger(Number(clearValue)) && clearValue.length === 10) return clearValue;
	return phoneNumber;
};

export const formatTimeZoneNoon = (date: string) => {
	const dateWithHoursUTC = new Date(`${date}T12:00:00Z`);
	return dateWithHoursUTC.toISOString().slice(0, -5) + 'Z';
};
