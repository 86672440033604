import axios from 'axios';
import axiosRetry from 'axios-retry';

import config from '@cp/common/config';
// constants
import { isWeb } from '@cp/common/platform';
import { emitCustomEvent } from '@cp/common/utils/events';

// helpers
import { getAppVersionHeader, handleRejectedHttpErrors } from '../helpers';

const {
	api: {
		host,
		paths: { consumer: consumerPath },
	},
} = config;

const consumer = axios.create({
	baseURL: host + consumerPath,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
		...getAppVersionHeader(),
	},
});

/**
 * Retries request if failed, check options: https://www.npmjs.com/package/axios-retry#options
 */
axiosRetry(consumer, { retries: 0 });

/**
 * API RESPONSE INTERCEPTOR
 */
consumer.interceptors.response.use((response) => {
	if (isWeb) emitCustomEvent('httpResponse', response);
	if (response.status === 204) {
		return { ...response, data: 'Successful update' };
	}
	return response;
}, handleRejectedHttpErrors);

export default consumer;
