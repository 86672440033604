import type { RootState } from '@cp/common/store';

import { NAME } from '../../constants';
import * as fromDeviceInfo from './deviceInfo';
import * as forceUpdate from './forceUpdate.selector';

export * from './configOverrides.selector';
export * from './inactivity.selector';
export * from './navigationHistory.selector';
export * from './remoteConfig.selector';

export const getIsNetConnected = (state: RootState) => fromDeviceInfo.getIsNetConnected(state[NAME].device);

export const getBiometricAuthPermission = (state: RootState) => fromDeviceInfo.getBiometricAuthPermission(state[NAME].device);

export const getIsBiometricAuthSupported = (state: RootState) => fromDeviceInfo.getIsBiometricAuthSupported(state[NAME].device);
export const getIsBioAuthPromptRejected = (state: RootState) => fromDeviceInfo.getIsBioAuthPromptRejected(state[NAME].device);

export const getModalShown = (state: RootState) => fromDeviceInfo.getModalShown(state[NAME].device);

export const getForceUpdate = (state: RootState) => forceUpdate.forceUpdateSelector(state[NAME].forceUpdate);

export const getShouldAppUpdate = (state: RootState) => forceUpdate.getShouldAppUpdate(state[NAME].forceUpdate);

export const getIsSoftUpdate = (state: RootState) => forceUpdate.getIsForceUpdate(state[NAME].forceUpdate);
