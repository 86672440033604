import axios from 'axios';
import axiosRetry from 'axios-retry';

import config from '@cp/common/config';
// constants
import { isWeb } from '@cp/common/platform';
import { emitCustomEvent } from '@cp/common/utils/events';

import { handleRejectedHttpErrors } from '../modules/lendingpoint/helpers';

// helpers

const {
	api: { middlewareUrl },
} = config;

const middleware = axios.create({
	baseURL: `${middlewareUrl}/apply/api`,
	headers: {
		'Content-Type': 'application/json',
		Authorization: `${config.keyAuthMiddleware}`,
		Accept: 'application/json',
	},
});

/**
 * Retries request if failed, check options: https://www.npmjs.com/package/axios-retry#options
 */
axiosRetry(middleware, { retries: 2 });

/**
 * API RESPONSE INTERCEPTOR
 */
middleware.interceptors.response.use((response) => {
	if (isWeb) emitCustomEvent('httpResponse', response);
	if (response.status === 204) {
		return { ...response, data: 'Successful update' };
	}
	return response;
}, handleRejectedHttpErrors);

export default middleware;
