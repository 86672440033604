/* eslint-disable indent */
import { nanoid } from '@reduxjs/toolkit';
import { Sentry } from '@cp/common/modules/shared/services';
import { isNative, isWeb } from '@cp/common/platform';
import * as DeviceInfo from '@cp/common/shared/services/DeviceInfo';
import * as PushNotifications from '@cp/common/shared/services/PushNotifications';
import * as crypto from '@cp/common/utils/crypto';
import { emitCustomEvent } from '@cp/common/utils/events';

let appHeader: Record<string, string> | undefined;
let correlationId: string | undefined | null;

export const createCorrelationId = (): string => {
	try {
		const id = crypto.createID();
		const cpCorrelationId = `CP-${id}`;
		setCorrelationId(cpCorrelationId);
		return cpCorrelationId;
	} catch (error) {
		console.error('Error at try to create correlation id', error);
		Sentry.captureException(error, (scope) => {
			scope.setTag('errorType', 'extraHeaders');
			return scope;
		});
		// use another nano id
		return nanoid(21);
	}
};

export const setCorrelationId = (id: string | null | undefined) => {
	if (id !== correlationId)
		if (isWeb) {
			emitCustomEvent('cp-correlation-id', { correlationId: id });
		}
	correlationId = id;
};

export const getCorrelationId = () => correlationId;

export const getCorrelationIdHeader = (id?: string) => ({
	'lp-correlation-id': id,
});

export const getAppVersionHeader = () => {
	if (appHeader) return appHeader;
	if (!isNative) return (appHeader = {});
	appHeader = {
		'lp-app-version': DeviceInfo.getAppTagVersion(),
	};
	return appHeader;
};

export const getPushNotificationsHeaders = async () => {
	try {
		return isNative
			? {
					'lp-device-token': await PushNotifications.registerForPush(),
					'lp-device-info': await DeviceInfo.getDeviceInfoForPush(),
			  }
			: undefined;
	} catch (error) {
		return undefined;
	}
};
