import { createDraftSafeSelector, createSelector } from '@reduxjs/toolkit';
import { isNumber } from 'lodash';

import type { RootState } from '@cp/common/store';
// type
import type { Loan } from '../../utils';

import { LoanQuickLinkTypes, NAME } from '../../constants';
import { isEbaySubSource } from '../../helpers';
import { loansCompletedLoansAdapter } from '../reducers/completedLoans.adapter';
import {
	loansAdapter,
	loansDetailsAdapter,
	// loansVirtucalCardsAdapter,
	// virtualCardsDetailsAdapter,
} from '../reducers/loans.adapters';
import { loanQuickLinkAdapter } from '../reducers/quickLinks.adapter';

const rootStateSelector = (state: RootState) => state;

export const loanQuickLinksStateSelector = (state: RootState) => state[NAME].quickLinks;

export const loanQuickLinksSelector = loanQuickLinkAdapter.getSelectors(loanQuickLinksStateSelector);

export const loansStateSelector = (state: RootState) => state[NAME].list;
// export const loansVCStateSelector = (state: RootState) => state[NAME].virtualCards;
export const loanDetailsStateSelector = (state: RootState) => state[NAME].details;
// export const virtualCardDetailsStateSelector = (state: RootState) => state[NAME].virtualCardDetails;

export const loansSelector = loansAdapter.getSelectors(loansStateSelector);
// export const loansVirtualCardSelector = loansVirtucalCardsAdapter.getSelectors(loansVCStateSelector);

export const loanDetailsSelector = loansDetailsAdapter.getSelectors(loanDetailsStateSelector);
// export const virtualCardDetailsSelector = virtualCardsDetailsAdapter.getSelectors(virtualCardDetailsStateSelector);

export const getLoans = (state: RootState) => loansSelector.selectAll(state);
// export const getLoansVirtualCard = (state: RootState) => loansVirtualCardSelector.selectAll(state);

export const getCurrentLoanId = createDraftSafeSelector(loanDetailsStateSelector, (details) => details.currentLoanId);
// export const getCurrentVirtualCardId = createDraftSafeSelector(
// 	virtualCardDetailsStateSelector,
// 	(details) => details.currentLoanId,
// );

export const getLoanDetails = createSelector(rootStateSelector, getCurrentLoanId, (state, currentLoanId) =>
	currentLoanId ? loanDetailsSelector.selectById(state, currentLoanId) : undefined,
);
// export const getVirtualCardDetails = createSelector(rootStateSelector, getCurrentVirtualCardId, (state, currentLoanId) =>
// 	currentLoanId ? virtualCardDetailsSelector.selectById(state, currentLoanId) : undefined,
// );

export const getLoanById = createSelector(
	rootStateSelector,
	(_: any, loanId: string | undefined) => loanId,
	(state, loanId) => (loanId ? loansSelector.selectById(state, loanId) : undefined),
);

export const getLoanDetailsById = createSelector(
	rootStateSelector,
	(_: any, loanId: string | undefined) => loanId,
	(state, loanId) => (loanId ? loanDetailsSelector.selectById(state, loanId) : undefined),
);

export const getLoanByGreaterTotalMissedPayment = createSelector(getLoans, (loans) => {
	let selectedLoan: Loan | undefined;
	for (const loan of loans || []) {
		if (
			isNumber(loan.totalMissedPayments) &&
			loan.totalMissedPayments > 0 &&
			isNumber(loan.totalMissedPaymentAmount) &&
			loan.totalMissedPaymentAmount > (selectedLoan?.totalMissedPaymentAmount || 0)
		) {
			selectedLoan = loan;
		}
	}
	return selectedLoan;
});

export const hasOnlyEbayLoans = createSelector(getLoans, (loans) =>
	Array.isArray(loans) && loans?.length > 0 ? loans?.every(isEbaySubSource) : undefined,
);

export const selectNumLoans = loansSelector.selectTotal;

export const selectIsSingleLoan = (state: RootState) => selectNumLoans(state) <= 1;

export const selectIsLoadingQuickLinks = createDraftSafeSelector(
	loanQuickLinksStateSelector,
	({ loading }) => loading === 'pending',
);

export const selectIsLoadingLoans = createDraftSafeSelector(
	loansStateSelector,
	({ loading }) => loading === 'pending' || loading === undefined,
);

export const getQuickLinksById = createSelector(
	rootStateSelector,
	(_: any, loanId: string | undefined) => loanId,
	(state, loanId) =>
		loanId
			? loanQuickLinksSelector
					.selectById(state, loanId)
					?.links?.filter((quickLink) => JSON.stringify(quickLink.id) !== JSON.stringify(LoanQuickLinkTypes.FICO)) || []
			: undefined,
);

export const selectIsUnitializedLoans = createDraftSafeSelector(loansStateSelector, ({ loading }) => loading === undefined);

export const selectSellerId = createDraftSafeSelector(loansStateSelector, ({ sellerId }) => sellerId);

export const completedLoansStateSelector = (state: RootState) => state[NAME].completed;

export const loansCompletedLoansSelector = loansCompletedLoansAdapter.getSelectors(completedLoansStateSelector);

export const getCompletedLoans = (state: RootState) => loansCompletedLoansSelector.selectAll(state) || [];

export const selectIsEmptyLoans = createSelector(
	[getLoans, selectIsLoadingLoans, selectIsUnitializedLoans],
	(loans, isLoading, isUnitializedLoans) => loans.length === 0 && !isLoading && !isUnitializedLoans,
);

export const selectLoanIds = loansSelector.selectIds;

export const selectSingleLoanId = (state: RootState) => selectLoanIds(state)[0] as string | undefined;

export const selectIsLoadingLoanDetails = createDraftSafeSelector(
	loanDetailsStateSelector,
	({ loading }) => loading === 'pending' || loading === undefined,
);
