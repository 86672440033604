import { createReducer, combineReducers } from '@reduxjs/toolkit';
import * as authActions from '@cp/common/modules/auth/store/actions';

import { NotificationType } from '../../utils';

import { actions, thunks } from '../actions';

const list = createReducer(null as NotificationType[] | null, (builder) => {
	builder.addCase(actions.addNotification, (state, action) => {
		if (state) {
			const notificationIndex = state.findIndex((notification) => notification.id === action.payload.id);
			if (notificationIndex < 0) {
				return [action.payload, ...state];
			} else {
				return state.map((notification, index) => {
					if (notificationIndex === index) {
						return action.payload;
					}

					return notification;
				});
			}
		} else {
			return [action.payload];
		}
	});

	builder.addCase(thunks.getNotificationList.fulfilled, (state, action) => action.payload.response?.mails || null);

	builder.addCase(
		thunks.getNotificationDetails.fulfilled,
		(state, action) =>
			state &&
			state.map((entry) =>
				action.payload && action.payload.response && entry.id === action.payload.response.id
					? { ...entry, body: action.payload.response.body }
					: entry,
			),
	);
	builder.addCase(actions.setAsDeleted, (state, action) => state && state.filter((entry) => entry.id !== action.payload.id));

	builder.addCase(actions.setAsRead, (state, action) => {
		if (!state) return;
		state.map((entry) => {
			if (entry.id === action.payload.id) {
				entry.read = true;
			}
			return entry;
		});
	});

	builder.addMatcher(
		(action) =>
			(authActions.thunks.signIn.fulfilled.match(action) && action.payload.success) ||
			authActions.thunks.signOut.pending.match(action),
		(state) => null,
	);
});

const notificationReducer = combineReducers({
	list,
});

export default notificationReducer;
export type NotificationState = ReturnType<typeof notificationReducer>;
