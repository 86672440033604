import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';

import { getDetails } from '@cp/common/modules/loans/store/actions/thunks';
import { getOffersCatalog } from '@cp/common/modules/refinance2/services';
import * as apiVerifyIdentity from '@cp/common/modules/refinance2/services';
import { ApiResponse } from '@cp/common/services/utils';

import { FetchOffers } from './types';

import combinedRootReducer from '../../../../store/rootReducer';
import { isRefinanceEbaySubSource } from '../../helpers';
import { getDocuments, getLoanOpportunities, getOffersRefinanceNew, getTila as getTilaRefinance } from '../../services';
import { GetDocumentsResponse, RefinanceOffer, RefinanceOpportunity, Tila } from '../../utils';
export const NO_AVAILABLE_OPPORTUNITIES_ERROR_MESSAGE = 'No available opportunities';
export type RootGeneralState = ReturnType<typeof combinedRootReducer>;

export const getOffersNewrefi = createAsyncThunk(
	'offers/fetch',
	async (
		reqData: FetchOffers | any,
		{ rejectWithValue },
	): Promise<{
		success: boolean;
		disbursalAmount?: number | undefined;
		offers?: RefinanceOffer[];
		errorMessage?: string | null;
	}> => {
		const response = await getOffersRefinanceNew(reqData);
		if (response.success && response.payload) {
			const offers = response.payload;
			const offersCatalogSortedByLoanAmountAsc = offers.sort((b, a) => b.loanAmount - a.loanAmount);
			const { estimatedDepositAmount } = offersCatalogSortedByLoanAmountAsc[offersCatalogSortedByLoanAmountAsc.length - 1];
			return { success: true, offers: offersCatalogSortedByLoanAmountAsc, disbursalAmount: estimatedDepositAmount };
		}
		if (!response.success) throw rejectWithValue(response);

		return { success: false, errorMessage: response.errorMessage };
	},
);

export const getOpportunity = createAsyncThunk(
	'refinances2/getOpportunity',
	async (
		_,
		{ dispatch },
	): Promise<{
		success: boolean;
		opportunity?: RefinanceOpportunity;
		errorMessage?: string | null;
		offers?: RefinanceOffer[];
		opportunityId?: string;
	}> => {
		const { success, payload, errorMessage } = await getLoanOpportunities();
		let opportunityId: string | undefined = '';

		if (success && payload) {
			const refinanceOpportunity = payload.opportunities?.find(
				(opportunity) => !!opportunity.loanId && opportunity.type === 'Refinance' && opportunity.isRefiE2Eeligible,
			);
			opportunityId = !refinanceOpportunity
				? payload?.opportunities?.find((opportunity) => opportunity.loanStatus?.toLowerCase() === 'incomplete')?.id
				: undefined;

			if (!refinanceOpportunity) {
				const errMsg = NO_AVAILABLE_OPPORTUNITIES_ERROR_MESSAGE;
				return { success: false, errorMessage: errMsg, opportunityId };
			}

			const _isRefinanceEbaySubSource = isRefinanceEbaySubSource(refinanceOpportunity.subSource);
			if (_isRefinanceEbaySubSource) {
				await dispatch(getDetails({ loanId: refinanceOpportunity.loanId }));
				return { success: true, opportunity: refinanceOpportunity, opportunityId };
			}

			const [{ offers }] = await Promise.all([
				dispatch(getOffersNewrefi(refinanceOpportunity.id)).unwrap(),
				dispatch(getDetails({ loanId: refinanceOpportunity.loanId as string })),
			]);

			if (offers && offers.length > 0) {
				return { success: true, opportunity: refinanceOpportunity, opportunityId };
			}
		}

		return { success: false, errorMessage: errorMessage, opportunityId };
	},
);

export const getTila = createAsyncThunk('refinance/getTila', async (opportunityId: string): Promise<ApiResponse<Tila>> => {
	const { success, payload, errorMessage } = await getTilaRefinance(opportunityId);
	if (success && payload) {
		return { success: true, response: payload };
	}
	return { success: false, errorMessage: errorMessage ?? 'Thunk error' };
});

export const fetchKYCQuestions = createAsyncThunk(
	'verifyIdentity/fetch-KYC-questions',
	async ({ config }: { config?: AxiosRequestConfig }, { rejectWithValue, getState }) => {
		const { auth } = getState() as RootGeneralState;
		const { refinance } = getState() as RootGeneralState;
		const appId = refinance.opportunity.opportunity?.id as string;
		const correlationId = auth?.correlationId as string;
		const response = await apiVerifyIdentity.fetchKYCQuestions({
			appId,
			correlationId: correlationId,
			config,
		});
		if (!response.success) throw rejectWithValue(response);

		return response;
	},
);

export const saveKYCAnswers = createAsyncThunk(
	'verifyIdentity/save-KYC-answers',
	async (data: AnsweredQuestion[], { rejectWithValue, getState }) => {
		const { refinance } = getState() as RootGeneralState;
		const appId = refinance.opportunity.opportunity?.id as string;
		const response = await apiVerifyIdentity.saveKYCAnswers(data, appId + '', '');
		if (!response.success) throw rejectWithValue(response);
		return response;
	},
);

export type AnsweredQuestion = {
	questionNumber: number;
	answer: string;
	type: string;
	isSkipped: boolean;
};
export const getDocumentsThunks = createAsyncThunk(
	'refinance2/getDocuments',
	async (
		applicationId: string,
	): Promise<{
		payload?: GetDocumentsResponse;
		success: boolean;
		errorMessage?: string | null;
	}> => {
		const res = await getDocuments(applicationId);
		if (res.success && !!res.payload) {
			return { success: true, payload: res.payload };
		}
		return { success: false, errorMessage: res.errorMessage };
	},
);

export const getRefinanceOffersEbay = createAsyncThunk(
	'refinance/getRefinanceOffers',
	async ({
		opportunityId,
		subSource,
		maxRefinanceDisbursal,
		RefinanceVarianceMinPayment,
		LowerRate,
	}: {
		opportunityId: string;
		subSource: string;
		maxRefinanceDisbursal?: boolean;
		RefinanceVarianceMinPayment?: boolean;
		LowerRate?: boolean;
	}): Promise<{
		success: boolean;
		errorMessage?: string | null;
		offers?: RefinanceOffer[];
		disbursalAmount?: number;
		messagingOfferType?: string;
		experimentGroup?: string;
	}> => {
		const _isRefinanceEbaySubSource = isRefinanceEbaySubSource(subSource);

		const { payload: offersCatalog, errorMessage } = await getOffersCatalog({
			opportunityId,
			isEbay: _isRefinanceEbaySubSource,
			maxRefinanceDisbursal,
			RefinanceVarianceMinPayment,
			LowerRate,
		});

		if (offersCatalog && offersCatalog.offers.length > 0) {
			const offersCatalogSortedByLoanAmountAsc = offersCatalog.offers.sort((b, a) => b.loanAmount - a.loanAmount);
			const { disbursalAmount } = offersCatalogSortedByLoanAmountAsc[offersCatalogSortedByLoanAmountAsc.length - 1];

			return {
				success: true,
				offers: offersCatalogSortedByLoanAmountAsc,
				disbursalAmount,
				messagingOfferType: offersCatalog.messagingOfferType ?? '',
				experimentGroup: offersCatalog.experimentGroup ?? '',
			};
		}
		return { success: false, errorMessage: errorMessage };
	},
);

export const isCreditUnionOption = createAsyncThunk('refinance/isCreditUnion', (creditUnionBank: string) => {
	return creditUnionBank;
});
