import isPlainObject from 'lodash/isPlainObject';
import hasIn from 'lodash/hasIn';
import set from 'lodash/set';

import * as Sentry from '../modules/shared/services/Sentry';

import type { ApiResponse } from '../services/utils';
import type { AxiosError } from 'axios';

/**
 * when we have 400 try to get the errors.detail for using
 * for messages and modals
 * @param error any
 */
const getErrorMessage = (error: any) => {
	const defaultErrMsg = "Something didn't go right but let's try again.";
	try {
		const response = JSON.parse(error.response.request._response);
		return response.errors.detail ? response.errors.detail : defaultErrMsg;
	} catch (error) {
		return defaultErrMsg;
	}
};

export const sensitiveKeys = {
	loans: {
		details: ['apr', 'contactId', 'disbursalAmount'],
	},
	payments: ['routingNumber', 'bankId', 'accountingNumber', 'accountId', 'cardNumber', 'expiryDate'],
	profile: [
		'contactId',
		'phoneNumber',
		'annualIncome',
		'postalCode',
		'city',
		'state',
		'street',
		'annualIncome',
		'mailingCity',
		'mailingCity',
		'mailingState',
		'mailingStreet',
	],
};

export const masked = (data: Record<string, any> | undefined, maskedKeys: Array<string>) => {
	if (!data || !isPlainObject(data)) return data;
	const result = { ...data };
	for (const key of maskedKeys) {
		if (hasIn(result, key)) set(result, key, '[masked]');
	}
	return result;
};

export const handleError = (error: AxiosError): ApiResponse => {
	if (error) {
		Sentry.withScope((scope) => {
			const status = error?.response?.status || 0;
			const url = error?.config?.url;
			const headers = masked(error?.config?.headers, ['Authorization']);
			const data = url?.includes('sign') ? masked(error.response?.data, ['password']) : error?.response?.data;
			scope.setTag('errorType', 'api');
			if (status === 401 || status === 403) scope.setLevel(Sentry.Severity.Critical);
			scope.setExtras({
				baseURL: `${error?.config.baseURL}${url}`,
				headers: headers,
				data: data,
				message: error.message,
			});

			Sentry.captureException(error);
		});
	} else {
		Sentry.configureScope((scope) => {
			scope.setTag('errorType', 'unknown');
		});
		Sentry.captureMessage('NO ERROR FOUND');
	}

	return {
		success: false,
		status: error.response && error.response.status ? error.response.status : 0,
		errorMessage: getErrorMessage(error),
		error,
	};
};

export const logFirebaseErrorToSentry = (error: Error) => {
	if (!error) return;
	Sentry.captureException(error, (scope) => {
		scope.setTag('errorType', 'firebase');
		return scope;
	});
};
