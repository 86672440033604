// eslint-disable-next-line simple-import-sort/imports
import { combineReducers } from 'redux';
import get from 'lodash/get';
import set from 'lodash/set';
import hasIn from 'lodash/hasIn';

import * as shared from '@cp/common/shared/constants';
import * as loans from '@cp/common/loans/constants';
import * as payments from '@cp/common/payments/constants';
import * as profile from '@cp/common/profile/constants';
import * as auth from '@cp/common/auth/constants';
import * as notifications from '@cp/common/notifications/constants';
import * as multiLoan from '@cp/common/multi-loan/constants';
import * as plaid from '@cp/common/plaid/constants';
import * as bankAccount from '@cp/common/bank-account/constants';

import sharedReducer from '@cp/common/shared/store/reducers';
import loansReducer from '@cp/common/loans/store/reducers';
import paymentsReducer from '@cp/common/payments/store/reducers';
import profileReducer from '@cp/common/profile/store/reducers';
import authReducer from '@cp/common/auth/store/reducers';
import notificationsReducer from '@cp/common/notifications/store/reducers';
import multiLoanReducer from '@cp/common/multi-loan/store/reducers';
import plaidReducer from '@cp/common/plaid/store/reducers';
import bankAccountReducer from '@cp/common/bank-account/store/reducers';

import { signOut } from '@cp/common/auth/store/actions/thunks';
import type { PayloadAction } from '@reduxjs/toolkit';

import * as refinance2 from '../modules/refinance2/constants/index';
import refinanceReducer2 from '../modules/refinance2/store/reducers';
import * as dtcApply from '../modules/dtc-apply/constants/index';
import dtcApplyReducer from '../modules/dtc-apply/store/reducers';

const combinedRootReducer = combineReducers({
	[shared.NAME]: sharedReducer,
	[loans.NAME]: loansReducer,
	[payments.NAME]: paymentsReducer,
	[profile.NAME]: profileReducer,
	[auth.NAME]: authReducer,
	[notifications.NAME]: notificationsReducer,
	[multiLoan.NAME]: multiLoanReducer,
	[plaid.NAME]: plaidReducer,
	[bankAccount.NAME]: bankAccountReducer,
	[refinance2.NAME]: refinanceReducer2,
	[dtcApply.NAME]: dtcApplyReducer,
});

function rootReducer(state: any, action: PayloadAction) {
	if (signOut.pending.match(action)) {
		const clearState = {} as Record<string, Record<string, unknown>>;
		const clearWhitelist = ['shared.device', 'shared.navigationHistory', 'auth._persist', 'loans._persist'];

		for (const keyPath of clearWhitelist) {
			if (hasIn(state, keyPath)) set(clearState, keyPath, get(state, keyPath));
		}
		state = clearState;
	}
	return combinedRootReducer(state, action);
}

export default rootReducer;
