import { shouldPolyfill } from '@formatjs/intl-datetimeformat/should-polyfill';

if (shouldPolyfill()) {
	// Load the polyfill 1st BEFORE loading data
	require('@formatjs/intl-datetimeformat/polyfill');
}
//@ts-ignore check if apply polyfill
if (Intl.DateTimeFormat.polyfilled) {
	// Parallelize CLDR data loading
	// require('@formatjs/intl-datetimeformat/add-golden-tz');
	require('@formatjs/intl-datetimeformat/locale-data/en-US');
}
