import * as React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { getAuthData } from '@cp/common/auth/store/selectors';
import { routes } from '@cp/common/navigation/constants/routes.constants';
import { selectBypassForMaintenance, selectIsMaintenanceEnabled } from '@cp/common/shared/store/selectors';
import { useAppSelector } from '@cp/common/store/hooks';
import { messagesWeb } from '@shared/constants';

import PrivateRoute from './components/PrivateRoute.component';
import ProtectAuthRoute from './components/ProtectAuthRoute.component';

// Routers
const AuthRoutes = React.lazy(() => import('./AuthRoutes'));
const LoanRoutes = React.lazy(() => import('./LoansRoutes'));
const PaymentsRoutes = React.lazy(() => import('./Payments.routes'));
const EnrollAutopayRoutes = React.lazy(() => import('./EnrollAutopay.routes'));
const MakePaymentRoutes = React.lazy(() => import('./MakePayment.routes'));
const ProfileRoutes = React.lazy(() => import('./Profile.routes'));
const RefinanceRoutes2 = React.lazy(() => import('./Refinance2Routes'));
const MultiLoanRoutes = React.lazy(() => import('./MultiLoanRoutes'));
const OctpScreen = React.lazy(() => import('@octp/screens/Octp.screen'));
const OctapScreen = React.lazy(() => import('@octap/screens/Octap.screen'));
const TokenizedScreen = React.lazy(() => import('@tokenized/container/TokenizedController'));
const RedirectAuthScreen = React.lazy(() => import('@auth/screens/RedirectAuth'));
const AppGeneralError = React.lazy(() => import('@shared/components/AppGeneralError'));
const AppMaintence = React.lazy(() => import('@shared/components/Maintence.component'));

// TODO: improve routing with react-router-config
const RootRoutes = () => {
	const authData = useAppSelector(getAuthData);
	const location = useLocation();
	const isInMaintenanceOBJ = useAppSelector(selectIsMaintenanceEnabled);
	const bypassForMaintenanceFirebase = useAppSelector(selectBypassForMaintenance);
	const queryParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
	const getBypassForMaintenanceQueryParam = window.localStorage.getItem('bypassForMaintenanceQueryParam');

	const [isInMaintenance, setIsInMaintenance] = React.useState(
		getBypassForMaintenanceQueryParam ? false : isInMaintenanceOBJ.isActive,
	);

	const isOctp = authData.isOctp;
	const authorizationToken = authData.authorizationToken;
	const isLogged = !!authorizationToken && !isOctp;
	const bypassForMaintenanceQueryParam = queryParams.get('bypassForMaintenance');

	React.useEffect(() => {
		if (bypassForMaintenanceQueryParam === bypassForMaintenanceFirebase) {
			window.localStorage.setItem('bypassForMaintenanceQueryParam', bypassForMaintenanceQueryParam);
			setIsInMaintenance(false);
		} else {
			window.localStorage.removeItem('bypassForMaintenanceQueryParam');
			setIsInMaintenance(isInMaintenanceOBJ.isActive);
		}
	}, [bypassForMaintenanceFirebase]);

	return (
		<Switch>
			<PrivateRoute path={`/${routes.loans}`} isLogged={isLogged} isOctp={isOctp} isInMaintenance={isInMaintenance}>
				<LoanRoutes />
			</PrivateRoute>
			<PrivateRoute path={`/${routes.payments}`} isLogged={isLogged} isOctp={isOctp} isInMaintenance={isInMaintenance}>
				<PaymentsRoutes />
			</PrivateRoute>
			<PrivateRoute
				path={`/${routes.enrollInAutoPay.enrollInAutoPay}`}
				isLogged={isLogged}
				isOctp={isOctp}
				isInMaintenance={isInMaintenance}
			>
				<EnrollAutopayRoutes />
			</PrivateRoute>
			<PrivateRoute
				path={`/${routes.makePaymentRoute.initial}`}
				isLogged={isLogged}
				isOctp={isOctp}
				isInMaintenance={isInMaintenance}
			>
				<MakePaymentRoutes />
			</PrivateRoute>
			<PrivateRoute path={`/${routes.profile}`} isLogged={isLogged} isOctp={isOctp} isInMaintenance={isInMaintenance}>
				<ProfileRoutes />
			</PrivateRoute>
			<PrivateRoute
				path={`/${routes.refinanceV2.refinance}`}
				isLogged={isLogged}
				isOctp={isOctp}
				isInMaintenance={isInMaintenance}
			>
				<RefinanceRoutes2 />
			</PrivateRoute>
			<PrivateRoute path={`/${routes.multiloan}`} isLogged={isLogged} isOctp={isOctp} isInMaintenance={isInMaintenance}>
				<MultiLoanRoutes />
			</PrivateRoute>
			<ProtectAuthRoute path={`/${routes.auth}`} isLogged={isLogged} isOctp={isOctp} isInMaintenance={isInMaintenance}>
				<AuthRoutes />
			</ProtectAuthRoute>
			<ProtectAuthRoute path={'/'} isLogged={isLogged} isOctp={isOctp} exact isInMaintenance={isInMaintenance}>
				<Redirect to={`/${routes.auth}`} />
			</ProtectAuthRoute>
			{/* TODO:DELETE AND ADD TO THE DEEPLINK ROUTE */}
			<Route
				path={`/${routes.octp}/:autenticationToken/:loanId/:origin?`}
				render={() => (isInMaintenance ? <Redirect to={`/${routes.maintenance}`} /> : <OctpScreen />)}
			/>
			{/* TODO:DELETE AND ADD TO THE DEEPLINK ROUTE */}
			<Route
				path={`/${routes.octap}/:autenticationToken/:loanId/:origin?`}
				render={() => (isInMaintenance ? <Redirect to={`/${routes.maintenance}`} /> : <OctapScreen />)}
			/>
			<Route
				path={`/${routes.tokenized}`}
				render={() => (isInMaintenance ? <Redirect to={`/${routes.maintenance}`} /> : <TokenizedScreen />)}
			/>
			<Redirect from="/authentication/login" to="/" />
			<Route
				path={`/${routes.redirect}/:refreshToken`}
				render={() => (isInMaintenance ? <Redirect to={`/${routes.maintenance}`} /> : <RedirectAuthScreen />)}
			/>
			<Route
				path={`/${routes.sessionTimeout}`}
				render={() => (
					<>
						{isInMaintenance ? (
							<Redirect to={`/${routes.maintenance}`} />
						) : (
							<AppGeneralError
								isSessionTimeout={true}
								buttonText="Log in again"
								buttonRoute={`${routes.auth}`}
								isNavbarsRender
								title={messagesWeb.sessionTimeout.title}
							/>
						)}
					</>
				)}
			/>
			<Route path={`/${routes.maintenance}`} render={() => (isInMaintenance ? <AppMaintence /> : <Redirect to="/" />)} />
			<Route path={`/${routes.blockedAccount}`} render={() => <AppMaintence isTemporarilyUserBlocked />} />
			{/* Route without any path but inside Switch component is used for 404 pages */}
			{/* TODO: improve 404 page not found. */}
			<Route
				path="*"
				render={() => (isInMaintenance ? <Redirect to={`/${routes.maintenance}`} /> : <AppGeneralError isNavbarsRender />)}
			/>
		</Switch>
	);
};

export default RootRoutes;
