import * as React from 'react';
import { useIdleTimer } from 'react-idle-timer';

import { signOut } from '@cp/common/auth/store/actions/thunks';
import { getAuthData } from '@cp/common/auth/store/selectors';
// Config
import config from '@cp/common/config/shared.config';
// Constants
import { routes } from '@cp/common/navigation/constants/routes.constants';
import history from '@cp/common/navigation/navigation.web';
// Modules
import * as Sentry from '@cp/common/shared/services/Sentry';
import { useAppDispatch, useAppSelector } from '@cp/common/store/hooks';

type InactivityMonitorProps = {
	children: React.ReactNode;
};

const InactivityMonitor = ({ children }: InactivityMonitorProps) => {
	const { autoLogoutDuration, autoLogoutThrottle } = config;
	const dispatch = useAppDispatch();
	const authContext = useAppSelector(getAuthData);

	const handleOnIdle = () => {
		if (!authContext.userToken && !authContext.isOctp) return;
		if (!getRemainingTime()) {
			Sentry.addSignOutBreadcrumb(true);
			history.replace(`/${routes.sessionTimeout}`);
			return dispatch(signOut({ isAutoSignout: true }));
		} else {
			return null;
		}
	};

	const { getRemainingTime, pause, resume, reset } = useIdleTimer({
		timeout: autoLogoutDuration,
		onIdle: handleOnIdle,
		eventsThrottle: autoLogoutThrottle,
		debounce: 500,
	});

	React.useEffect(() => {
		if (!authContext.userToken && !authContext.isOctp) {
			reset();
			pause();
			return;
		}
		reset();
		resume();

		return () => {
			reset();
			pause();
		};
	}, [authContext.userToken]);

	return <React.Fragment>{children}</React.Fragment>;
};

export default InactivityMonitor;
