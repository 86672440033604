import { PayloadAction } from '@reduxjs/toolkit';
import { PLAIDState } from '../../utils';

export const fetchLinkTokenPending = (state: PLAIDState) => {
	state.loadingStatusFetchLinkToken = 'loading';
};

export const fetchLinkTokenFulfilled = (state: PLAIDState, action: PayloadAction<any>) => {
	state.dataFetchLinkToken = action.payload.response;
	state.loadingStatusFetchLinkToken = 'loaded';
};

export const fetchLinkTokenRejected = (state: PLAIDState, action: PayloadAction<any>) => {
	state.errorFetchLinkToken = action.payload.error;
	state.loadingStatusFetchLinkToken = 'error';
};

export const savePlaidPublicTokenPending = (state: PLAIDState) => {
	state.loadingStatusSavePlaidPublicToken = 'loading';
};

export const savePlaidPublicTokenFulfilled = (state: PLAIDState, action: PayloadAction<any>) => {
	state.dataSavePlaidPublicToken = action.payload.response;
	state.loadingStatusSavePlaidPublicToken = 'loaded';
};

export const savePlaidPublicTokenRejected = (state: PLAIDState, action: PayloadAction<any>) => {
	state.errorSavePlaidPublicToken = action.payload.error;
	state.loadingStatusSavePlaidPublicToken = 'error';
};
