import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import { FLUSH, purgeStoredState } from 'redux-persist';

import * as authService from '@cp/common/auth/services/auth';
import { persistConfigAuth, persistConfigLoans, persistConfigRoot } from '@cp/common/config/persist';
import { actions as profileActions } from '@cp/common/profile/store';
import { createCorrelationId } from '@cp/common/services/modules/lendingpoint/helpers';
import { ApiResponse } from '@cp/common/services/utils';
import * as Sentry from '@cp/common/shared/services/Sentry';

import type { RootState } from '@cp/common/store';
import type { AxiosError } from 'axios';
import type { AuthData, LoginParams, LoginWithPasswordlessParams, LogoutParams, UserAuthenticationData } from '../../utils';

import { getRequestAuthorizationConfig, getSafeExpireDate } from '../../helpers';

export let _token: string | null | undefined;

export const setToken = (authorizationToken: string | null | undefined) => (_token = authorizationToken);

export const signIn = createAsyncThunk(
	'auth/signIn',
	async ({ username, password, onBeforeSuccess }: LoginParams, { dispatch }) => {
		let data: AuthData | undefined;

		const result = await authService.login({ username, password });

		if (result.success) {
			const token = result.response?.authorizationToken;
			const correlationId = createCorrelationId();

			const profileResult = await dispatch(
				profileActions.thunks.getUserInfo(getRequestAuthorizationConfig({ token, correlationId })),
			).then(unwrapResult);

			if (profileResult.success && profileResult.response) {
				// get auth data
				const response = result.response;
				const scope = response?.scope;
				const expiresIn = response?.expires ? getSafeExpireDate(response.expires) : null;
				const refreshToken = response?.refreshToken;
				const userName = profileResult.response.email;
				const userId = result.response?.uuid;
				const contactId = profileResult?.response?.contactId;

				data = {
					correlationId,
					token,
					refreshToken,
					expiresIn,
					scope,
					userName,
					userId,
					contactId,
				};

				await onBeforeSuccess(data);
			} else {
				const error = new Error('Error in profile failed') as AxiosError;
				error.code = 'profile/failed';
				result.success = false;
				result.error = error;
				result.errorMessage = "Something didn't go right but let's try again.";
			}
		}
		return {
			...result,
			data,
		};
	},
	{
		condition: (_, { getState }) => {
			const { auth } = getState() as RootState;
			if (auth.loading === 'pending') {
				// Already fetched or in progress, don't need to re-fetch
				return false;
			}
		},
	},
);

export const signInWithPasswordless = createAsyncThunk(
	'auth/signIn',
	async (
		{ username, password, loginMode, otpCode, onBeforeSuccess, isSystemGeneratedPassword }: LoginWithPasswordlessParams,
		{ dispatch },
	) => {
		let data: AuthData | undefined;

		const hasOTPCode = !!otpCode;
		const isEmailMode = loginMode === 'email';
		const isPhoneMode = loginMode === 'phone';

		const result =
			hasOTPCode && typeof otpCode === 'string'
				? await authService.verifyOTPCode({
						emailId: isEmailMode ? username : undefined,
						phoneNumber: isPhoneMode ? username : undefined,
						code: otpCode,
				  })
				: await authService.login({ username, password });

		if (result.success) {
			const token = result.response?.authorizationToken;
			const correlationId = createCorrelationId();

			const profileResult = await dispatch(
				profileActions.thunks.getUserInfo(getRequestAuthorizationConfig({ token, correlationId })),
			).then(unwrapResult);

			if (profileResult.success && profileResult.response) {
				// get auth data
				const response = result.response;
				const scope = response?.scope;
				const expiresIn = response?.expires ? getSafeExpireDate(response.expires) : null;
				const refreshToken = response?.refreshToken;
				const userName = profileResult.response.email;
				const userId = result.response?.uuid;

				data = {
					correlationId,
					token,
					refreshToken,
					expiresIn,
					scope,
					userName,
					userId,
				};

				await onBeforeSuccess(data);
			} else {
				const error = new Error('Error in profile failed') as AxiosError;
				error.code = 'profile/failed';
				result.success = false;
				result.error = error;
				result.errorMessage = "Something didn't go right but let's try again.";
			}
		}
		return {
			...result,
			data,
			isSystemGeneratedPassword,
		};
	},
	{
		condition: (_, { getState }) => {
			const { auth } = getState() as RootState;
			if (auth.loading === 'pending') {
				// Already fetched or in progress, don't need to re-fetch
				return false;
			}
		},
	},
);

export const signOut = createAsyncThunk(
	'auth/signOut',
	async ({ isAuthExpired, isAutoSignout }: LogoutParams | undefined = {}, { dispatch }) => {
		try {
			let results: Array<Promise<any>> = [];
			dispatch({
				type: FLUSH,
				result: (flushResult: Promise<any>) => (results = results.concat(flushResult)),
			});

			await Promise.allSettled([
				...results,
				authService.logout(_token),
				purgeStoredState(persistConfigRoot),
				purgeStoredState(persistConfigLoans),
				purgeStoredState(persistConfigAuth),
				Sentry.clearUserInfo(),
			]);
			return {
				isAutoSignout,
				isAuthExpired,
			};
		} catch (error) {
			console.error(error);
		} finally {
			_token = undefined;
		}
	},
	{
		condition: (_, { getState }) => {
			const { auth } = getState() as RootState;
			if (auth.isAutoSignout || auth.isSignout) {
				// Already fetched or in progress, don't need to re-fetch
				return false;
			}
		},
	},
);

export const getAuthenticationUser = createAsyncThunk(
	'auth/getAuthenticationUser',
	async ({ emailOrPhone, token }: { emailOrPhone: string; token: string }): Promise<ApiResponse<UserAuthenticationData>> => {
		const response = await authService.getAuthenticationUser(emailOrPhone, token);
		if (response && response.success && response.response) {
			return { success: true, response: response.response };
		}
		return { success: false, errorMessage: response.errorMessage ?? 'Thunk error' };
	},
);
