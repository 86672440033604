import React from 'react';

type LoadingProps = {
	loadingText?: string;
	theme?: string;
};

const Loading = ({ loadingText = 'Loading...', theme = 'black' }: LoadingProps) => (
	<div className="loading-more uk-text-center uk-margin" uk-scrollspy="cls:uk-animation-fade; repeat: true; delay: 50">
		<span className="uk-button loading uk-text-light uk-link-reset uk-disabled" style={{ color: `${theme} !important` }}>
			{loadingText}
		</span>
	</div>
);

export default Loading;
