import { createMigrate, PersistConfig } from 'redux-persist';
import sessionStorage from 'redux-persist/es/storage/session'; // defaults to localStorage for web

export const persistConfigRoot: PersistConfig<any> = {
	key: 'root',
	storage: sessionStorage,
	blacklist: ['shared', 'auth', 'documents', 'payments'],
	migrate: createMigrate({ 3: () => undefined }, { debug: __DEV__ && false }),
	version: 3,
};

export const persistConfigAuth: PersistConfig<any> = {
	key: 'auth',
	storage: sessionStorage,
	version: 3,
	blacklist: ['isAuthLoading', 'isSignout'],
	// add migration and return undefined to use initialState, dont want anything about previous version
	migrate: createMigrate({ 3: () => undefined }, { debug: __DEV__ && false }),
};

export const persistConfigPayments: PersistConfig<any> = {
	key: 'payments',
	storage: sessionStorage,
	blacklist: ['methods'],
	// add migration and return undefined to use initialState, dont want anything about previous version
	migrate: createMigrate({ 3: () => undefined }, { debug: __DEV__ && false }),
};

export const persistConfigLoans: PersistConfig<any> = {
	key: 'loans',
	storage: sessionStorage,
	version: 3,
	// add migration and return undefined to use initialState, dont want anything about previous version
	migrate: createMigrate({ 3: () => undefined }, { debug: __DEV__ && false }),
};

export const persistConfigDeviceInfo = undefined;

export const persistConfigInactivity = undefined;
