import * as React from 'react';

import EmailComposerFormContainer from '@support/containers/EmailComposerForm';

import '../styles/help.css';
import '../styles/help-notice.css';
import '../styles/styles-modal.css';

type Props = {
	isButton?: boolean;
	islink?: boolean;
	title?: string;
	className?: string | undefined;
	showIcon?: boolean;
};

const EmailComposerModalScreen: React.FC<Props> = ({
	isButton = false,
	islink = false,
	title = 'Email us',
	className,
	showIcon = true,
}) => <EmailComposerFormContainer isButton={isButton} islink={islink} title={title} className={className} showIcon={showIcon} />;

export default EmailComposerModalScreen;
