export enum EbaySellerType {
	/**
	 * @description Consumer Onboarded
	 */
	COB = 'COB',
	/**
	 * @description Business Onboarded
	 */
	BO = 'BO',
	/**
	 * @description Business Onboarded Pending Data
	 */
	BOPD = 'BOPD',
}

export enum EbayOfferType {
	WORKING_CAPITAL = 'WC',
	BUSINESS_LOAN = 'INST_EBAY',
}

export enum LPBankNames {
	FINWISE = 'Finwise Bank',
	FIRST_ELECTRONIC = 'First Electronic Bank',
}

export enum SlugConsentTerms {
	LP_PLATFORM = 'consents-terms-service',
	CREDIT_AUTHORIZATION = 'consents-credit-authorization',
	CONSENT_ELECTRONIC_COMMUNICATION = 'consents-electronic-communication-agreement',
	CONSENT_CONTRACT = 'consents-contact',
	PRIVACY_NOTICE = 'consents-privacy-notice',
}

export enum REPAYMENT_FREQUENCY {
	'MONTHLY' = 'Monthly',
	'28 DAYS' = '28 Days',
	'BI-WEEKLY' = 'Bi-Weekly',
	'SEMI-MONTHLY' = 'Semi-Monthly',
}

export enum MessagingOfferType {
	GENERIC = 'Generic',
	MORE_MONEY = 'More Money',
	LOWER_PAYMENT = 'Lower Payment',
	LOWER_RATE = 'Lower Rate',
}

export enum OpportunitySubSources {
	EBAY_LOAN_REFINANCE = 'eBayLoan Refinance',
}
