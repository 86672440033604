import { NAME } from '../../constants';

import * as helpers from '../../helpers/common';

export default helpers.createActionTypes(NAME, [
	'NET_INFO.SET',
	'NAVIGATION_HISTORY.SET',
	'BIOMETRIC_AUTH.SET',
	'BIOMETRIC_AUTH.IS_SUPPORTED',
	'MODAL_SHOWN.SET',
	'BIOMETRIC_AUTH_PROMPT.SET',
	'FORCE_UPDATE.SET',
]);
