import config from '@cp/common/config';
import { emitCustomEvent } from '../events';

///
// eslint-disable-next-line @typescript-eslint/require-await
export const logEventToAnalytics = async (eventName: string, params?: object) => {
	try {
		if (__DEV__) console.log('[ANALYTICS]', eventName, params);
		if (
			(window?.name.startsWith('tag-assistant-debug-window') && config.environment !== 'production') ||
			config.environment === 'production'
		) {
			emitCustomEvent('cp-analytics', { eventName, params });
		}
	} catch (error) {
		console.error(error);
	}
};

// logs route state change to firebase analytics
export const logNavigationStateChangeInAnalytics = async () => {};

export const setUserIdAnalytics = async () => {};

export const getMappedScreenName = (screenName?: string) => {
	return screenName;
};
