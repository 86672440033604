import React from 'react';
import { useHistory } from 'react-router-dom';

import config from '@cp/common/config';
import * as loanStore from '@cp/common/loans/store';
import shared from '@cp/common/modules/shared';
import { messages } from '@cp/common/navbar/constants';
import { routes } from '@cp/common/navigation/constants';
import { setEnrollAutopay } from '@cp/common/payments/store/actions/actions';
import { useAppDispatch, useAppSelector } from '@cp/common/store/hooks';
import icoChevronDown from '@images/icons/ico-chevron-down-white.svg';
import { isMobile } from '@shared/helpers/DetectionDevice';
import EmailComposerModalScreen from '@support/screens/EmailComposer.modal';

import type { PropsListOfItems } from '@navbar/utils';

const ListOfItemsComponent: React.FC<PropsListOfItems> = ({ items, singOut, isManageAutopay, isEmptyLoans }) => {
	const history = useHistory();
	const dispatch = useAppDispatch();
	const isMobileRender = isMobile.any();
	const isSingleLoan = useAppSelector(loanStore.selectors.selectIsSingleLoan);
	const singleLoanId = useAppSelector(loanStore.selectors.selectSingleLoanId);
	const loan = useAppSelector((state) => loanStore.selectors.getLoanById(state, singleLoanId));

	const handlePressMakePayment = () => {
		isSingleLoan && loan?.loanId
			? history.push(`/${routes.makePaymentRoute.initial}/${routes.makePaymentRoute.selectPaymentAmount}`, {
					loanId: loan.loanId,
			  })
			: history.push(`/${routes.makePaymentRoute.initial}/${routes.makePaymentRoute.selectLoan}`);
	};

	const closeMenuPayments = () => {
		const navPayments = document.getElementById('Payments')!;
		navPayments.click();
	};

	const closeMenuProfile = () => {
		const navProfile = document.getElementById('Profile')!;
		navProfile.click();
	};

	return (
		<React.Fragment>
			{items.map((item, idx) => (
				<li className="uk-visible@s" key={`${item.title}-${idx}`}>
					<a className="uk-navbar-item dropdown-item" id={item.title}>
						{item.title}
						<img src={icoChevronDown} alt={item.title} />
					</a>
					{item.subItems ? (
						<div className="uk-navbar-dropdown">
							<ul className="uk-nav uk-navbar-dropdown-nav">
								{item.subItems.map((subItem, subIdx) => {
									return (
										<li key={`${subItem.subTitle}-${subIdx}`}>
											{subItem.subTitle === messages.navbar.profile.subItems.signOut ? (
												<a onClick={singOut}>
													<img src={subItem.icon} alt={subItem.subTitle} />
													<span>{subItem.subTitle}</span>
												</a>
											) : subItem.subTitle === messages.navbar.supports.subItems.call ? (
												<>
													{shared.helpers.format.formatMessage(messages.navbar.supports.subItems.callUs, {
														link: ([contactPhone]) => {
															const phoneNumber = config[contactPhone as keyof typeof config] as string;
															return (
																<>
																	{isMobileRender ? (
																		<a href={`tel:${shared.helpers.format.formatPhone(phoneNumber)}`}>
																			<img src={subItem.icon} alt={subItem.subTitle} />
																			<span>
																				{messages.navbar.supports.subItems.call} {shared.helpers.format.formatPhone(phoneNumber)}
																			</span>
																		</a>
																	) : (
																		<a className="disable-click-link">
																			<img src={subItem.icon} alt={subItem.subTitle} />
																			<span>
																				{messages.navbar.supports.subItems.call} {shared.helpers.format.formatPhone(phoneNumber)}
																			</span>
																		</a>
																	)}
																</>
															);
														},
													})}
												</>
											) : subItem.subTitle === messages.navbar.supports.subItems.email ? (
												<EmailComposerModalScreen />
											) : subItem.subTitle === messages.navbar.payments.subItems.makeAPayment ? (
												<>
													{!isEmptyLoans && (
														<a
															onClick={() => {
																handlePressMakePayment();
																closeMenuPayments();
															}}
														>
															<img src={subItem.icon} alt={subItem.subTitle} />
															<span>{subItem.subTitle}</span>
														</a>
													)}
												</>
											) : subItem.subTitle === messages.navbar.supports.subItems.knowledgeCenter ? (
												<a href={`${process.env.REACT_APP_URL_KNOWLEDGE_BROWSER}`} target="_blank">
													<img src={subItem.icon} alt={subItem.subTitle} />
													<span>{subItem.subTitle}</span>
												</a>
											) : subItem.subTitle === messages.navbar.payments.subItems.manageAutopay && isManageAutopay ? (
												<a
													onClick={() => {
														closeMenuPayments();
														if (isSingleLoan) {
															dispatch(
																setEnrollAutopay({
																	loanId: loan?.loanId ? loan.loanId : (singleLoanId as string),
																	isAutopay: loan?.isAutopay as boolean,
																}),
															);
															return history.push(
																loan?.isAutopay
																	? `/${routes.enrollInAutoPay.enrollInAutoPay}/${routes.enrollInAutoPay.autopaySummary}`
																	: `/${routes.enrollInAutoPay.enrollInAutoPay}/${routes.enrollInAutoPay.autopaySetup}`,
																{
																	loanId: loan?.loanId ? loan.loanId : (singleLoanId as string),
																	isAutopay: loan?.isAutopay,
																},
															);
														} else {
															return history.push(
																`/${routes.enrollInAutoPay.enrollInAutoPay}/${routes.enrollInAutoPay.autopaySelectLoan}`,
															);
														}
													}}
												>
													<img src={subItem.icon} alt={subItem.subTitle} />
													<span>{subItem.subTitle}</span>
												</a>
											) : (
												subItem.subTitle !== messages.navbar.payments.subItems.manageAutopay && (
													<a
														onClick={() => {
															if (item.title === 'Profile') {
																closeMenuProfile();
															} else {
																closeMenuPayments();
															}
															subItem.route && history.push(`/${subItem.route}`);
														}}
													>
														<img src={subItem.icon} alt={subItem.subTitle} />
														<span>{subItem.subTitle}</span>
													</a>
												)
											)}
										</li>
									);
								})}
							</ul>
						</div>
					) : null}
				</li>
			))}
		</React.Fragment>
	);
};

export default ListOfItemsComponent;
