import { NAME } from '../../constants';

import shared from '../../../shared';

export default shared.helpers.createActionTypes(NAME, [
	'NOTIFICATION_LIST.ATTEMPT',
	'NOTIFICATION_LIST.SUCCESS',
	'NOTIFICATION_LIST.FAILURE',
	'NOTIFICATION_LIST.ADD',
	'NOTIFICATION_DETAILS.ATTEMPT',
	'NOTIFICATION_DETAILS.SUCCESS',
	'NOTIFICATION_DETAILS.FAILURE',
	'NOTIFICATION_DETAILS.SET_AS_READ',
	'NOTIFICATION_DETAILS.SET_AS_DELETED',
]);
