import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';

// Types
import type { User } from '../../utils';

import * as profileService from '../../services/profile';

export const getUserInfo = createAsyncThunk('profile/getUserInfo', async (config: AxiosRequestConfig | undefined) => {
	const result = await profileService.getUserInfo(config);
	const isValidUserInfo = result.success && result.response && !result.response?.message?.includes?.('No details found');
	if (isValidUserInfo) {
		return {
			...result,
			response: { ...result.response, employmentType: result.response?.employementType } as User,
		};
	}
	return result;
});

export const updateUserInfo = createAsyncThunk('profile/updateUserInfo', async (user: Partial<User>) => {
	const result = await profileService.updateUserInfo(user);
	return { ...result, response: user };
});
