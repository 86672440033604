import { createAsyncThunk } from '@reduxjs/toolkit';

import { ApiResponse } from '@cp/common/services/utils';
import { RootState } from '@cp/common/store';

import { apiGetApplicationDetails } from '../../services/dtcApply';
import { ResponseGetApplicationDetails } from '../../utils';

type DetailsType = { reject?: boolean; applicationId?: string };

type GetApplyType = {
	applicationId?: string;
};

export const getDetailsState = createAsyncThunk(
	'get/stateDetails',
	async (
		{ reject = true, applicationId = '' }: DetailsType,
		{ getState },
	): Promise<ApiResponse<ResponseGetApplicationDetails>> => {
		const { auth } = getState() as RootState;

		const authToken = auth.userToken || '';
		const appId = applicationId;

		const response = await apiGetApplicationDetails(appId, authToken);
		if (reject) {
			if (!response.success) {
				return { success: false, errorMessage: response.errorMessage };
			}
		}
		return { success: true, response: response.payload };
	},
);

export const getApplyType = createAsyncThunk(
	'get/applyType',
	async ({ applicationId = '' }: GetApplyType, { getState, dispatch }): Promise<ApiResponse<any>> => {
		const { auth } = getState() as RootState;

		const uuId = auth.scope?.applicationUUID || applicationId || '';
		if (uuId) {
			const { success: successDetails, response: responseDetails } = await dispatch(
				getDetailsState({ applicationId: uuId }),
			).unwrap();
			return { success: successDetails, response: responseDetails };
		}
		return { success: false };
	},
);
