import isNumber from 'lodash/isNumber';

import type { Loan, LoanDetailsType, LoansResponse } from '../utils';

export const loansResponseParser = (list: LoansResponse['loanAccounts']): Loan[] =>
	(list || []).map(({ contractId, contractName: lai, ...loan }) => ({
		...loan,
		loanId: contractId,
		lai,
		payment:
			loan.overDueDays > 0 && isNumber(loan.totalMissedPaymentAmount) && loan.totalMissedPaymentAmount > 0
				? loan.totalMissedPaymentAmount
				: loan.currentLoanPaymentAmount,
		amount: loan.disbursalAmount || 0,
		balance: loan.loanPayOffAmountAsOfToday,
		payoffBalanceAmount: loan.payoffBalanceAmount,
		paymentDate: loan.nextDueDate,
		subSource: loan.subSource,
		isOverDue: !!loan.overDueDays,
		overDueDays: loan.overDueDays,
		hasMissedPayments: !!loan.totalMissedPayments || false,
		isAutopay: loan.isAutopay || false,
		isPromoApplicable: (loan.isPromoApplicable && !loan.overDueDays) || false,
		creditLineRepresentative: loan.creditLineRepresentative,
		isCreditLineExhausted: loan.isCreditLineExhausted,
		creditLineConsumedBalance: loan.creditLineConsumedBalance,
		creditLineAvailableBalance: loan.creditLineAvailableBalance,
		isOverDueDaysBetween21And30: loan.overDueDays >= 21 && loan.overDueDays <= 30,
		isOverDueDaysBetween31And90: loan.overDueDays >= 31 && loan.overDueDays <= 90,
		isOverDueDaysBetween1And20: loan.overDueDays >= 1 && loan.overDueDays <= 20,
		isOverDueDaysMoreThan30: loan.overDueDays > 30,
	}));

export const loanDetailsResponseParser = (loanId: string, details: LoanDetailsType): LoanDetailsType => ({
	...details,
	loanId,
	isOverDueDaysBetween1And20: details.overDueDays >= 1 && details.overDueDays <= 20,
	isOverDueDaysBetween21And30: details.overDueDays >= 21 && details.overDueDays <= 30,
	isOverDueDaysMoreThan30: details.overDueDays > 30,
	yearlyPaymentAmount: details.paymentAmount * 12,
});
