import { createReducer, combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';

import { persistConfigDeviceInfo } from '@cp/common/config/persist';
import { actions } from '../actions';

const isNetConnected = createReducer(true, (builder) => {
	builder.addCase(actions.setNetInfo, (state, action) => !!(action.payload.isConnected && action.payload.isInternetReachable));
});

const biometricAuthPermission = createReducer(false, (builder) => {
	builder.addCase(actions.setBiometricAuthPermission, (state, action) => action.payload);
});

const isBiometricAuthSupported = createReducer(null as null | boolean, (builder) => {
	builder.addCase(actions.isBiometricAuthSupported, (state, action) => action.payload);
});

const modalShown = createReducer(false, (builder) => {
	builder.addCase(actions.setModalShown, (state, action) => action.payload);
});

const bioAuthPromptRejected = createReducer(false, (builder) => {
	builder.addCase(actions.setBioAuthPromptRejected, (state, action) => action.payload);
});

const deviceInfoReducer = combineReducers({
	isNetConnected,
	biometricAuthPermission,
	modalShown,
	bioAuthPromptRejected,
	isBiometricAuthSupported,
});

export type DeviceInfoState = ReturnType<typeof deviceInfoReducer>;

const persistDeviceInfoReducer = persistConfigDeviceInfo
	? (persistReducer(persistConfigDeviceInfo, deviceInfoReducer) as unknown as typeof deviceInfoReducer)
	: deviceInfoReducer;

export default persistDeviceInfoReducer;
