const messagesWeb = {
	generalError: {
		wrong: 'Oops, something went wrong',
		issue: 'The issue may be resolved if you try again, but give us a call if the problem persists at',
		Back: 'Go Back',
	},
	sessionTimeout: {
		title: 'Session Timeout',
		body: 'Due to inactivity and for your security,\n you’ve been logged out.',
		call: 'You can also give us a call at',
	},
} as const;

export default messagesWeb;
