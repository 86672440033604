import * as React from 'react';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { Provider } from 'react-redux';
import { BrowserRouter, Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { PersistGate } from 'redux-persist/integration/react';

// Constans
import history from '@cp/common/navigation/navigation.web';
// Store
import { persistor, store } from '@cp/common/store';
// Components
import RootRoutes from '@navigation/RootRoutes';
import AppGeneralError from '@shared/components/AppGeneralError';
import InactivityMonitor from '@shared/components/InactivityMonitor';
import Loading from '@shared/components/Loading';
import HttpRequestInterceptor from '@shared/containers/HttpRequestInterceptor';
import RemoteConfigContainer from '@shared/containers/RemoteConfig.container';
import { insertBeforeOverride } from '@utils/override';

insertBeforeOverride();

const tagManagerArgs: TagManagerArgs = {
	gtmId: 'GTM-TV99TJK',
	auth: process.env.REACT_APP_GTM_AUTH,
	preview: process.env.REACT_APP_GTM_PREVIEW,
};

TagManager.initialize(tagManagerArgs);

const App = () => {
	return (
		<Sentry.ErrorBoundary fallback={<AppGeneralError />}>
			<React.Suspense fallback={<Loading />}>
				<Provider store={store}>
					<PersistGate loading={<Loading />} persistor={persistor}>
						<HttpRequestInterceptor>
							<InactivityMonitor>
								<Router history={history}>
									<RemoteConfigContainer>
										<BrowserRouter basename={process.env.PUBLIC_URL}>
											<RootRoutes />
										</BrowserRouter>
									</RemoteConfigContainer>
								</Router>
							</InactivityMonitor>
						</HttpRequestInterceptor>
					</PersistGate>
				</Provider>
			</React.Suspense>
		</Sentry.ErrorBoundary>
	);
};

export default App;
