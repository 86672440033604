import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

// UIKIT
import 'uikit/dist/css/uikit.min.css';
import 'uikit/dist/js/uikit.min.js';
import 'uikit/dist/js/uikit-icons.min.js';
import '@assets/styles/custom.scss';
import '@assets/styles/printable.scss';
import '@assets/styles/head.scss';
import '@assets/styles/main.scss';
import '@assets/styles/foot.scss';
import '@assets/styles/forms.scss';
import '@assets/styles/skeleton.scss';
import '@assets/styles/formsNav.scss';
import './utils/polyfill';
import '@assets/styles/fixAllCustom.scss';
import '@assets/styles/custom_csp4.x.scss';

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
