import React, { ChangeEvent } from 'react';
import MaskedInput, { Mask } from 'react-text-mask';
import UIkit from 'uikit';

import IcoCheck from '@images/check.png';
import IcoCard from '@images/debitcard-cvv.png';

import UIKitModal from './UIKitModal.component';

type Props = {
	icoInput?: string;
	guide?: boolean;
	type?: 'text' | 'password' | 'number';
	labelInput?: string;
	placeHolder?: string;
	name?: string;
	value?: string | number;
	isDisabled?: boolean;
	isError?: string | undefined;
	isTouched?: boolean;
	modalName?: string | undefined;
	testID?: string | undefined;
	classNameInput?: string | undefined;
	classNameContent?: string | undefined;
	classNameContentInput?: string | undefined;
	mask: Mask | ((value: string) => Mask);
	iconInputSmall?: boolean;
	onChange: {
		(e: ChangeEvent<any>): void;
		<T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any> ? void : (e: string | ChangeEvent<any>) => void;
	};
	onBlur: { (e: any): void; <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void };
};

const ModalInput = ({
	icoInput,
	modalName,
	iconInputSmall,
}: {
	icoInput?: string;
	modalName?: string | undefined;
	iconInputSmall?: boolean;
}) => (
	<>
		<a
			className="uk-form-icon uk-form-icon-flip"
			href="/"
			target="_blank"
			rel="noreferrer"
			onClick={(e) => {
				e.preventDefault();
				UIkit.modal(`#info-check`).show();
			}}
		>
			<img src={icoInput} alt="info-check" className={iconInputSmall ? 'img-small ' : ''} />
		</a>

		<UIKitModal id="info-check" className="lp-modal bank-modal uk-flex-top" uk-modal="true">
			<div className="uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
				<button className="uk-modal-close-default" type="button" uk-close="true" />
				<div className="uk-modal-body uk-text-center uk-overflow-hidden">
					{modalName === 'debitCard' ? (
						<>
							<img className="cvv-img uk-width-medium uk-margin-small-top" src={IcoCard} alt="card" />
							<p className="uk-text-emphasis uk-text-small uk-margin-remove">
								The card verification value (CVV) is a set of 3-4 digit numbers on the back of your card.
							</p>
						</>
					) : (
						<img className="check-img uk-width-large uk-margin-small-top" src={IcoCheck} alt="check" />
					)}
				</div>
			</div>
		</UIKitModal>
	</>
);

const InputMask: React.FC<Props> = ({
	labelInput,
	placeHolder,
	name,
	value,
	isDisabled,
	isError,
	isTouched,
	testID,
	classNameInput,
	classNameContent,
	classNameContentInput,
	type,
	mask,
	icoInput,
	modalName,
	iconInputSmall,
	guide = true,
	onChange,
	onBlur,
}) => {
	const inputClass = isError && isTouched ? 'uk-input border-error-input' : 'uk-input';
	return (
		<div className={classNameContent ? classNameContent : 'lp-form-field'}>
			<div className={classNameContentInput ? classNameContentInput : 'uk-form-controls'}>
				<ModalInput icoInput={icoInput} modalName={modalName} iconInputSmall={iconInputSmall} />

				<MaskedInput
					guide={guide}
					mask={mask}
					className={`${classNameInput ? classNameInput : ''} ${inputClass}`}
					type={type ? type : 'text'}
					placeholder={placeHolder || ' '}
					name={name}
					value={value}
					disabled={isDisabled}
					id={testID}
					onChange={onChange}
					onBlur={onBlur}
				/>
				<label className="uk-form-label">{labelInput}</label>
			</div>
			{isError && isTouched && (
				<div className="lp-error">
					<p>{isError}</p>
				</div>
			)}
		</div>
	);
};

export default InputMask;
