import { BankAccountRefinance, DocumentsTypes, RefinanceOffer, RefinanceOpportunity, Tila } from '../../utils';

// eslint-disable-next-line no-shadow
export enum LoadingStatus {
	NOT_LOADED = 'not loaded',
	LOADING = 'loading',
	LOADED = 'loaded',
	ERROR = 'error',
}

export interface Documents {
	documents: DocumentsTypes[] | null;
}

export interface SaveAgreement {
	saveAgreement: null;
	loading: string;
	saveAgreementError: string;
}

export interface RefinanceState {
	opportunityError: string;
	opportunity: RefinanceOpportunity | null;
	yearlySaving: number | null | undefined;
	loading: string;
	hasYearlySaving: boolean;
	creditUnionOption: string | undefined;
}

export interface PaymentMethods {
	paymentMethods: BankAccountRefinance[] | null | any;
	loading: string;
	paymentMethodsError: string;
}

export interface InitialStateGetOffers {
	offers: RefinanceOffer[] | null | any;
	offersError?: string | null;
	opportunity: RefinanceOpportunity | null;
	loading: string;
	selectedOffer: RefinanceOffer[] | null | any;
	experimentGroup: string | null | any;
}

export interface TilaType {
	tila: Tila | null;
	tilaError: string | null;
	loading: string;
}

export interface VerifyIdentityState {
	currentTry: number;
	alreadySkipped: boolean;
	loadingStatusFetchKYCQuestions: LoadingStatus;
	loadingStatusSaveKYCAnswers: LoadingStatus;
	errorFetchKYCQuestions: string | null | undefined;
	errorSaveKYCAnswers: string | null | undefined;
	// TODO: use here the correct type
	dataFetchKYCQuestions: { [key: string]: string | number | any };
	dataSaveKYCAnswers: { [key: string]: string | number };
	kYCQuestions: KYCQuestion[];
}

export type KYCQuestion = {
	questionNumber: number;
	question: string;
	type: string;
	options: string[];
	answer?: string | null;
	isSkipped: boolean;
};
