import { createDraftSafeSelector, createSelector } from '@reduxjs/toolkit';

import type { RootState } from '@cp/common/store';

import { NAME } from '../../constants';

const profileSelector = (state: RootState) => state[NAME];

export const getUserInfo = createDraftSafeSelector(profileSelector, (state) => state.userInfo);
export const getContactId = createSelector(profileSelector, (state) => state.userInfo?.contactId);
export const getUserId = createSelector(profileSelector, (state) => state.userInfo?.id);
export const getUserEmail = createSelector(getUserInfo, (item) => item?.email);
