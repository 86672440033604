import { createEntityAdapter } from '@reduxjs/toolkit';

import type { CompletedLoan, Loan, LoanDetailsType, LoanVirtualCard, VirtualCardDetails } from '../../utils';

export const loansAdapter = createEntityAdapter<Loan>({
	selectId: (loan) => loan.loanId,
});

export const loansVirtucalCardsAdapter = createEntityAdapter<LoanVirtualCard>({
	selectId: (loan) => loan.applicationId,
});

export const loansDetailsAdapter = createEntityAdapter<LoanDetailsType>({ selectId: (loan) => loan.loanId });
export const virtualCardsDetailsAdapter = createEntityAdapter<VirtualCardDetails>({ selectId: (loan) => loan.id });

export const loansCompletedLoansAdapter = createEntityAdapter<CompletedLoan>({
	selectId: (completedLoan) => completedLoan.name,
});
