import React from 'react';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { signOut } from '@cp/common/auth/store/actions/thunks';
import { consumer, manager, oauth, vendors } from '@cp/common/lendingpoint/api';
import { paths } from '@cp/common/lendingpoint/constants';
import NavigationService from '@cp/common/navigation';
import { routes } from '@cp/common/navigation/constants';
import { isNative } from '@cp/common/platform';
import * as extraHeaders from '@cp/common/services/modules/lendingpoint/helpers';
import { useAppDispatch, useAppSelector } from '@cp/common/store/hooks';

import type { OAuthPayload } from '@cp/common/auth/utils';

import { getAuthData } from '../../auth/store/selectors';
import { isAuthExpired } from '../helpers';
import useEventCallback from './useEventCallback';

const blacklistUrlTryAgain: Array<{ path: string | RegExp; method?: string }> = [
	{ path: paths.loanApplications },
	{ path: paths.applications + paths.offersCatalog },
	{ path: paths.revokeToken },
	{ path: new RegExp(String.raw`${paths.loanApplications}/(\S+)/`), method: 'patch' },
	{ path: new RegExp(String.raw`${paths.loans}/(\S+)/access-links`) },
	{ path: new RegExp(String.raw`${paths.loans}/(\S+)/payments\?type=Missed`) },
	{ path: new RegExp(String.raw`${paths.loans}/(\S+)/payments`), method: 'post' },
	{ path: paths.paymentMethods, method: 'post' },
	{ path: new RegExp(String.raw`${paths.loans}/(\S+)`), method: 'patch' },
	{ path: new RegExp(String.raw`${paths.loans}/(\S+)/messages`), method: 'get' },
	{ path: `${paths.user}/access-links`, method: 'get' },
	{ path: new RegExp(String.raw`/(\S+)`), method: 'get' },
	{ path: new RegExp(String.raw`${paths.loans}/(\S+)/mails`) },
	{ path: new RegExp(String.raw`${paths.loans}//mails`) },
	{ path: new RegExp(String.raw`${paths.loanApplications}/(\S+)/documents`), method: 'post' },
];

const checkBlacklistTryAgain = (url: string, method: string) =>
	blacklistUrlTryAgain.find(
		(backListItem) =>
			(backListItem.path === url || (backListItem.path instanceof RegExp && backListItem.path.exec(url))) &&
			(!backListItem.method || backListItem.method === method),
	);

/**
 * returns if error is being caused by axios cancel
 * @function
 * @returns {Boolean} - true if the error caused by canceling the request
 */
const areRequestCanceled = (err?: AxiosError) => {
	return !!err && axios.isCancel(err);
};

function useHttpInterceptor() {
	const dispatch = useAppDispatch();
	const authData = useAppSelector(getAuthData);

	const { authorizationToken, correlationId, expiresIn } = authData;

	React.useEffect(() => {
		/**
		 * Intercept response errors to navigate to TryAgain or user logour
		 * @param error
		 */
		const consumerInterceptorErrorId = consumer.interceptors.response.use(undefined, interceptResponseError);
		const managerInterceptorErrorId = manager.interceptors.response.use(undefined, interceptResponseError);
		const oauthInterceptorErrorId = oauth.interceptors.response.use(undefined, interceptResponseError);
		const vendorsInterceptorErrorId = vendors.interceptors.response.use(undefined, interceptResponseError);

		/**
		 * Intercept requests to add authorization header token, correlationId, etc...
		 */
		const consumerInterceptorReqId = consumer.interceptors.request.use(interceptConsumerRequest);
		const managerInterceptorReqId = manager.interceptors.request.use(interceptConsumerRequest);
		const vendorsInterceptorReqId = vendors.interceptors.request.use(interceptConsumerRequest);

		return () => {
			consumer.interceptors.response.eject(consumerInterceptorErrorId);
			manager.interceptors.response.eject(managerInterceptorErrorId);
			oauth.interceptors.response.eject(oauthInterceptorErrorId);
			vendors.interceptors.response.eject(vendorsInterceptorErrorId);

			consumer.interceptors.request.eject(consumerInterceptorReqId);
			manager.interceptors.request.eject(managerInterceptorReqId);
			vendors.interceptors.request.eject(vendorsInterceptorReqId);
		};
	}, []);

	const interceptResponseError = useEventCallback((error: AxiosError) => {
		const canceled = areRequestCanceled(error);
		const status = error.response?.status || 0;
		const url = error?.config?.url as string;
		const onTryAgainError = error?.config?.onTryAgainError;
		const method = error?.config?.method;

		const isFailedRefreshToken =
			!canceled && (error.config?.data as OAuthPayload)?.grantType === 'refresh_token' && url === paths.token;
		if (!canceled) {
			if ((status === 401 && url !== paths.revokeToken) || isFailedRefreshToken) {
				dispatch(signOut({ isAuthExpired: true }));
			} else if (status === 403 || status === 404 || status >= 500) {
				const paymentFailedMatch =
					error.config.method?.toLowerCase() === 'post' && new RegExp(String.raw`${paths.loans}/(\S+)/payments`).exec(url); // check if payment failed
				const blackListResult = checkBlacklistTryAgain(url, method || '');
				const redirectTryAgain = onTryAgainError?.(error) ?? (blackListResult ? false : null) ?? true;
				const isLogged = status === 403 ? false : !!authorizationToken;
				if (__DEV__ && 0)
					console.log('[AXIOS INTERCEPT]', {
						isLogged,
						status,
						paymentFailedMatch,
						url,
						canceled,
						isFailedRefreshToken,
						blackListResult,
						redirectTryAgain,
					});

				if ((paymentFailedMatch && paymentFailedMatch[1]) || redirectTryAgain) {
					const params = {
						isLogged,
						isTryAgainPayment: !!paymentFailedMatch,
						loanId: paymentFailedMatch && paymentFailedMatch[1] ? paymentFailedMatch[1] : false,
					};

					NavigationService.navigate(
						paymentFailedMatch
							? isNative
								? 'MakePaymentFailed'
								: routes.paymentFailed
							: isNative
							? 'TryAgain'
							: routes.tryAgain,
						params,
					);
					if (!isLogged) {
						dispatch(signOut({ isAuthExpired: true }));
					}
				}
			}
		}

		return Promise.reject(error);
	});

	const interceptConsumerRequest = useEventCallback((request: AxiosRequestConfig) => {
		if (!authorizationToken) return request;

		if (isAuthExpired(expiresIn)) {
			dispatch(signOut({ isAuthExpired: true }));
			return request;
		}
		const headers = request.headers as Record<string, string>;

		if (correlationId) {
			const correlationIdHeader = extraHeaders.getCorrelationIdHeader(correlationId);
			Object.assign(request.headers, correlationIdHeader);
		}

		// Add authorization header
		headers.Authorization = `Bearer ${authorizationToken}`;

		if (request.method === 'get') {
			request.data = null; // fix header content-type
		}
		// add sdocs credentials
		if (
			request.url &&
			request.url.startsWith(paths.applications) &&
			request.url.search(new RegExp(paths.offersCatalog, 'g')) < 0
		) {
			headers.Authorization = authorizationToken;
		}

		return request;
	});

	return;
}

export default useHttpInterceptor;
