import { createAction } from '@reduxjs/toolkit';

import types from './types';
import type { NavigationHistoryEntry } from '../../utils';
import type { AppUpdateInfo } from '../../utils/forceUpdate';

const setNetInfo = createAction<{ isConnected: boolean; isInternetReachable: boolean }>(types.NET_INFO.SET);

const updateNavigationHistory = createAction<NavigationHistoryEntry>(types.NAVIGATION_HISTORY.SET);

const setBiometricAuthPermission = createAction<boolean>(types.BIOMETRIC_AUTH.SET);

const isBiometricAuthSupported = createAction<boolean>(types.BIOMETRIC_AUTH.IS_SUPPORTED);

const setModalShown = createAction<boolean>(types.MODAL_SHOWN.SET);
const setBioAuthPromptRejected = createAction<boolean>(types.BIOMETRIC_AUTH_PROMPT.SET);

const setForceUpdate = createAction<AppUpdateInfo>(types.FORCE_UPDATE.SET);

const setAutoSignoutTimestamp = createAction<number>('autoSignoutTimestamp');

export {
	isBiometricAuthSupported,
	setAutoSignoutTimestamp,
	setBioAuthPromptRejected,
	setBiometricAuthPermission,
	setForceUpdate,
	setModalShown,
	setNetInfo,
	updateNavigationHistory,
};
