import { createReducer } from '@reduxjs/toolkit';

import { detailsState } from '../../utils';
import { getApplyType, getDetailsState } from '../actions/thunks';

export const detailsInitialState: detailsState = {
	getDetails: {
		loadingStatus: 'not loaded',
		data: null,
		error: null,
	},
	getApplyType: {
		loadingStatus: 'not loaded',
		data: null,
		error: null,
	},
};

const dtcApplyReducer = createReducer(detailsInitialState, (builder) => {
	// get Details
	builder.addCase(getDetailsState.pending, (state) => {
		state.getDetails.loadingStatus = 'loading';
	});
	builder.addCase(getDetailsState.fulfilled, (state, action) => {
		state.getDetails.loadingStatus = 'loaded';
		state.getDetails.data = action.payload.response;
	});
	builder.addCase(getDetailsState.rejected, (state, action) => {
		state.getDetails.loadingStatus = 'error';
		state.getDetails.error = action.error || action.payload;
	});
	// get apply Type
	builder.addCase(getApplyType.pending, (state) => {
		state.getApplyType.loadingStatus = 'loading';
	});
	builder.addCase(getApplyType.fulfilled, (state, action) => {
		state.getApplyType.loadingStatus = 'loaded';
		state.getApplyType.data = action.payload.response;
	});
	builder.addCase(getApplyType.rejected, (state, action) => {
		state.getApplyType.loadingStatus = 'error';
		state.getApplyType.error = action.error || action.payload;
	});
});

export default dtcApplyReducer;
export type DTCAApplyState = ReturnType<typeof dtcApplyReducer>;
