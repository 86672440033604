import * as React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

// Constants
import { routes } from '@cp/common/navigation/constants/routes.constants';
import Footer from '@navbar/screens/Footer.screen';
import NavBar from '@navbar/screens/Navbar.screen';
import NavbarMobileScreen from '@navbar/screens/NavbarMobile.screen';
// Types

type PrivateRouteProps = {
	isLogged: boolean;
	children: any;
	isOctp: boolean;
	isInMaintenance: boolean;
} & RouteProps;

type LocationParams = { isOctp: boolean };

const PrivateRoute = ({ isLogged, children, isOctp, isInMaintenance, ...rest }: PrivateRouteProps) => {
	const location = useLocation<LocationParams>();

	const routesValid =
		location.pathname === `/${routes.makePaymentRoute.initial}/${routes.makePaymentRoute.selectPaymentAmount}` ||
		location.pathname === `/${routes.makePaymentRoute.initial}/${routes.makePaymentRoute.selectPaymentDate}` ||
		location.pathname === `/${routes.makePaymentRoute.initial}/${routes.makePaymentRoute.selectPaymentMethod}` ||
		location.pathname === `/${routes.makePaymentRoute.initial}/${routes.makePaymentRoute.paymentSummary}` ||
		location.pathname === `/${routes.makePaymentRoute.initial}/${routes.makePaymentRoute.paymentSummarySuccess}` ||
		location.pathname === `/${routes.makePaymentRoute.initial}/${routes.makePaymentRoute.paymentSummaryError}` ||
		location.pathname === `/${routes.makePaymentRoute.initial}/${routes.makePaymentRoute.paymentSummaryProccessing}` ||
		location.pathname === `/${routes.payments}/${routes.paymentMethods}` ||
		location.pathname === `/${routes.payments}/${routes.paymentMethods}/${routes.newBankAccount}` ||
		location.pathname === `/${routes.payments}/${routes.paymentMethods}/${routes.newDebitCard}` ||
		location.pathname === `/${routes.payments}/${routes.paymentMethods}/${routes.addBillingInformation}` ||
		location.pathname === `/${routes.payments}/${routes.PaymentMethodsSuccess}` ||
		location.pathname === `/${routes.payments}/${routes.PaymentMethodFailed}`;

	const goesToPayment = routesValid && isOctp;

	if (!isLogged && !goesToPayment && isOctp) {
		window.localStorage.setItem('goesTo', location.pathname);

		return (
			<Redirect
				to={{
					pathname: `/${routes.auth}`,
					state: { from: location },
				}}
			/>
		);
	}

	return (
		<>
			{isInMaintenance ? (
				<Redirect to={routes.maintenance} />
			) : (
				<Route
					{...rest}
					render={({ location: locationProps }) =>
						isLogged || (goesToPayment && isOctp) ? (
							<>
								<NavBar />
								<main className="uk-background-light">
									<section className="uk-section uk-section-default lp-section padding-top-32">{children}</section>
								</main>
								<Footer />
								<NavbarMobileScreen />
							</>
						) : (
							<Redirect
								to={{
									pathname: `/${routes.auth}`,
									state: { from: locationProps },
								}}
							/>
						)
					}
				/>
			)}
		</>
	);
};

export default PrivateRoute;
