import React from 'react';
import { useHistory } from 'react-router-dom';

type Props = {
	text: string;
	testID?: string;
	isDisabled?: boolean;
	onClick?: () => void;
	className?: string;
	isLoading?: boolean;
	type?: 'button' | 'submit' | 'reset';
	style?: any;
	to?: string;
};

const ButtonGeneric: React.FC<Props> = ({
	text,
	testID,
	isDisabled,
	onClick,
	className,
	isLoading,
	style = {},
	type = 'submit',
	to,
}) => {
	const history = useHistory();
	return (
		<button
			id={testID}
			type={type}
			style={style}
			disabled={isDisabled}
			onClick={to ? () => history.push(to) : onClick}
			className={className ? className : 'uk-button uk-button-primary'}
		>
			{text} {isLoading && <div uk-spinner="true" />}
		</button>
	);
};

export default ButtonGeneric;
