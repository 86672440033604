import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';

import { persistConfigAuth } from '@cp/common/config/persist';

import type { RequestLoadingStatus } from '@cp/common/modules/shared/utils';
import type { AuthData, AuthScope } from '../../utils';

import { actions, thunks } from '../actions';

interface AuthState {
	isAuthLoading: boolean;
	isSignout: boolean;
	isAutoSignout: boolean;
	userToken: string | null;
	octpToken: string | null;
	originUrl?: string | null;
	refreshToken: string | null;
	expiresIn: string | null;
	scope: AuthScope | null | undefined;
	correlationId: string | null | undefined;
	loading: RequestLoadingStatus;
	currentRequestId: string | undefined;
	isSystemGeneratedPassword: boolean | undefined;
}

const initialState: AuthState = {
	isAuthLoading: false,
	isSignout: false,
	isAutoSignout: false,
	userToken: null,
	octpToken: null,
	originUrl: null,
	scope: null,
	correlationId: null,
	refreshToken: null,
	expiresIn: null,
	loading: 'idle',
	currentRequestId: undefined,
	isSystemGeneratedPassword: undefined,
};

const authReducer = createReducer(initialState, (builder) => {
	builder.addCase(thunks.signIn.pending, (state, action) => {
		if (!state) return;
		// clear OCTP token
		state.octpToken = null;
		if (state.loading === 'idle') {
			state.loading = 'pending';
			state.currentRequestId = action.meta.requestId;
			state.isAutoSignout = false;
		}
	});

	builder.addCase(thunks.signIn.rejected, (state, action) => {
		const { requestId } = action.meta;
		if (state.loading === 'pending' && state.currentRequestId === requestId) {
			state.loading = 'idle';
			state.currentRequestId = undefined;
			state.isAutoSignout = false;
		}
	});

	builder.addCase(thunks.signOut.pending, (state, action) => {
		thunks.setToken(state.userToken || state.octpToken);
		const isAutoSignout = !!action.meta.arg?.isAutoSignout || !!action.meta.arg?.isAuthExpired;
		state.userToken = null;
		state.refreshToken = null;
		state.correlationId = null;
		state.scope = null;
		state.expiresIn = null;
		state.isAuthLoading = false;
		state.isSignout = !isAutoSignout;
		state.isAutoSignout = isAutoSignout;
	});

	builder.addCase(thunks.signOut.fulfilled, (state) => {
		state.isAutoSignout = true;
		state.isSignout = false;
	});

	builder.addCase(actions.setIsAuthLoading, (state, action) => {
		state.isAuthLoading = action.payload;
	});

	builder.addCase(thunks.getAuthenticationUser.fulfilled, (state, action) => {
		if (state && action.payload.response) {
			const response = action.payload.response;
			state.isSystemGeneratedPassword = response.isSystemGeneratedPassword;
		}
	});

	builder.addMatcher(
		(action) => thunks.signIn.fulfilled.match(action) || actions.setCredentials.match(action),
		(state, action) => {
			const data = action.payload?.data as AuthData | undefined;

			if (data) {
				const { token, refreshToken, correlationId, scope, expiresIn, isOctp, originUrl } = data;
				state.userToken = !isOctp && token ? token : null;
				state.octpToken = isOctp && token ? token : null;
				state.originUrl = originUrl ? originUrl : null;
				state.refreshToken = refreshToken ? refreshToken : null;
				state.correlationId = correlationId ? correlationId : null;
				state.scope = scope ? scope : null;
				state.expiresIn = expiresIn ? expiresIn : null;
				state.isAutoSignout = false;
				state.isSignout = false;
				state.isSystemGeneratedPassword = action.payload.isSystemGeneratedPassword;
			}

			const requestId = action?.meta?.requestId;
			if (state.loading === 'pending' && state.currentRequestId === requestId) {
				state.loading = 'idle';
				state.currentRequestId = undefined;
			}
		},
	);
});

export type AuthenticationState = ReturnType<typeof authReducer>;

const persistAuthReducer = persistReducer<AuthenticationState>(persistConfigAuth, authReducer);

export default persistAuthReducer;
