import config from '@cp/common/config';
import { HTTPServicesResponse } from '../../../../services/utils';
import { AppVersionResponse, AppVersion } from './types';
import { getAppVersion } from './helpers';
import { lendingpoint } from '../../../../services';
import { handleSubModuleError } from '@cp/common/services/modules/lendingpoint/helpers';

export const fetchRemoteAppVersionNumber = async (): Promise<HTTPServicesResponse<AppVersion>> => {
	try {
		const accessToken = config.api.appVersions.bearerToken;
		if (!accessToken || config.disableForceUpdate) {
			if (!accessToken)
				console.warn('Fail to get accessToken for force update:' + accessToken + ' please check your .env file exist');
			return { success: false };
		}
		const appVersions = await lendingpoint.api.utilities.get<AppVersionResponse>('/mobile/versions');
		return { success: true, payload: getAppVersion(appVersions.data) };
	} catch (e) {
		return handleSubModuleError(e);
	}
};
