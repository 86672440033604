// import { persistConfigPlaid } from '@cp/common/config/persist';
import { BankAccountState } from '@cp/common/modules/bank-account/utils';
import { combineReducers, createReducer } from '@reduxjs/toolkit';
// import persistReducer from 'redux-persist/es/persistReducer';
import { getBankAccountInfo, saveBankAccountInfo } from '../actions/thunks';
import * as reducers from './bank-account.reducers';
export const bankAccountInitialState: BankAccountState = {
	saveBankAccount: {
		loadingStatus: 'not loaded',
		data: '',
		error: null,
	},
	bankAccount: {
		loadingStatus: 'not loaded',
		data: undefined,
		error: undefined,
	},
};
const bankAccount = createReducer(bankAccountInitialState, (builder) => {
	builder
		.addCase(saveBankAccountInfo.pending, reducers.saveBankAccountInfoPending)
		.addCase(saveBankAccountInfo.fulfilled, reducers.saveBankAccountInfoFulfilled)
		.addCase(saveBankAccountInfo.rejected, reducers.saveBankAccountInfoRejected)
		.addCase(getBankAccountInfo.pending, reducers.getBankAccountInfoPending)
		.addCase(getBankAccountInfo.fulfilled, reducers.getBankAccountInfoFulfilled)
		.addCase(getBankAccountInfo.rejected, reducers.getBankAccountInfoRejected);
});

const bankAccountReducer = combineReducers({
	bankAccount,
});

export type PlaidState = ReturnType<typeof bankAccountReducer>;

// const persistPlaidReducer = persistConfigPlaid
// 	? (persistReducer(persistConfigPlaid, plaidReducer) as unknown as typeof plaidReducer)
// 	: plaidReducer;

export default bankAccountReducer;
