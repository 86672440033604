import React from 'react';

import { defineMessage } from '@cp/common/shared/helpers';

import { TagPlaceholderCallback } from '../../shared/utils';
import { EbayOfferType } from './enums';

const messagesWeb = {
	personalInformation: {
		title: 'Personal Information',
		firstName: 'First Name',
		lastName: 'Last Name',
		address: 'Address',
		street: 'Street Address',
		city: 'City',
		state: 'State',
		zip: 'ZIP Code',
		phone: 'Phone',
		phoneNumber: 'Phone Number',
		email: 'Email',
		good: 'Looks Good',
		confimrAndContinue: 'Confirm and Continue',
		update: 'Update My Information',
		apartment: 'Apartment, Suite, Building, etc. (Optional)',
	},
	refinance: {
		confirm: {
			title: 'Confirm & continue',
			subtitle: 'Confirm your personal information',
			userInfo: {
				address: 'Address',
				phone: 'Phone',
				email: 'Email',
				income: 'Income',
				incomeType: 'Income type',
				employer: 'Employer',
				fundsWillBeDeposited: 'Funds will be deposited in your Bank account',
				paymentsWillBeAutomatically: 'Payments will be automatically withdrawn from',
				lastFourDigits: 'Debit Card ending with',
				furtherIOnBehalf:
					'Further, I, on behalf of myself and the business, authorize eBay, Inc., LendingPoint and its lending partners, including but not limited to FinWise Bank, First Electronic Bank, and Fundation Group LLC (“Lending Partners”) to share with one another account and transaction details about my eBay Seller account and the information that I have provided here for consideration of this loan inquiry.',
			},
			labels: {
				lendingPointPlatform: 'LendingPoint Platform Terms of Service',
				creditAuthorization: 'Credit Authorization',
				conseToElectronic: 'Consent to Electronic Communications,',
				conseToContact: 'Consent to Contact,',
				and: 'and ',
				privacyNotice: 'Privacy Notice.',
				callUsAt: 'call us at',
			},
			toUpdate: defineMessage<{ br: string | React.ReactElement }>({
				id: 'toUpdate.confirm',
				defaultMessage: 'To update your information: {br}',
			}),
			btnSubmit: 'Confirm and continue',
			callUs: 'To update your information, call us at ',
			depositBankNameNotFound: defineMessage<{ isEbaySellerType: boolean; link: TagPlaceholderCallback }>({
				id: 'refinance.depositBankNameNotFound',
				defaultMessage: `{isEbaySellerType, select,
				false {<link>contactPhone</link>}
				other {<link>contactPhone3</link>}
			}`,
			}),
			disclaimer: defineMessage<{ strong: TagPlaceholderCallback }>({
				id: 'refinance.disclaimer',
				defaultMessage: `By clicking {strong}, I confirm that I have the authority to contract on behalf of the business
										and further confirm that the information that I provided is complete and accurate. Further, I, on behalf of
										myself and the business, authorize (1) LendingPoint and its lending partners inlcuding but not limited to
										FinWise Bank, First Electronic Bank and Foundation Group LLP (“Lending Partenrs”) to share with one another
										the information that I had provided here for consideration of this loan inquiry; and (2) eBay, Inc.,
										LendingPoint and its Lending Partners to share with one another information about my eBay sales and loan
										inquiry as described in Section 6 and 7 of the LendingPoint Platform Terms of Service. I understand this
										inquiry will not impact my personal credit score and that the rate estimates and term presented are subject to
										change after full application review. I also acknowledge that I have reviewed and agree to the following: `,
			}),
		},
		offers: {
			cardDetails: {
				newLoanAmount: 'New loan amount',
			},
			title: defineMessage<{
				disbursalAmount: TagPlaceholderCallback | string;
				isEbay: boolean;
				messagingOfferType?: string;
			}>({
				id: 'offers.title',
				defaultMessage: `{isEbay, select,
					true {{messagingOfferType, select,
						Generic {Your on-time payments have earned you access to get a new loan with us!}
						MoreMoney {Congratulations! We do have the offer you want! Your on-time payments have earned you access to more capital.}
						LowerPayment {Congratulations! We do have the offer you want! Your on-time payments have earned you access to a lower payment.}
						LowerRate {Congratulations! We do have the offer you want! Your on-time payments have earned you access to a lower rate.}
						other {Your on-time payments have earned you access to get a new loan with us!}
					}}
					other {Your on-time payments get you access to more money. Get an extra {disbursalAmount}}
				}`,
			}),
			subtitle: 'Get ready for a better loan',
			selectWorkingCapital: 'Select either Working Capital or Business Loan with Fixed Payments',
			workinCapital: 'Working Capital',
			businessloan: 'Business Loan',
			estimatedDisbursalAmount: 'Estimated disbursal amount',
			selectToCalculatePayments: 'Select to calculate payments',
			estimatedTimeToRepay: 'Estimated time to repay',
			feesAndPayments: 'Fees and payments',
			oneFixedFee: 'One fixed fee (8%)',
			totalPayments: 'Total payments',
			months: 'months',
			thereAreNoOffers: 'There are no offers to show.',
			labels: {
				paymentAmountEveryTwoWeeks: 'Payment amount every two weeks',
				currentBalance: 'Current Balance',
				currentLoan: 'Current loan',
				amount: 'Amount',
				payment: 'Payment',
				frequency: 'Frequency',
				term: 'Term',
				apr: 'APR',
				bottomLabel: 'Current payoff balance',
				save: 'Save',
				yearly: 'yearly',
				paymentOptions: 'Payment options',
				monthly: 'Monthly',
				originationFee: 'Origination fee',
				estimatedDeposit: 'Estimated deposit amount',
				payingOnTime: 'Paying on time has paid off',
				remaining: 'Remaining',
				newLoanTerm: 'New loan term',
				newApr: 'New apr',
				expectedTerms: 'Expected terms',
				termsOptions: 'Terms options',
			},
			yourCurrent: 'Your current loan balance',
			currentBalance: 'Current Balance',
			oneApproved:
				'Once approved, your new loan will be applied to your current payoff balance and the remaining amount will be deposited into your bank account.',
			capitalForShort:
				'Capital for short terms needs such an inventory! Once approved, your new loan will be applied to your current payoff balance and the remaining amount will be deposited into your bank account.',
			selectOffer: 'Select Offer',
			agreement: 'Agreement',
			moreForYou: 'More for you',
			selectYourPreferred: 'Select your preferred offer',
			actualAmountsWill: 'Actual amounts will be shown before you sign the contract.',
			footerLabel: defineMessage<{ offerType: EbayOfferType | string | undefined; br: string | React.ReactElement }>({
				id: 'refi.offers.footer',
				defaultMessage: `{offerType, select,
				WC {Capital for short terms needs such an inventory! Once approved, your new loan will be applied to your current payoff balance and the remaining amount will be deposited into your bank account.}
				INST_EBAY {Lower monthly payments as you go! Once approved, your new loan will be applied to your current payoff balance and the remaining amount will be deposited into your bank account.}
				other {Estimated deposit amount.{br}Once approved, your new loan will be applied to your current payoff balance and the remaining amount will be deposited into your bank account.}	
				}`,
			}),
			estimatedDeposit:
				'Estimated deposit amount. Once approved, your new loan will be applied to your current payoff balance and the remaining amount will be deposited into your bank account.',
		},
		sdocs: {
			title: 'Sign agreement',
			subTitle: 'You’re almost done',
			content: 'Please confirm your loan details, complete the electronic records consent, and sign your loan agreements.',
			reviewAndSign: 'Review and sign your contract',
			loanAgreement: 'LOAN AGREEMENT',
			businessLoanAgreement: 'BUSINESS LOAN AGREEMENT',
			tila: {
				titleBL: 'CREDIT COST DISCLOSURES',
				titleDTC: 'FEDERAL TRUTH-IN-LENDING\nDISCLOSURES',
				pleaseConfirmYourloan:
					'Please confirm your loan details, complete the electronic records consent and sign your loan agreement.',
				disbursedLoanAmount: 'DISBURSED LOAN AMOUNT',
				thePrincipalLoanAmount: 'The principal loan amount disbursed to you on your behalf.',
				oneFixedFinanceCharge: 'ONE FIXED FINANCE CHARGE',
				theDollarAmount: 'The dollar amount the credit will cost you.',
				amountDue: 'AMOUNT DUE',
				theDisbursedLoanAmountPlus:
					'The Disbursed Loan Amount plus the One Fixed Finance Charge equal the total amount due by you under this Agreement (Subject to any addiitonal fees or amounts that may come due and owing under the provisions of this Agreeement).',
				totalOfPayment: 'Total of payments',
				theAmountYouWillHavePaid: `The amount you will have paid after you've made all payments as scheduled.`,
				amountFinanced: 'Amount financed',
				theAmountOfCreditProvided: 'The amount of credit provided to you or on your behalf.',
				financeCharge: 'FINANCE CHARGE',
				annualPercentage: 'ANNUAL PERCENTAGE RATE',
				theCostOfYourCredit: 'The cost of your credit as a yearly rate.',
				yourPaymentSchedule: 'Your payment schedule will be',
				numberOfPayment: 'Number of payments',
				amountOfPayment: 'Amount of payments',
				paymentFrequency: 'Payment frequency',
				whenPaymentsAreDue: 'When payments are due',
				lateCharge: 'Late charge',
				ifPaymentIsNotReceived:
					'If payment is not received in full within 15 days after it is due, you will pay a late charge of $30 ($15 for Colorado residents or 5% of the loan amount for Utah residents',
				thereIsNoLateCharge: '. There is no late charge for Massachusetts residents',
				prepayment: 'Prepayment',
				ifYouPayOffAllBussinesLoan: 'If you pay off all or part of the loan early, you will not have to pay a penalty.',
				ifYouPayOffAll:
					'If you pay off all or part of the loan early, you will not be entitled to a rebate of part of the One Time Up-Front Finance Charge.',
				ifYouPayOffAllOfParte:
					'If you pay off all or part of the loan early, you may be entitled to a partial refund of the unearned portion of any origination fee (if one was charged) that exceeds 5% of the Final Amount Financed. If you are a resident of Colorado, South Dakota or Georgia, for loans above $3,000, and you prepay all of the loan early, you will not be	entitled to a refund of any finance charge.',
				seeYouContractTerms:
					'SEE YOUR CONTRACT TERMS FOR ANY ADDITIONAL INFORMATION ABOUT NONPAYMENT, DEFAULT, ANY REQUIRED REPAYMENT IN FULL BEFORE THE SCHEDULED DUE DATE, NO PREPAYMENT REBATES OR PENALTIES.',
				seeYouLoanAgreement:
					'See your loan agreement terms for any additional information about nonpayment, default, any required repayment in full before the scheduled due date, and prepayment refunds and penalties.',
				thePaymentSchedule:
					'The Payment Schedule and all above numerical disclosures are estimates and may change based upon the final amount financed and the date the loan is funded.',
				itemizationOfEstimated: defineMessage<{ br: string | React.ReactElement }>({
					id: 'sdocs.itemizationOfEstimated',
					defaultMessage: 'Itemization of Estimated Amount{br}Financed',
				}),
				amountGivenDirectly: '1. Amount given directly to you',
				amountPaidOnYour: '2. Amount paid on your account with us',
				amountPaidToOther: '3. Amount paid to others on your behalf',
				amountFinancedEstimated: '4. Amount financed',
				oneFixedFinance: 'One Fixed Finance Charge (Prepaid Finance Charge)',
				prepaidFinanceCharge: 'Prepaid Finance Charge',
				theAmountsDisclosedWorkCapital:
					'The amounts disclosed in this itemization are estimates. Your Final Disbursed Loan Amount will not exceed the Disbursed Loan Amount disclosed above, but it may be less',
				theAmountsDosclosed:
					'The amounts disclosed in this Itemization are estimates. Your Final Amount Financed will not exceed the Amount Financed disclosed above, but it may be less.',
			},
			tilaUnsignedContract: {
				consentToElectronic: 'Consent to electronic communications',
				byClickingThe: defineMessage<{ link: TagPlaceholderCallback }>({
					id: 'byClickingThe.tilaUnsignedContract',
					defaultMessage:
						'By clicking the box, you agree that you have reviewed and agree to the <link>Consent to electronic communications</link> terms (click blue hyperlink to view), and agree to affix your electronic signature thereto, which provide among others things that communications, notices, documents, and signatures on documents may be done via electronic means and format. You further agree that your electronic signature shall bind you in the same manner and to same extent as a physical signature would do, in accordance with the Electronic Signature in Global and National Commerce Act (ESIGN) to the extent applicable.',
				}),
				clickBlueHyperLink: defineMessage<{ isBusinessLoanOrIsWorkingCapital: boolean }>({
					id: 'sdocs.clickBlueHyperLink',
					defaultMessage: `{isBusinessLoanOrIsWorkingCapital, select,
						true {(click blue hyperlink to view), (2) that signing below will affix your electronic signature to the LOAN AGREEMENT (3) that the LOAN AGREEMENT is conditional upon final approval by the lender.}
						false {(click blue hyperlink to view), (2) that signing below will affix your electronic signature to the LOAN AGREEMENT, (3) to a credit check inquiry that may affect your credit score, and (4) that the LOAN AGREEMENT is conditional upon final approval by the lender.}
					}`,
				}),
				byClickingTheBoxYouAgree: defineMessage<{
					link: TagPlaceholderCallback;
					component: string | React.ReactElement;
					isBusinessLoan: boolean;
					isWeb: boolean;
					label: TagPlaceholderCallback | undefined | null;
				}>({
					id: 'sdocs.byClickingTheBoxYouAgree',
					defaultMessage: `{isBusinessLoan, select,
						true {By clicking the box, you agree (1) that you have reviewed and agree to the terms set forth in the {link} {component} (click blue hyperlink to view), (2) that you will not use the loan proceeds for personal, family, household, or consumer purposes, (3) that signing below will affix your electronic signature to the {isWeb, select, true {{link}} other {{link}}} that you are authorized to sign on behalf of the business, and (4) that the {link} is conditional upon final approval by the lender.}
						other {By clicking the box, you agree (1) that you have reviewed and agree to the terms set forth in the {label} {component} (click blue hyperlink to view), (2) that signing below will affix your electronic signature to the {label} (3) to a credit check inquiry that may affect your credit score, and (4) that the {isWeb, select, true {<label>LOAN AGREEMENT</label>} other {<link>LOAN AGREEMENT</link>}} is conditional upon final approval by the lender.}

					}`,
				}),
			},
			tilaLoanAggreement: {
				pleaseReviewYour: 'PLEASE REVIEW YOUR',
				toReadAllOfTheLoan:
					'TO READ ALL OF THE LOAN TERMS AND IMPORTANT DISCLOSURES ABOUT THE LOAN INCLUDING LATE CHARGES, PAYMENT AUTHORIZATION, ARBITRATION REQUIREMENTS AND CERTAIN STATE DISCLOSURES.',
				signLoanAgreement: 'Sign loan agreement & consent to electronic communications',
			},
			tilaSignature: {
				yourAboveSignature: `Your above signature will be placed on the Consent to Electronic Communications and LOAN AGREEMENT and the documents will be emailed to you after you click the below "SIGN LOAN AGREEMENT & CONSENT TO ELECTRONIC COMMUNICATIONS" button.`,
			},
			ifPaymentIsNotReceived: defineMessage<{ isDTC: boolean }>({
				id: 'sdocs.ifPaymentIsNotReceived',
				defaultMessage: `{isDTC, select,
				false {If payment is not received in full within 15 days after it is due, you will pay a late charge of $30 ($15 for Colorado residents or 5% of the loan amount for Utah residents).}
				true {If payment is not received in full within 15 days after it is due, you will pay a late charge of $30 ($15 for Colorado residents or 5% of the loan amount for Utah residents. There is no late charge for Massachusetts residents).}
			}`,
			}),
		},
		whatsNext: {
			title: `You're all set.`,
			thankYou: defineMessage<{ email: string; br: string | React.ReactElement }>({
				id: 'whatsNext.thankYou',
				defaultMessage: `	Thank you for being the best part of LendingPoint. {br} You will soon receive a copy of your contract at {email}.`,
			}),
			yourNextPaymentOf: 'Your next payment of',
			willBeDueOn: 'Will be due on',
			withThisNew: 'With this new loan you get',
			more: ' more',
			yearlyPayment: 'Yearly payment savings:',
			youWillRecive: defineMessage<{ br: string | React.ReactElement }>({
				id: 'whatsNext.youWillRecive',
				defaultMessage: `You will receive a reminder via email 10 days {br}	prior to your next due date. As a paperless company, we do not send statements by mail.`,
			}),
		},
		congrats: {
			title: defineMessage<{ firstName: string }>({
				id: 'congrats.title',
				defaultMessage: 'Good news, {firstName}!',
			}),
		},
	},
} as const;

export default messagesWeb;
