import { nanoid } from 'nanoid';

import type { LoanDetailsType } from '../../loans/utils';
import type { RefinanceOffer } from '../utils';

import {
	EbaySellerType,
	eBaySellerTypes,
	frequencyValueMap,
	OpportunitySubSources,
	REPAYMENT_FREQUENCY,
	YEARLY_SAVING_MIN_VALUE,
} from '../constants';

type YearlySavingsParams = {
	details?: LoanDetailsType | null;
	offer: RefinanceOffer;
};

export function groupOffersBy(arr: Array<RefinanceOffer>, key: keyof RefinanceOffer) {
	const typeGroups: Record<string | number, Array<RefinanceOffer>> = {};
	for (const item of arr) {
		const value = item[key];
		if (key in item && value) typeGroups[value] = (typeGroups[value] || []).concat(item);
	}
	return typeGroups;
}
/**
 * @deprecated this calculation is already made from the backend
 * @param param0
 * @returns
 */
export const calculateYearlySaving = ({ details, offer }: YearlySavingsParams) => {
	try {
		const frequency = details && frequencyValueMap[details.frequency?.toUpperCase()];
		if (frequency && details && details.paymentAmount * frequency > offer.paymentAmount * 12 && details.remainingTerm > 12) {
			const yearlySavings = details.paymentAmount * frequency - offer.paymentAmount * 12;
			if (yearlySavings >= YEARLY_SAVING_MIN_VALUE) return yearlySavings;
			else {
				return;
			}
		}
	} catch (error) {
		return;
	}
};

export const generateOfferExternalId = () => nanoid();

export const getOfferByExternalId = (offers: Array<RefinanceOffer>, externalId: string) =>
	offers.find((offer) => offer.externalId === externalId);

export const hasEbaySellerType = (sellerType?: string) =>
	!!sellerType && eBaySellerTypes.indexOf(sellerType as EbaySellerType) > -1;

export const parseRepaymentFrequencyOffer = (repaymentFrequency: string): string =>
	repaymentFrequency === REPAYMENT_FREQUENCY.MONTHLY
		? 'Monthly payment amount'
		: repaymentFrequency === REPAYMENT_FREQUENCY['28 DAYS']
		? 'Payment amount every 28 Days'
		: repaymentFrequency === REPAYMENT_FREQUENCY['BI-WEEKLY']
		? 'Bi-Weekly payment amount'
		: repaymentFrequency === REPAYMENT_FREQUENCY['SEMI-MONTHLY']
		? 'Semi-Monthly payment amount'
		: '';

export const parseOffersResponse = (offers: RefinanceOffer[]) =>
	offers.map((offer) => ({
		...offer,
		repaymentFrequency: parseRepaymentFrequencyOffer(offer.repaymentFrequency),
	}));

export const isRefinanceEbaySubSource = (subSource?: string) =>
	!!subSource && subSource === OpportunitySubSources.EBAY_LOAN_REFINANCE;
