// override javascript functions for adding nonce to injected scripts/css
declare global {
	interface Window {
		dataLayer: { [key: string]: { [key: string]: string } };
	}
}

export function insertBeforeOverride() {
	const metaNonce = window.dataLayer[0].gtm_nonce;
	const insertBefore = Element.prototype.insertBefore;

	(Element as any).prototype.insertBefore = function (newNode: HTMLScriptElement, existingNode: Node) {
		if (newNode.baseURI.indexOf(window.location.host) !== -1 && newNode.nodeName === 'SCRIPT' && newNode.src === '') {
			// eslint-disable-next-line prefer-rest-params
			arguments[0].setAttribute('nonce', metaNonce);
		}
		insertBefore.apply(this, [newNode, existingNode]);
	};
}
