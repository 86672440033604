import { lendingpoint } from '../../../services';

import type { ApiResponse } from '../../../services/utils';
import type { MailGetAllResponse, MailGetDetailsResponse, MailId } from '../utils';
import type { EmailComposerFormValues } from '../../support/utils';

const {
	api: { consumer },
	constants: { paths },
	helpers: { handleSubModuleError },
} = lendingpoint;

export const getMails = async (): Promise<ApiResponse<MailGetAllResponse>> => {
	try {
		const response = await consumer.get(paths.mails);
		return { success: true, response: response.data };
	} catch (err) {
		return handleSubModuleError(err);
	}
};

export const getMailDetail = async (mailId: MailId): Promise<ApiResponse<MailGetDetailsResponse>> => {
	try {
		const response = await consumer.get(`${paths.mails}/${mailId}`);
		return { success: true, response: response.data };
	} catch (err) {
		return handleSubModuleError(err);
	}
};

export const deleteMail = async (mailId: number): Promise<ApiResponse> => {
	try {
		const response = await consumer.delete(`${paths.mails}/${mailId}`);
		return { success: true, response: response.data };
	} catch (err) {
		return handleSubModuleError(err);
	}
};

export const replyMail = async (mailId: MailId, emailFormValues: EmailComposerFormValues): Promise<ApiResponse> => {
	try {
		const emailFormData = new FormData();
		emailFormData.append('mailSubject', emailFormValues.subject.replace('RE:', 'REPLY')); // error if subject has RE:
		emailFormData.append('mailBody', emailFormValues.message);

		const response = await consumer.post(`${paths.mails}/${mailId}`, emailFormData);
		return { success: true, response: response.data };
	} catch (err) {
		return handleSubModuleError(err);
	}
};

export const composeMail = async (
	overDueDays: number,
	emailFormValues: EmailComposerFormValues,
	contractId: string,
): Promise<ApiResponse> => {
	try {
		const emailFormData = new FormData();
		emailFormData.append('mailSubject', emailFormValues.subject);
		emailFormData.append('mailBody', emailFormValues.message);
		//@ts-ignore
		emailFormData.append('overDueDays', overDueDays);
		const response = await consumer.post(`${paths.loans}/${contractId}/mails`, emailFormData);
		return { success: true, response: response.data };
	} catch (err) {
		return handleSubModuleError(err);
	}
};
