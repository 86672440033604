/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { configureStore, ThunkAction, Action, getDefaultMiddleware, AsyncThunkAction } from '@reduxjs/toolkit';
import { persistStore, persistReducer, REHYDRATE, FLUSH, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

import * as Sentry from '@cp/common/shared/services/Sentry';
import { persistConfigRoot } from '@cp/common/config/persist';
import Reactotron from '@cp/common/config/reactotron';
import { masked, sensitiveKeys } from '@cp/common/utils/services';

import * as payments from '@cp/common/payments/constants';
import * as profile from '@cp/common/profile/constants';

import rootReducer from './rootReducer';

import paymentsTypes from '@cp/common/payments/store/actions/types';
import {useDispatch} from 'react-redux'

const blackListActions = [REHYDRATE, paymentsTypes.PAYMENT_METHODS.SUCCESS, paymentsTypes.PAYMENT_METHODS.UPDATE];

const persistedReducer = persistReducer(persistConfigRoot, rootReducer) as typeof rootReducer;

// Create sentry enhancer to emit breadcrumbs and masked sensitive data for security reasons ignore some actions
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
	stateTransformer: (state: RootState) => ({
		...state,
		[payments.NAME]: {
			details: null,
		},
		[profile.NAME]: { userInfo: masked(state?.[profile.NAME]?.userInfo as any, sensitiveKeys.profile) },
	}),
	actionTransformer: (action) =>
		blackListActions.includes(action.type) ? { type: action.type, payload: action.payload ? '[masked]' : undefined } : action,
});

// Add reactotron enhancer
//@ts-ignore fix mobile compile
const reactotronReduxEnhacer = Reactotron?.createEnhancer?.();

// Filter Enhancers

const enhancers = [sentryReduxEnhancer, reactotronReduxEnhacer].filter(Boolean);

const middleware = getDefaultMiddleware({
	serializableCheck: {
		ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
	},
});

export const store = configureStore({
	reducer: persistedReducer,
	middleware,
	enhancers,
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type AppAsyncThunkAction = ReturnType<AsyncThunkAction<any, any, any>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();