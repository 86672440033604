import { LoadingStatus, VerifyIdentityState as VerifyIdentityStateType } from './type';

export const verifyIdentityInitialState: VerifyIdentityStateType = {
	currentTry: 1,
	alreadySkipped: false,
	loadingStatusFetchKYCQuestions: LoadingStatus.NOT_LOADED,
	loadingStatusSaveKYCAnswers: LoadingStatus.NOT_LOADED,
	errorFetchKYCQuestions: null,
	errorSaveKYCAnswers: null,
	dataFetchKYCQuestions: {},
	dataSaveKYCAnswers: {},
	kYCQuestions: [],
};
