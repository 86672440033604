import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';

import * as authActions from '@cp/common/modules/auth/store/actions';
import { persistConfigInactivity } from '@cp/common/config/persist';
import { setAutoSignoutTimestamp } from '../actions/actions';

interface InactivityState {
	autoSignoutTimestamp: number;
}

const initialState: InactivityState = {
	autoSignoutTimestamp: 0,
};

const inactivityReducer = createReducer(initialState, (builder) => {
	builder.addCase(setAutoSignoutTimestamp, (state, action) => {
		state.autoSignoutTimestamp = action.payload;
	});
	builder.addCase(authActions.thunks.signIn.fulfilled, (state, action) => {
		if (action.payload.success) {
			state.autoSignoutTimestamp = Date.now();
		}
	});
	builder.addCase(authActions.thunks.signOut.pending, (state) => {
		state.autoSignoutTimestamp = 0;
	});
});

const inactivityPersistReducer = persistConfigInactivity
	? (persistReducer(persistConfigInactivity, inactivityReducer) as unknown as typeof inactivityReducer)
	: inactivityReducer;

export default inactivityPersistReducer;
