import * as Yup from 'yup';

import { stateValues } from '@cp/common/shared/constants';

import { EbaySellerType } from './enums';
export { default as messagesWeb } from './messages.web';
export const NAME = 'refinance';

export * from './enums';

export const eBaySellerTypes = Object.values(EbaySellerType) as string[];

export const frequencyValueMap: { [key: string]: number } = {
	MONTHLY: 12,
	'SEMI-MONTHLY': 24,
	'BI-WEEKLY': 26,
	'28 DAYS': 13,
};

export const YEARLY_SAVING_MIN_VALUE = 200;

export const EXTRA_MONEY_MIN_VALUE = 50;

export const formsKeys = {
	persolaInfo: {
		firstName: 'firstName',
		lastName: 'lastName',
		email: 'email',
		phoneNumber: 'phoneNumber',
		street: 'street',
		address2: 'address2',
		city: 'city',
		state: 'state',
		postalCode: 'postalCode',
	},
	incomeVerification: {
		annualIncome: 'annualIncome',
		employementType: 'employementType',
		companyName: 'companyName',
		position: 'position',
		employmentStartDate: 'employmentStartDate',
		companyPhone: 'companyPhone',
	},
} as const;

const refinanceEbayOffersOptionsSchema = Yup.object({
	maxRefinanceDisbursal: Yup.boolean(),
	RefinanceVarianceMinPayment: Yup.boolean(),
	LowerRate: Yup.boolean(),
});

export const forms = {
	refinanceEbayOffersOptions: {
		initialValues: {
			maxRefinanceDisbursal: false,
			RefinanceVarianceMinPayment: false,
			LowerRate: false,
		},
		schema: refinanceEbayOffersOptionsSchema,
	},
	refinanceProfileInfo: {
		initialValues: {
			[formsKeys.persolaInfo.street]: '',
			[formsKeys.persolaInfo.address2]: '',
			[formsKeys.persolaInfo.city]: '',
			[formsKeys.persolaInfo.state]: '',
			[formsKeys.persolaInfo.postalCode]: '',
		},
		labels: {
			firstName: 'First name',
			lastName: 'Last name',
			email: 'Email',
			phoneNumber: 'Phone',
			city: 'City',
			postalCode: 'ZIP code',
			street: 'Address',
			address2: 'Address 2',
			state: 'State',
		},
		placeholders: {
			firstName: 'John',
			lastName: 'Smith',
			email: 'myemail@email.com',
			phoneNumber: '(999) 999-9999',
			street: '220 Locus Avenue',
			address2: 'Apt #, Suite, etc. (optional)',
			city: 'Atlanta',
			state: 'Select...',
			postalCode: '30318',
		},
		schema: Yup.object({
			street: Yup.string().required().ensure().trim().lowercase(),
			address2: Yup.string().ensure().trim().lowercase(),
			city: Yup.string().required().ensure().trim().lowercase(),
			// TODO: validate validation logic
			state: Yup.string().required().ensure().trim().length(2).uppercase().oneOf(stateValues),
			postalCode: Yup.string().ensure().trim().lowercase().required(),
			phoneNumber: Yup.string().ensure().trim().lowercase().required(),
		}),
	},
};

export enum OpportunitySubSources {
	EBAY_LOAN_REFINANCE = 'eBayLoan Refinance',
}

export enum EbayOfferType {
	WORKING_CAPITAL = 'WC',
	BUSINESS_LOAN = 'INST_EBAY',
}

export enum experimentGrouptype {
	CONTROL = 'IRFee_Control',
	TEST1 = 'IRFee_Test1',
	TEST2 = 'IRFee_Test2',
}
export { default as messages } from './messages';

export const formKeys = {
	refinanceEbayOffersOptions: {
		maxRefinanceDisbursal: 'maxRefinanceDisbursal',
		RefinanceVarianceMinPayment: 'RefinanceVarianceMinPayment',
		LowerRate: 'LowerRate',
	},
};
