import { createAsyncThunk } from '@reduxjs/toolkit';
import { isString } from 'lodash';

import { setPaymentsMissed } from '@cp/common/modules/payments/store/actions/actions';
import { PaymentMissedState } from '@cp/common/modules/payments/utils';

import type { ApiResponse } from '@cp/common/services/utils';
import type { RootState } from '@cp/common/store';
import type { AxiosRequestConfig } from 'axios';
import type { Loan, LoanDetailsType, LoansResponse, QuickActionsRoutesName, VirtualCardDetails } from '../../utils';

import { loanDetailsResponseParser, loansResponseParser } from '../../helpers';
import * as loanServices from '../../services/loans';

type GetQuickLinksOptions = {
	loanId?: string;
	lpScreenName: QuickActionsRoutesName;
	config?: AxiosRequestConfig;
};
type UpdateLoanDetailsOptions = { loanId: string; loanNickname: string; config?: AxiosRequestConfig };

export const getAll = createAsyncThunk(
	'loans/getLoanAccounts',
	async (
		config: AxiosRequestConfig | undefined,
		{ dispatch },
	): Promise<ApiResponse<Omit<LoansResponse, 'loanAccounts'> & { loanAccounts: Loan[] }>> => {
		const res = await loanServices.getLoans(config);
		if (res.success && res.response) {
			const loanAccounts = loansResponseParser(res.response.loanAccounts);
			const paymentsMissed: Array<PaymentMissedState> = [];
			const loansTransform = await Promise.all(
				loanAccounts.map(async (loan) => {
					if (!!loan.totalMissedPayments && isString(loan.loanId)) {
						const results = await loanServices.getMissedPaymentsOK(config);
						const details = results.response?.details;

						// validate details it's right
						if (Array.isArray(details) && details.length) {
							paymentsMissed.push({ loanId: loan.loanId, payments: details });
						}

						const paymentDueDate = details?.sort(
							(a, b) => new Date(a.paymentDueDate).getTime() - new Date(b.paymentDueDate).getTime(),
						)[0].paymentDueDate;

						return {
							...loan,
							paymentDueDate,
						};
					} else {
						return loan;
					}
				}),
			);

			dispatch(setPaymentsMissed(paymentsMissed));
			return {
				...res,
				response: {
					...res.response,
					loanAccounts: loansTransform,
				},
			};
		}
		return {
			success: false,
			error: res.error,
			errorMessage: res.errorMessage,
			response: undefined,
		};
	},
	{
		condition: (_, { getState }) => {
			const { loans } = getState() as RootState;
			if (loans.list.loading === 'pending') {
				// Already fetched or in progress, don't need to re-fetch
				return false;
			}
		},
	},
);

export const getDetails = createAsyncThunk(
	'loans/getDetails',
	async ({ loanId, config }: { loanId: string; config?: AxiosRequestConfig }): Promise<ApiResponse<LoanDetailsType>> => {
		const res = await loanServices.getLoanDetails(loanId, config);
		if (res.success && res.response) {
			const details = loanDetailsResponseParser(loanId, res.response);
			const normalizeResponse = { ...res.response, ...details };
			return { success: true, response: normalizeResponse };
		}
		return { success: false, error: res.error };
	},
);

export const getVirtualCardDetails = createAsyncThunk(
	'loans/getVirtualCardDetails',
	async (applicationId: string): Promise<ApiResponse<VirtualCardDetails>> => {
		const res = await loanServices.getVirtualCardDetails(applicationId);
		if (res.success && res.response) {
			return { success: true, response: res.response.virtualCard };
		}
		return { success: false, error: res.error };
	},
);

export const getCompletedLoans = createAsyncThunk(
	'loans/getCompletedLoans',
	async (config: AxiosRequestConfig | undefined) => loanServices.getCompletedLoans(config),
	{
		condition: (_, { getState }) => {
			const { loans } = getState() as RootState;
			if (loans.completed.loading === 'pending') {
				// Already fetched or in progress, don't need to re-fetch
				return false;
			}
		},
	},
);

export const getQuickLinks = createAsyncThunk(
	'loans/getQuickLinks',
	async ({ loanId, lpScreenName, config }: GetQuickLinksOptions) =>
		loanId
			? loanServices.getQuickLinksByLoan({ loanId, lpScreenName, requestConfig: config })
			: loanServices.getUserQuickLinks({ lpScreenName, requestConfig: config }),
);

export const updateLoanDetails = createAsyncThunk(
	'loans/updateLoanDetails',
	async ({ loanId, loanNickname, config }: UpdateLoanDetailsOptions) =>
		loanServices.updateLoanDetails({ loanId, loanNickname }, config),
);
