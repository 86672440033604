import { createAsyncThunk } from '@reduxjs/toolkit';

import { AddPaymentMethodResponse } from '@cp/common/modules/payments/utils';
import { ApiResponse } from '@cp/common/services/utils';

import * as api from '../../services';
import { BankAccountInfo, InfoBankAccountQuery } from '../../utils';

export const saveBankAccountInfo = createAsyncThunk(
	'bankAccount/saveBankAccountInfo',
	async (
		reqData: {
			bankAccountInfo: BankAccountInfo;
			applicationId: string;
		},
		{ rejectWithValue },
	): Promise<ApiResponse<AddPaymentMethodResponse>> => {
		const response = await api.saveBankAccountInfo(reqData.bankAccountInfo, reqData.applicationId);
		if (!response.success) throw rejectWithValue(response);
		return response;
	},
);

export const getBankAccountInfo = createAsyncThunk(
	'bankAccount/getBankAccountInfo',
	async (reqData: InfoBankAccountQuery, { rejectWithValue }) => {
		const response = await api.getBankAccountInfo(reqData);
		if (!response.success) throw rejectWithValue(response);
		return response;
	},
);
