import React from 'react';

import config from '@cp/common/config';

const FooterScreen: React.FC = () => {
	return (
		<footer className="uk-background-light no-print">
			<div className="uk-section uk-section-default uk-section-xsmall">
				<div className="uk-container uk-container-small lp-foot">
					<div>© {new Date().getFullYear()} LendingPoint LLC. All rights reserved.</div>
					<div className="uk-text-border">
						<a className="uk-link" href={`${config.tcUrl}`} target="_blank">
							Terms of Use
						</a>{' '}
						|{' '}
						<a className="uk-link" href={config.lpPrivacyPolicyUrl} target="_blank">
							Privacy Notice
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default FooterScreen;
