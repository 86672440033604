import type { AxiosError, AxiosRequestConfig } from 'axios';

import { lendingpoint } from '../../../services';
import { ApiResponse } from '../../../services/utils';
import { documentsResponseParser } from '../helpers';
import { DocumentType, User, UserResponse } from '../utils';
import { SingleUserDocumentsResponseType } from './utils';

const {
	api: { consumer },
	constants: { paths },
	helpers: { handleSubModuleError },
} = lendingpoint;

export const getAllUserDocuments = async (): Promise<ApiResponse<DocumentType[]>> => {
	try {
		const response = await consumer.get(paths.documents, {
			'axios-retry': {
				retries: 1,
			},
		});
		return { success: true, response: documentsResponseParser(response.data) };
	} catch (err) {
		return handleSubModuleError(err);
	}
};

export const getSingleUserDocument = async (documentId: string): Promise<ApiResponse<SingleUserDocumentsResponseType>> => {
	try {
		const response = await consumer.get<SingleUserDocumentsResponseType>(`${paths.documents}/${documentId}`, {
			'axios-retry': {
				retries: 1,
			},
		});
		return { success: true, response: response.data };
	} catch (err) {
		return handleSubModuleError(err);
	}
};

export const getUserInfo = async (config?: AxiosRequestConfig): Promise<ApiResponse<UserResponse>> => {
	try {
		const response = await consumer.get<UserResponse>(paths.user, {
			'axios-retry': {
				retries: 1,
			},
			...config,
		});
		return { success: true, response: response.data };
	} catch (err) {
		return handleSubModuleError(err);
	}
};

// export const updateUserInfo = async ({
// 	annualIncome,
// 	employementType,
// 	companyName,
// 	position,
// 	employmentStartDate,
// 	companyPhone,
// }: {
// 	annualIncome: string;
// 	employementType: string;
// 	companyName?: string;
// 	position?: string;
// 	employmentStartDate?: string;
// 	companyPhone?: string;
// }): Promise<HTTPServicesResponse<any>> => {
// 	try {
// 		const { data } = await consumer.patch<{}>(paths.user, {
// 			annualIncome,
// 			employementType,
// 			companyName,
// 			position,
// 			employmentStartDate,
// 			companyPhone,
// 		});
// 		return { success: true, payload: data };
// 	} catch (err) {
// 		const { success, error, errorMessage } = handleSubModuleError(err);
// 		const status = error?.response?.status;

// 		return {
// 			success,
// 			errorMessage: errorMessage,
// 			statusCode: status,
// 		};
// 	}
// };

export const updateUserInfo = async (user: Partial<User>): Promise<ApiResponse<Partial<User>>> => {
	try {
		await consumer.patch(paths.user, user, {
			'axios-retry': {
				retries: 2,
			},
		});
		return { success: true, response: user };
	} catch (err) {
		return handleSubModuleError(err as AxiosError);
	}
};
