import axios from 'axios';
import config from '@cp/common/config';
import { NAME, errorNames } from '../constants';
import { httpErrorLogger } from '../../../middlewares';

const api = axios.create({
	baseURL: config.lpApps.host,
});

api.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		httpErrorLogger(NAME, errorNames.general, error);

		return Promise.reject(error);
	},
);

export default api;
