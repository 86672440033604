import React from 'react';
import * as CryptoJS from 'crypto-js';

import type { MessageDescriptor } from '@formatjs/intl';

export const decryptData = (data: string, key: string) => {
	try {
		const bytes = CryptoJS.AES.decrypt(data, key);
		const response = bytes.toString(CryptoJS.enc.Utf8);
		return JSON.parse(response);
	} catch (error) {
		return undefined;
	}
};

// as seen in AMCP-245
export const formatDateOptions = [
	'MMM D, YYYY',
	'dddd, MMM D, YYYY',
	'MMMM D, YYYY',
	'YYYY-MM-DD',
	'dddd, [\n]MMMM D, YYYY',
	'dddd MMMM D, YYYY',
	'MMMM YYYY',
	'MM-DD-YYYY',
	'MM/DD/YYYY',
	'dddd, [\n]MMMM D',
	'MMMM D',
	'dddd, MMMM D, YYYY',
	'D',
	'Do',
] as const;

export type ComponentFetchingErrorType = {
	failed: boolean;
	details?: string;
};

export type NavigationHistoryEntry = {
	previousRouteName: string | null;
	currentRouteName: string | null | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface MessageDescriptorOptions<T = void, P = void> extends Omit<MessageDescriptor, 'description'> {}

export type TagPlaceholderCallback<T = string | React.ReactElement> = (chunks: T[]) => React.ReactNode;

export type RequestLoadingStatus = 'idle' | 'pending';

export const rand = () => Math.round(Math.random());

// type for token api services
export type IApiGenerateToken = {
	uuid: string;
	accessToken: string;
	refreshToken: string;
	tokenType: string;
	expires: number;
	status?: number;
	tokenMiddle?: string;
};
