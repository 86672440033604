import { defineMessage } from '../../shared/helpers';
import { TagPlaceholderCallback } from '../../shared/utils';

const messages = {
	navbar: {
		greeting: defineMessage<{ name: TagPlaceholderCallback }>({
			id: 'navbar.greeting',
			defaultMessage: 'Hello, {name}',
		}),
		supports: {
			title: 'Support',
			subItems: {
				call: 'Call',
				callUs: defineMessage<{ link: TagPlaceholderCallback }>({
					id: 'supports.calPhone',
					defaultMessage: '<link>contactPhone</link>',
				}),
				email: 'Email Us',
				knowledgeCenter: 'Knowledge Center',
			},
		},
		payments: {
			title: 'Payments',
			subItems: {
				paymentCenter: 'Payment Center',
				makeAPayment: 'Make a Payment',
				manageAutopay: 'Manage Autopay',
			},
		},
		profile: {
			title: 'Profile',
			subItems: {
				personalInformation: 'Personal Information',
				settings: 'Settings',
				documents: 'Documents',
				paymentMethods: 'Payment Methods',
				completedLoans: 'Completed Loans',
				signOut: 'Sign Out',
			},
		},
	},
} as const;

export default messages;
