import { combineReducers } from 'redux';

import deviceInfoReducer from './deviceInfo.reducer';
import forceUpdateReducer from './forceUpdate.reducer';
import inactivityReducer from './inactivity.reducer';
import navigationHistoryReducer from './navigationHistory.reducer';
import remoteConfigReducer from './remoteConfig.reducer';

const sharedReducer = combineReducers({
	device: deviceInfoReducer,
	forceUpdate: forceUpdateReducer,
	navigationHistory: navigationHistoryReducer,
	inactivity: inactivityReducer,
	remoteConfig: remoteConfigReducer,
});

export default sharedReducer;

export type SharedState = ReturnType<typeof sharedReducer>;
