/* eslint-disable no-undef */
import React from 'react';
import { Field } from 'formik';
import UIkit from 'uikit';

import IcoCheck from '@images/check.png';
import IcoCard from '@images/debitcard-cvv.png';

import UIKitModal from './UIKitModal.component';

const ModalInput = ({
	icoInput,
	modalName,
	iconInputSmall,
}: {
	icoInput?: string;
	modalName?: string | undefined;
	iconInputSmall?: boolean;
}): JSX.Element => (
	<>
		<a
			className="uk-form-icon uk-form-icon-flip"
			href="/"
			target="_blank"
			rel="noreferrer"
			onClick={(e) => {
				e.preventDefault();
				UIkit.modal(`#info-check`).show();
			}}
		>
			<img src={icoInput} alt="info-check" className={iconInputSmall ? 'img-small ' : ''} />
		</a>

		<UIKitModal id="info-check" className="lp-modal bank-modal uk-flex-top" uk-modal="true">
			<div className="uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
				<button className="uk-modal-close-default" type="button" uk-close="true" />
				<div className="uk-modal-body uk-text-center uk-overflow-hidden">
					{modalName === 'debitCard' ? (
						<>
							<img className="cvv-img uk-width-medium uk-margin-small-top" src={IcoCard} alt="card" />
							<p className="uk-text-emphasis uk-text-small uk-margin-remove">
								The card verification value (CVV) is a set of 3-4 digit numbers on the back of your card.
							</p>
						</>
					) : (
						<img className="check-img uk-width-large uk-margin-small-top" src={IcoCheck} alt="check" />
					)}
				</div>
			</div>
		</UIKitModal>
	</>
);

type Props = {
	iconInputSmall?: boolean;
	icoInput?: string;
	labelInput?: string;
	placeHolder?: string;
	name?: string;
	value?: string | number;
	isDisabled?: boolean;
	isError?: string | undefined;
	isTouched?: boolean;
	modalName?: string | undefined;
	testID?: string | undefined;
	type?: 'number' | 'password' | 'email' | 'text';
};

const InputWithIcon: React.FC<Props> = ({
	icoInput,
	labelInput,
	placeHolder,
	name,
	value,
	isDisabled,
	isError,
	isTouched,
	modalName = 'bankAccount',
	testID,
	iconInputSmall = false,
	type,
}) => {
	const inputClass = isError && isTouched ? 'uk-input border-error-input' : 'uk-input';

	return (
		<div className="lp-form-field">
			<div className="uk-form-controls">
				<ModalInput icoInput={icoInput} modalName={modalName} iconInputSmall={iconInputSmall} />
				<Field
					className={inputClass}
					type={type ? type : 'text'}
					placeholder={placeHolder || ' '}
					name={name}
					value={value ? value : null}
					disabled={isDisabled}
					id={testID}
				/>
				<label className="uk-form-label">{labelInput}</label>
			</div>
			{isError && isTouched && (
				<div className="lp-error">
					<p>{isError}</p>
				</div>
			)}
		</div>
	);
};

export default InputWithIcon;
