import axios from 'axios';

import { httpErrorLogger } from '@cp/common/services/middlewares';

import type { AxiosError } from 'axios';
import type { HTTPServicesResponse } from '../../../utils';

import { getErrorMessage } from '../../../helpers';
import { errorNames, NAME } from '../constants';

// TODO REPLACE WITH HTTPServicesResponse
export const handleSubModuleError = (error: AxiosError): HTTPServicesResponse => {
	/*     if(__DEV__)
    console.log(`httpErrorLogger: API-ERROR-${NAME}-${errorNames.unexpected}`, error?.message, error?.stack); */
	return { success: false, errorMessage: getErrorMessage(error), error };
};

export const handleRejectedHttpErrors = (error: AxiosError) => {
	const status = error?.response?.status || 0;
	const canceled = !!error && axios.isCancel(error);
	if (!canceled) {
		if (status === 401 || status === 403) {
			httpErrorLogger(NAME, errorNames.unauthorized, error);
		} else if (status > 401 || status >= 500) {
			// general error
			httpErrorLogger(NAME, errorNames.general, error);
		}
	}
	if (__DEV__ && error) console.error('intercept error:', error?.toJSON(), error?.response);
	return Promise.reject(error);
};
