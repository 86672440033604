import { defineMessage } from '../../shared/helpers';
import { TagPlaceholderCallback } from '../../shared/utils';

const messagesWeb = {
	dashboard: {
		greeting: 'Hello,',
		emptyLoansLabel: 'Congrats, your loan has been paid off.',
		accountOverview: 'Account Overview',
	},
	loansCard: {
		autopay: 'Autopay',
		enrollInAutopay: 'Set up Autopay',
		dueDateTitle: 'Due Date',
		autopayScheduled: 'Autopay scheduled for',
		paymentScheduled: 'Payment due by',
		promotionalFinancing: 'Promotional Financing\nPayoff',
		todayBalance: 'Today’s Balance',
		makeExtraPayment: 'Make Extra Payment',
		makePayment: 'Make a Payment',
		viewDetails: 'View Details',
	},
	loansCardEmpty: {
		title1: "You don't have any",
		title2: 'active loans',
		applyLoan: 'Apply for a Loan',
		viewCompleteLoans: 'View Completed Loans',
		contactUs: 'Contact Us',
		callUs: defineMessage<{ link: TagPlaceholderCallback }>({
			id: 'supports.calPhone',
			defaultMessage: '<link>contactPhone</link>',
		}),
	},
	loanCardPayments: {
		payments: 'Payments',
		nextPaymentLabel: defineMessage<{ isAutopay: boolean }>({
			id: 'loanCardPayments.nextPaymentLabel',
			defaultMessage: `{isAutopay, select,
				true {Next Autopay Payment}
				other {Next Payment Due} 
			}:`,
		}),
		overDueTitle: 'Your previous payment was due on ',
		amountDue: 'Amount Due',
		buttonMakePayment: defineMessage<{ isAutopay: boolean }>({
			id: 'loanCardPayments.buttonMakePayment',
			defaultMessage: `{isAutopay, select,
				true {Make Extra Payment}
				other {Make a Payment} 
			}`,
		}),
		buttonViewPayments: 'View Payments',
		linkEnrollInAutopay: 'Set up Autopay',
	},
	transactionHistory: {
		title: 'Transaction History',
		paymentFrom: 'PAYMENT FROM:',
		buttonViewAllTransactions: 'View All Transactions',
		moreDetails: 'More Details',
	},
	editNickname: {
		title: 'Edit Loan Nickname',
		loanNicknameLabel: 'Loan Nickname',
		saveUpdates: 'Save Updates',
		cancel: 'Cancel',
	},
	loanDetails: {
		title: 'Loan Details',
		basicDetailsCard: {
			title: 'Loan details',
			todaysBalanceLabel: "Today's Balance",
			amountLabel: 'Loan Amount:',
			paymentAmountLabel: 'Payment Amount',
			termLabel: 'Term',
			interestLabel: 'Interest rate',
			feeLabel: 'Fixed fee',
			frequencyLabel: 'Payment Frequency',
			interestTooltipLabel: 'If balance is not paid in full by Same as Cash promotional period expiration date.',
			autopay: 'Autopay',
			enrollInAutopay: 'Set up Autopay',
			promotionalFinancingPayOff: 'Promotional Financing Payoff',
			promotionLenght: 'Promotion Length',
			promotionExpiration: 'Promotion Expiration',
		},
	},
	delinquentNotice: {
		title: 'Your account is past due',
		pastDuePayment: 'Past Due Payments',
		contactUs: 'Contact Us',
		callUs: defineMessage<{ link: TagPlaceholderCallback }>({
			id: 'supports.calPhone',
			defaultMessage: '<link>contactPhone</link>',
		}),
		buttonTitle: 'Continue to Dashboard',
		buttonEmail: 'Email Us',
	},
} as const;

export default messagesWeb;
