import { createAction } from '@reduxjs/toolkit';

import types from './types';

import { DocumentsTypes, RefinanceOffer } from '../../utils';

export const setSelectedOfferNew = createAction<RefinanceOffer>(types.REFINANCE_SELECTED_OFFER.SET_SELECTED_OFFER);

export const setDocuments = createAction<DocumentsTypes[] | null>(types.REFINANCE_DOCUMENTS.SET_DOCUMENTS);

export const clearOpportunity = createAction(types.REFINANCE_OPPORTUNITY.CLEAR_OPPORTUNITY);

export const clearOffers = createAction(types.REFINANCE_CLEAR.CLEAR_OFFERS);

export const clearDocuments = createAction(types.REFINANCE_DOCUMENTS.CLEAR_DOCUMENTS);
