/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { isWeb } from '@cp/common/platform';
import type { History } from 'history';

export type NavigationWeb = History;
export type NavigationOrHistory = NavigationWeb | typeof import('./navigation.native');
export type ParamsOrState = Record<string, any>;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const navigationOrHistory: NavigationOrHistory = require('./navigation').default;

export const isNavigationWeb = (obj: any): obj is NavigationWeb => isWeb;

const navigate = (routeNameOrPath: string, paramsOrState?: ParamsOrState) => {
	if (isNavigationWeb(navigationOrHistory)) navigationOrHistory.push(routeNameOrPath, paramsOrState);
	else navigationOrHistory.navigate(routeNameOrPath, paramsOrState);
};

const replace = (routeNameOrPath: string, paramsOrState?: ParamsOrState) => {
	navigationOrHistory.replace(routeNameOrPath, paramsOrState);
};

const push = (path: string, state: ParamsOrState) => {
	navigationOrHistory.push(path, state);
};

const getActiveRouteName = () =>
	isNavigationWeb(navigationOrHistory) ? navigationOrHistory.location.pathname : navigationOrHistory.getActiveRouteName();

export default {
	navigate,
	push,
	replace,
	getActiveRouteName,
	navigationOrHistory,
	isNavigationWeb,
};
