export enum LoanIndicator {
	/**
	 * @description Refinance Qualified
	 */
	RFQ = 'RFQ',
	/**
	 * @description Refinance Qualified but Ineligible for Refinance at this time
	 */
	RFQU = 'RFQU',
	/**
	 * @description Multi-Loan Qualified
	 */
	MLQ = 'MLQ',
	/**
	 * @description Do not touch, this customer is not a Good customer.
	 */
	DNT = 'DNT',
}
