import React from 'react';

import config from '@cp/common/config';
import shared from '@cp/common/shared';
import { messagesWeb } from '@cp/common/support/constants';
import IcoError from '@images/badge-desk-error.svg';
import ButtonGeneric from '@shared/components/ButtonGeneric';

type Props = {
	handleTryAgain: () => void;
};

const EmailComposerError: React.FC<Props> = ({ handleTryAgain }) => {
	return (
		<div className="uk-modal-body lp-help" uk-overflow-auto="true">
			<div className="lp-notice">
				<div className="app-notice uk-text-center content-header-error">
					<h1 className="uk-heading-small uk-margin-large">{messagesWeb.errorMessages.title}</h1>
					<img className="notice-img fix-icons margin-top-48" src={IcoError} alt="erro-ico" />
					<p className="uk-text-gray">
						{messagesWeb.errorMessages.body1}
						<br />
						<br />
						{messagesWeb.errorMessages.body2}{' '}
						{shared.helpers.format.formatMessage(messagesWeb.callUs, {
							link: ([contactPhone]) => {
								const phoneNumber = config[contactPhone as keyof typeof config] as string;
								return (
									<a
										className="uk-link-heading uk-text-bolder uk-text-nowrap"
										href={`tel:${shared.helpers.format.formatPhone(phoneNumber)}`}
									>
										{shared.helpers.format.formatPhone(phoneNumber)}
									</a>
								);
							},
						})}
						.
					</p>
					<div className="notice-buttons margin-top-48">
						<ButtonGeneric text="Try Again" className="uk-button uk-button-primary" onClick={handleTryAgain} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default EmailComposerError;
