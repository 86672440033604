import sharedConfig from '../shared.config';

type Reactotron = import('reactotron-core-client').Reactotron;

const Reactotron = !sharedConfig.disableReactotron
	? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
	  (require('./reactotron.config').default as Reactotron)
	: undefined;

export default Reactotron;
