import { createAsyncThunk } from '@reduxjs/toolkit';
import throttle from 'lodash/throttle';

import { ApiResponse } from '@cp/common/services/utils';

import type { Dispatch } from 'redux';

import { fetchRemoteConfig } from '../../services/RemoteConfig';
import { checkAvailableUpdate } from '../../utils/forceUpdate';
import { RemoteConfigState } from '../reducers/remoteConfig.reducer';
import * as actions from './actions';

const THROTTLE_TIME = 15000;

// But this will work since the inner function is only "created" once:
const innerFunction = throttle(
	async (dispatch: Dispatch) => {
		const appUpdateInfo = await checkAvailableUpdate();
		dispatch(actions.setForceUpdate(appUpdateInfo));
		return appUpdateInfo;
	},
	THROTTLE_TIME,
	{ trailing: false },
);

export const getForceUpdateStatus = () => (dispatch: Dispatch) => innerFunction(dispatch);

export const getRemoteConfig = createAsyncThunk(
	'shared/remoteConfig',
	async (): Promise<ApiResponse<Partial<RemoteConfigState>>> => {
		const response = await fetchRemoteConfig();
		if (response && response.success) return response;
		return { success: response.success };
	},
);
